import React, { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { media } from 'src/config/breakpoints';
import { UsedProductOfferButton } from 'src/app/product/components/UsedProductOfferButton';
import { UsedProductTile } from 'src/app/search/components/UsedProductTile';
import { ProductOfferTilePrice } from 'src/app/product/components/ProductOfferTile/ProductOfferTilePrice';
import { productOfferTileMaxWidth } from 'src/app/product/components/ProductOfferTile';
import {
  calculatePagesCount,
  Pagination,
} from 'src/app/search/components/Pagination';
import { useResponsivePageSize } from 'src/app/search/hooks/useResponsivePageSize';
import { useUsedProductOffersPaginationFilterControl } from 'src/app/search/hooks/useFilterControl';
import {
  searchUsedProductsResultsSelector,
  searchUsedProductsResultsTotalSelector,
} from 'src/app/search/state/searchProducts/searchUsedProductsSelectors';
import { buildProductItemPageRoute } from 'src/app/routes';
import {
  LoadingIndicatorLimiter,
  UsedProductOffersLoadingIndicator,
} from 'src/app/search/components/LoadingIndicator';
import { ConstraintContainer } from 'src/common/components/ConstraintContainer';
import { productsPreferenceSelector } from 'src/state/settings/settingsSelectors';
import { DreamProductTile } from 'src/app/search/components/DreamProductTile';
import { Link } from 'gatsby';
import { useRequest } from 'src/lib/useRequest';
import { isInitialRequest } from 'src/state/requestStatus/requestStatus';
import { findUsedProductsRequestActions } from 'src/app/search/state/searchProducts/searchUsedProductsActions';

const ProductOffersGrid = styled.div`
  display: grid;
  position: relative;
  min-height: 19.125rem;
  margin-bottom: 2rem;
  justify-content: center;
  grid-row-gap: 2rem;

  ${media.w.min.px768(css`
    grid-template-columns: repeat(
      2,
      minmax(21rem, ${productOfferTileMaxWidth})
    );
    grid-column-gap: 8%;
  `)}

  ${media.w.min.px1200(css`
    grid-row-gap: 3rem;
    grid-column-gap: 1rem;

    justify-content: space-between;
    grid-template-columns: repeat(
      3,
      minmax(21rem, ${productOfferTileMaxWidth})
    );
  `)}
`;

export const UsedProductOffers: FC<{ onDreamCarClick: () => void }> = ({
  onDreamCarClick,
}) => {
  const {
    state: {
      value: { page, size: pageSize },
    },
    setValue,
  } = useUsedProductOffersPaginationFilterControl();
  const productsPreference = useSelector(productsPreferenceSelector);
  const newPageSize = useResponsivePageSize(
    3,
    productsPreference === 'used' ? 9 : 6
  );

  useEffect(() => {
    setTimeout(() => {
      if (newPageSize != pageSize) {
        setValue({ page, size: newPageSize });
      }
    }, 100);
  }, [newPageSize]);

  const products = useSelector(searchUsedProductsResultsSelector);
  const total = useSelector(searchUsedProductsResultsTotalSelector);
  const pagesCount = calculatePagesCount(total, pageSize);
  const [pageChanged, setPageChanged] = useState(false);

  const { isLoading, status } = useRequest(
    findUsedProductsRequestActions.request
  );
  const [showDreamCar, setShowDreamCar] = useState(
    page === pagesCount && !isLoading && !isInitialRequest(status)
  );

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setShowDreamCar((current) => {
      if (current) {
        if (page !== pagesCount && !isLoading) {
          // User changed from last page to other and it has finished loading
          return false;
        }
      } else {
        if (page === pagesCount && !isLoading) {
          // User changed from other page to last and it has finished loading
          return true;
        }
      }

      return current;
    });
  }, [page, pagesCount, isLoading]);

  useEffect(() => {
    if (pageChanged) {
      containerRef?.current?.scrollIntoView();
    }
  }, [isLoading]);

  const handlePageChange = (newPage: number) => {
    setValue({ page: newPage, size: pageSize });
    setPageChanged(true);
  };

  return (
    <LoadingIndicatorLimiter>
      <ConstraintContainer ref={containerRef}>
        <ProductOffersGrid>
          <UsedProductOffersLoadingIndicator />
          {products.map((product) => (
            <UsedProductOfferButton
              key={product.id}
              as={Link}
              tabIndex={0}
              to={buildProductItemPageRoute({
                annualMileage: product.offer.annualMileage,
                brandCode: product.brand?.code,
                buyoutPrice: product.offer.buyoutPrice,
                contractPeriod: product.offer.contractPeriod,
                customerType: product.offer.customerType,
                financingType: product.offer.financingType,
                initialPayment: product.offer.initialPayment,
                modelLabel: product.model?.label,
                packageType: product.offer.packageType,
                productId: product.id,
                version: product.version,
              })}
              hoverAccentLabel={
                <ProductOfferTilePrice price={product.offer.price} />
              }
            >
              <UsedProductTile product={product} />
            </UsedProductOfferButton>
          ))}

          {showDreamCar && (
            <UsedProductOfferButton
              tabIndex={0}
              onClick={onDreamCarClick}
              hoverAccentLabel="Wyświetl formularz"
            >
              <DreamProductTile />
            </UsedProductOfferButton>
          )}
        </ProductOffersGrid>

        {products.length > 0 && (
          <Pagination
            page={page}
            pageSize={pageSize}
            total={total}
            onChange={handlePageChange}
          />
        )}
      </ConstraintContainer>
    </LoadingIndicatorLimiter>
  );
};
