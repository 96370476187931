import { generatePDF } from 'src/app/product/components/PdfGenerator/generate-pdf-from-form-blocks';

export const downloadPDF = async (
  product: any,
  activeOffer: any,
  image: any,
  imageDimensions: any,
  nameAndSurname: string
) => {
  const fileName =
    product.label +
      `- ${activeOffer?.financingType === 'rent' ? 'najem' : 'leasing'}` ||
    'oferta';
  const pdfBlob = await generatePDF(
    product,
    activeOffer,
    image,
    imageDimensions,
    nameAndSurname
  );
  const url = URL.createObjectURL(pdfBlob);
  const link = document.createElement('a');
  link.href = url;
  link.download = `${fileName}.pdf`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
