import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import {
  actionsModifyingFilters,
  searchFiltersActions,
  srpFiltersActions,
} from 'src/app/search/state/searchFilters/searchFiltersActions';
import { navigate } from 'gatsby';
import { searchFiltersStateSelector } from 'src/app/search/state/searchFilters/searchFiltersSelectors';
import { SearchFiltersState } from 'src/app/search/state/searchFilters/searchFiltersReducer';
import {
  packFilters,
  unpackFilters,
} from 'src/app/search/services/filterSerializer';
import { SearchPageRoute } from 'src/app/routes';
import {
  fetchSrpFilters,
  pickFilterValues,
} from 'src/app/search/services/filters';
import { stringify } from 'qs';
import { pipe } from 'fp-ts/lib/pipeable';
import { filter, fromNullable, getOrElse } from 'fp-ts/lib/Option';
import { getQueryParamsMap, getQueryString } from 'src/lib/getQueryParams';
import {
  currentSearchParamsVersion,
  SearchParamsVersion,
} from 'src/app/search/searchParamsVersion';
import { keys } from 'ramda';
import { isString } from 'src/lib/typeGuards';
import { searchProductsActions } from 'src/app/search/state/searchProducts/searchProductsActions';
import { prune } from '@summer/react-kit/functions';
import { brandsListSelector } from 'src/app/state/commonFilters/commonFiltersSelectors';
import { Brand } from 'src/app/common/models/product';
import { BrandModel } from 'src/app/state/commonFilters/commonFiltersReducer';
import { OptionFilter } from 'src/app/common/models/filter';

function* writeToURLQuery() {
  const brands: (Brand & { models: BrandModel[] })[] | undefined = yield select(
    brandsListSelector
  );
  const state: SearchFiltersState = yield select(searchFiltersStateSelector);
  const stringifiedFilters = packFilters(pickFilterValues(state));

  const brandsUrl = prune(
    (brands ?? []).map((brand) =>
      state[OptionFilter.Brand].value.includes(brand.id)
        ? brand.code
        : undefined
    )
  ).join('/');

  const qs = stringify({
    ...getQueryParamsMap(),
    f: stringifiedFilters,
    v: currentSearchParamsVersion,
  });

  yield put(searchProductsActions.setQueryParamString(qs));

  if (location.pathname.includes('/app/search')) {
    navigate(`${SearchPageRoute}/${brandsUrl}?${qs}`, {
      replace: true,
    });
  }
}

const filtersQueryParamCompat = () => {
  const qp = getQueryParamsMap();
  const version = qp?.v;

  if (keys(qp).length === 0) {
    return {};
  }

  if (version === SearchParamsVersion.v2) {
    return pipe(qp, (a) =>
      pipe(
        fromNullable(a?.f),
        filter(isString),
        getOrElse(() => ''),
        unpackFilters
      )
    );
  } else {
    /**
     * Query string on SRP was not proper query params prior to WUDA-426.
     * It was compressed and put into URL without assignment to any query param,
     * for example: /app/search?asd12341 instead of /app/search?f=asd12341
     * and it could have unsafe url characters like "=" (from base64 enc).
     *
     * After WUDA-426 each filters params are compressed, stringified
     * and assigned to "f" query param. Also "v" parameter has been added
     * to identify version for further compatibility code.
     */

    const qs = getQueryString();
    if (location.pathname.includes('/app/search')) {
      navigate(`${SearchPageRoute}?v=${currentSearchParamsVersion}`, {
        replace: true,
      });
    }
    return unpackFilters(qs);
  }
};

function* readFromURLQuery() {
  yield put(
    searchFiltersActions.loadQueryParamsSuccess(filtersQueryParamCompat())
  );
}

function* readFromURLQueryWatcher() {
  yield takeEvery(searchFiltersActions.loadQueryParams.type, readFromURLQuery);
}

function* writeToURLQueryWatcher() {
  yield takeLatest(actionsModifyingFilters, writeToURLQuery);
}

function* processSrpFilters() {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore FIXME
    const response = yield call(fetchSrpFilters);
    yield put(srpFiltersActions.requestSuccess(response));
  } catch (error) {
    yield put(srpFiltersActions.requestFailure(error));
  }
}

function* fetchSrpFiltersWatcher() {
  yield takeEvery([srpFiltersActions.request.type], processSrpFilters);
}

export function* searchFiltersSaga() {
  yield all([
    writeToURLQueryWatcher(),
    readFromURLQueryWatcher(),
    fetchSrpFiltersWatcher(),
  ]);
}
