import React from 'react';
import { AsideMainMenuLayout } from 'src/common/components/AsideMainManuLayout';
import { Seo } from 'src/public/components/Seo';
import { Title } from 'src/common/components/typography/Title';
import styled from 'styled-components';
import { colors } from 'src/config/colors';
import { FlexScrollContainer } from 'src/common/components/FlexScrollContainer';
import { typography } from 'src/config/typography';
import { ExpansionPanel } from 'src/common/components/ExpansionPanel/ExpansionPanel';
import { ExpansionPanelSummary } from 'src/common/components/ExpansionPanel/ExpansionPanelSummary';
import { ExpansionPanelDetails } from 'src/common/components/ExpansionPanel/ExpansionPanelDetails';
import { SimpleLink } from 'src/common/components/SimpleLink';
import { Paragraph } from 'src/common/components/typography/Text';
import { useStorePageUrl } from 'src/common/hooks/useStorePageUrl';
import { Link } from 'gatsby';
import { TosRoute } from 'src/public/publicRoutes';

// todo: Extract to AsideMainMenuLayout ?
const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  color: ${colors.lightGray};
  font-family: ${typography.fontFamilyDefault};
  font-size: 0.875rem;
`;

// todo: Extract to AsideMainMenuLayout ?
const ScrollContent = styled.div`
  max-width: 49rem;
`;

const Header = styled.header`
  color: ${colors.white};
`;

const LeftPad = styled.div`
  padding-left: 2rem;
`;

const PrivacyPolicyAndCookiesPage = () => {
  useStorePageUrl();

  return (
    <AsideMainMenuLayout>
      <Seo title="Polityka prywatności i cookies" />
      <Container>
        <Header>
          <Title level={1} variant="condensed">
            Polityka prywatności i&nbsp;cookies
          </Title>
        </Header>
        <FlexScrollContainer>
          <ScrollContent>
            <section>
              <Paragraph size="small">
                Ostatnia aktualizacja: 01.06.2024r.
              </Paragraph>

              <ExpansionPanel defaultExpanded={true}>
                <ExpansionPanelSummary>1. Kim jesteśmy?</ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Paragraph size="small">
                    Niniejsza Polityka prywatności i&nbsp;plików cookies
                    (&bdquo;
                    <strong>Polityka</strong>&rdquo;) określa sposób,
                    w&nbsp;jaki Mobility Benefit sp. z&nbsp;o.o. z&nbsp;siedzibą
                    przy ul. Grzybowskiej 62, 00-844 Warszawa, KRS 0000827524,
                    NIP 5272920409, (&bdquo;
                    <strong>Mobility</strong>&rdquo;, &bdquo;<strong>my</strong>
                    &rdquo;), działając jako administrator, przetwarza Twoje
                    dane osobowe, w&nbsp;związku z&nbsp;prowadzeniem serwisu{' '}
                    <SimpleLink target="_blank" href="https://carplatform.pl/">
                      https://carplatform.pl/
                    </SimpleLink>
                    (&bdquo;<strong>Strona</strong>&rdquo;).
                  </Paragraph>
                  <Paragraph size="small">
                    Polityka opisuje w szczególności rodzaje danych, które
                    przetwarzamy, cele, w&nbsp;jakich je wykorzystujemy, jak
                    również podmioty, którym możemy je udostępniać oraz
                    przysługujące Ci prawa.
                  </Paragraph>
                  <Paragraph size="small">
                    Dokładamy wszelkich starań, aby chronić Twoją prywatność,
                    zgodnie z wymogami Rozporządzenia Parlamentu Europejskiego i
                    Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie
                    ochrony osób fizycznych w związku z przetwarzaniem danych
                    osobowych i w sprawie swobodnego przepływu takich danych
                    oraz uchylenia dyrektywy 95/46/WE (&bdquo;
                    <strong>RODO</strong>&rdquo;).
                  </Paragraph>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary>
                  2. Jak się z nami skontaktować?
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Paragraph size="small">
                    Jeśli masz jakiekolwiek pytania dotyczące niniejszej
                    Polityki lub chcesz skorzystać z&nbsp;przysługujących Ci
                    praw, skontaktuj się z&nbsp;nami drogą elektroniczną na
                    adres hello@mobilitybenefit.pl lub pisemnie na adres
                    Mobility Benefit sp. z&nbsp;o.o. z&nbsp;siedzibą przy ul.
                    Grzybowskiej 62, 00-844 Warszawa.
                  </Paragraph>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary>
                  3. Jakie dane osobowe przetwarzamy, w&nbsp;jakich celach
                  i&nbsp;na jakiej podstawie prawnej?
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Paragraph size="small">
                    Możemy przetwarzać Twoje dane osobowe w&nbsp;różnych celach,
                    w&nbsp;różnym zakresie i&nbsp;na różnej podstawie prawnej,
                    dlatego pogrupowaliśmy te informacje odnosząc się do celu
                    przetwarzania Twoich danych osobowych.
                  </Paragraph>
                  <Title level={3} variant="condensed">
                    3.1 Korzystanie ze Strony przez niezalogowanych użytkowników
                  </Title>
                  <LeftPad>
                    <Paragraph size="small">
                      <strong>Zakres danych</strong>. Gdy przeglądasz naszą
                      Stronę bez logowania przetwarzamy dane dotyczące Twojej
                      aktywności na Stronie, np.: dane dotyczące oglądanych
                      przez Ciebie ofert, podstron, jak również dane dotyczące
                      sesji, Twojego urządzenia oraz systemu operacyjnego,
                      przeglądarki, lokalizacji, adresu IP oraz unikalnego ID.
                    </Paragraph>
                    <Paragraph size="small">
                      <strong>Podstawa prawna</strong>. Nasz prawnie uzasadniony
                      interes (art. 6 ust. 1 lit. f RODO), polegający na
                      umożliwieniu Ci zapoznania się z&nbsp;wybranymi treściami
                      umieszczonymi na naszej Stronie oraz podjęcia decyzji
                      o&nbsp;założeniu konta lub udzielona przez Ciebie zgoda na
                      używanie przez nas plików cookies.
                    </Paragraph>
                    <Paragraph size="small">
                      <strong>Okres przetwarzania</strong>: Dane osobowe zebrane
                      w powyższym celu przetwarzamy przez okres cyklu życia
                      naszych plików cookies (więcej informacji na temat okresów
                      przechowywania określonych plików cookies znajdziesz
                      w&nbsp;sekcji <i>„Jakich rodzajów cookies używamy?”</i>{' '}
                      poniżej.
                    </Paragraph>
                  </LeftPad>

                  <Title level={3} variant="condensed">
                    3.2 Utworzenie konta użytkownika i&nbsp;uwierzytelnianie
                    użytkownika
                  </Title>
                  <LeftPad>
                    <Paragraph size="small">
                      <strong>Zakres danych</strong>. Aby utworzyć dla Ciebie
                      konto w aplikacji oraz uwierzytelnić Cię w&nbsp;czasie
                      logowania przetwarzamy dane osobowe podane przez Ciebie
                      w&nbsp;procesie rejestracji w&nbsp;szczególności: imię,
                      Twój służbowy i&nbsp;prywatny adres e-mail, dane Twojego
                      pracodawcy oraz ustanowione przez Ciebie hasło.
                    </Paragraph>
                    <Paragraph size="small">
                      <strong>Podstawa prawna</strong>. Niezbędność do wykonania
                      umowy o&nbsp;świadczenie usług drogą elektroniczną (art. 6
                      ust. 1 lit. b&nbsp;RODO) zgodnie z{' '}
                      <SimpleLink as={Link} to={TosRoute}>
                        Regulaminem
                      </SimpleLink>{' '}
                      oraz ciążący na nas obowiązek prawny (art. 6 ust. 1 lit.
                      c&nbsp;RODO) wynikający z&nbsp;art. 7 pkt 1 lit.
                      a&nbsp;ustawy o&nbsp;świadczeniu usług drogą
                      elektroniczną.
                    </Paragraph>
                    <Paragraph size="small">
                      <strong>Okres przetwarzania</strong>. Dane osobowe zebrane
                      w powyższym celu przechowujemy przez czas trwania umowy
                      o&nbsp;świadczenie usługi prowadzenia konta, bez względu
                      na to, czy aktywnie korzystasz z&nbsp;naszej Strony.
                      Jeżeli nie udało Ci się założyć konta gdyż Twój pracodawca
                      nie jest jeszcze partnerem Mobility, nie będziemy
                      przechowywać Twojego służbowego adresu poczty
                      elektronicznej (przechowujemy go nie dłużej, niż do
                      udzielenia odpowiedzi).
                    </Paragraph>
                  </LeftPad>

                  <Title level={3} variant="condensed">
                    3.3 Korzystanie ze Strony po zalogowaniu
                  </Title>
                  <LeftPad>
                    <Paragraph size="small">
                      Przetwarzamy Twoje dane osobowe, aby umożliwić Ci
                      korzystanie z&nbsp;funkcjonalności konta:
                      wyświetlania/wyszukiwania ofert dot. pojazdów, obsługę
                      zapisanych rekordów wyszukiwania oraz przeglądanych ofert,
                      zarządzania kontem oraz złożenia zapytania ofertowego.
                    </Paragraph>
                    <Paragraph size="small">
                      <strong>Zakres danych</strong>. W&nbsp;tym celu
                      przetwarzamy Twoje dane osobowe podane przez Ciebie
                      w&nbsp;formularzu rejestracyjnym to znaczy: imiona,
                      nazwiska, prywatne i&nbsp;służbowe adresy poczty
                      elektronicznej, miejsce zatrudnienia, numer telefonu,
                      płeć, informację o&nbsp;wybranym modelu i&nbsp;wyposażeniu
                      pojazdu, historię złożonych zapytań ofertowych, jak
                      również dane dotyczące Twojej aktywności na Stronie, np.:
                      dane dotyczące oglądanych przez Ciebie ofert, podstron,
                      jak również dane dotyczące sesji, Twojego urządzenia oraz
                      systemu operacyjnego, przeglądarki, lokalizacji, adresu IP
                      oraz unikalnego ID.
                    </Paragraph>
                    <Paragraph size="small">
                      <strong>Podstawa prawna</strong>. Niezbędność do wykonania
                      umowy o&nbsp;świadczenie usług drogą elektroniczną (art. 6
                      ust. 1 lit. b&nbsp;RODO) zgodnie z&nbsp;
                      <SimpleLink as={Link} to={TosRoute}>
                        Regulaminem
                      </SimpleLink>{' '}
                      a&nbsp;także zgoda na przetwarzanie plików cookies.
                    </Paragraph>
                    <Paragraph size="small">
                      <strong>Okres przetwarzania</strong>. Dane osobowe zebrane
                      w&nbsp;powyższym celu przechowujemy przez czas trwania
                      umowy o&nbsp;świadczenie usługi prowadzenia konta, bez
                      względu na to, czy aktywnie korzystasz z&nbsp;usług
                      aplikacji. Po usunięciu konta Twoje dane osobowe zostają
                      zanonimizowane (dotyczy danych osobowych wskazujących na
                      aktywność i preferencje użytkowników) lub usunięte
                      (dotyczy danych osobowych podawanych bezpośrednio przez
                      użytkownika np. przy zakładaniu konta), z wyjątkiem
                      następujących danych: imię, nazwisko, adres e-mail oraz
                      informacja o&nbsp;wyrażonych zgodach (te dane będziemy
                      przechowywać jeszcze przez okres do 3 lat od usunięcia
                      konta dla celów rozpatrywania skarg oraz roszczeń
                      związanych z&nbsp;korzystaniem z&nbsp;naszych usług). Dane
                      osobowe użytkowników zawarte w&nbsp;plikach cookies
                      przechowujemy przez czas odpowiadający cyklowi życia
                      zapisanych na ich urządzeniach plików cookies. (więcej
                      informacji na temat okresów przechowywania określonych
                      plików cookies znajdziesz w&nbsp;sekcji{' '}
                      <i>„Jakich rodzajów cookies używamy?”</i> poniżej.
                    </Paragraph>
                  </LeftPad>

                  <Title level={3} variant="condensed">
                    3.4 Okresowa weryfikacja miejsca zatrudnienia użytkownika
                  </Title>
                  <LeftPad>
                    <Paragraph size="small">
                      Warunkiem korzystania z&nbsp;aplikacji jest zatrudnienie
                      na podstawie umowy o&nbsp;pracę, umowy zlecenia lub
                      świadczenia usług w&nbsp;ramach indywidualnej działalności
                      gospodarczej na rzecz jednego z&nbsp;naszych Partnerów.
                      Okresowo możemy weryfikować aktualność spełniania przez
                      Ciebie tego warunku korzystania z aplikacji poprzez
                      wysłanie wiadomości na Twój służbowy adres e-mail. Jeżeli
                      nie przejdziesz pozytywnie tej weryfikacji, zachowasz
                      dostęp do swojego konta w aplikacji, ale nie będziesz mieć
                      możliwości skorzystania z&nbsp;usług oraz ofert naszych
                      Partnerów.
                    </Paragraph>
                    <Paragraph size="small">
                      <strong>Zakres danych</strong>. W&nbsp;tym celu
                      przetwarzamy Twoje dane osobowe w&nbsp;zakresie imienia,
                      nazwiska, służbowego adresu poczty elektronicznej oraz
                      miejsca zatrudnienia.
                    </Paragraph>
                    <Paragraph size="small">
                      <strong>Podstawa prawna</strong>. Niezbędność do wykonania
                      umowy o&nbsp;świadczenie usług drogą elektroniczną (art. 6
                      ust. 1 lit. b&nbsp;RODO) zgodnie z&nbsp;
                      <SimpleLink as={Link} to={TosRoute}>
                        Regulaminem
                      </SimpleLink>{' '}
                      .
                    </Paragraph>
                    <Paragraph size="small">
                      <strong>Okres przetwarzania</strong>. Dane osobowe zebrane
                      w&nbsp;powyższym celu przechowujemy przez czas trwania
                      umowy o&nbsp;świadczenie usługi prowadzenia konta, bez
                      względu na to, czy aktywnie korzystasz z&nbsp;usług.
                    </Paragraph>
                  </LeftPad>

                  <Title level={3} variant="condensed">
                    3.5 Marketing usług i&nbsp;produktów oferowanych na Stronie,
                    w&nbsp;tym przedstawianie ofert pojazdów dopasowanych do
                    Twoich preferencji
                  </Title>
                  <LeftPad>
                    <Paragraph size="small">
                      <strong>Zakres danych</strong>. W&nbsp;tym celu
                      przetwarzamy informacje o&nbsp;treści wyrażonej przez
                      Ciebie zgody na otrzymywanie komunikacji marketingowej,
                      numer Twojego telefonu, Twój prywatny adres poczty
                      elektronicznej oraz informacje o&nbsp;wybranych lub
                      przeglądanych przez Ciebie modelach i&nbsp;wyposażeniu
                      pojazdów oraz historię złożonych zapytań ofertowych.
                      W&nbsp;przypadku korzystania z&nbsp;usługi LiveChat,
                      przetwarzamy również dane udostępniane przez Ciebie
                      w&nbsp;wiadomościach przesyłanych za pośrednictwem tej
                      usługi. Informacje te otrzymujemy bezpośrednio od Ciebie
                      lub pozyskujemy je z&nbsp;plików cookies wykorzystywanych
                      na Stronie.
                    </Paragraph>
                    <Paragraph size="small">
                      Powyższe dane wykorzystujemy do stworzenia Twojego
                      profilu, odpowiadającego Twoim zainteresowaniom
                      i&nbsp;preferencjom. Następnie do tak stworzonego profilu
                      dopasowujemy informacje marketingowe dotyczące produktów
                      i&nbsp;usług, które mogą Cię zainteresować.
                    </Paragraph>
                    <Paragraph size="small">
                      <strong>Podstawa prawna</strong>. Nasz prawnie uzasadniony
                      interes (art. 6 ust. 1 lit. f&nbsp;RODO), polegający na
                      możliwości dostosowania naszej oferty do Twoich
                      preferencji lub zainteresowań oraz udzielona przez Ciebie
                      zgoda na otrzymywanie korespondencji marketingowej.
                    </Paragraph>
                    <Paragraph size="small">
                      <strong>Równowaga interesów</strong>. Po zważeniu naszego
                      interesu oraz Twoich interesów, praw i&nbsp;wolności
                      uznaliśmy, że przetwarzanie danych w&nbsp;opisany sposób
                      nie będzie ingerować nadmiernie w&nbsp;Twoją prywatność
                      ani nie będzie stanowić dla Ciebie nadmiernej uciążliwości
                    </Paragraph>
                    <Paragraph size="small">
                      <strong>Okres przetwarzania</strong>: W&nbsp;tym celu
                      będziemy przetwarzać Twoje dane osobowe do czasu odwołania
                      zgody lub uznania przez nas za skuteczny zgłoszonego przez
                      Ciebie sprzeciwu wobec przetwarzania Twoich danych. Dane
                      osobowe użytkowników zawarte w&nbsp;plikach cookies
                      przechowujemy przez czas odpowiadający cyklowi życia
                      zapisanych na ich urządzeniach plików cookies.
                    </Paragraph>
                  </LeftPad>

                  <Title level={3} variant="condensed">
                    3.6 Przygotowanie i&nbsp;przedstawienie oferty użytkownikom
                  </Title>
                  <LeftPad>
                    <Paragraph size="small">
                      W&nbsp;celu przygotowania i&nbsp;przedstawienia
                      odpowiedniej oferty, przetwarzamy dane osobowe
                      użytkowników, które zostały nam udostępnione przez
                      współpracujących z&nbsp;nami autoryzowanych dealerów,
                      a&nbsp;także sami udostępniamy dealerom dane osobowe
                      użytkowników.
                    </Paragraph>
                    <Paragraph size="small">
                      <strong>Zakres danych</strong>. W&nbsp;tym celu
                      przetwarzamy informacje o&nbsp;treści wyrażonej przez
                      Ciebie zgody na przekazanie (udostępnienie) danych
                      osobowych dealerom, a&nbsp;także Twoje: imię, nazwisko,
                      adres zamieszkania/adres korespondencyjny, adres e-mail,
                      numer telefonu, numer PESEL, nazwę prowadzonej
                      działalności/spółki, adres siedziby spółki, numer NIP oraz
                      REGON, numer prawa jazdy wraz z&nbsp;datą uzyskania
                      dokumentu,&nbsp;a także zakres Twojego zapytania
                      ofertowego, w&nbsp;tym model i&nbsp;wyposażenie wybranego
                      przez Ciebie auta, oraz wskazany rodzaj umowy.
                    </Paragraph>
                    <Paragraph size="small">
                      <strong>Podstawa prawna</strong>. Twoja zgoda (art. 6 ust.
                      1 lit. a&nbsp;RODO) oraz podjęcie na Twoje żądanie działań
                      zmierzających do zawarcia umowy sprzedaży/leasingu/najmu
                      długoterminowego samochodu (art. 6 ust. 1 lit
                      b&nbsp;RODO).
                    </Paragraph>
                    <Paragraph size="small">
                      <strong>Okres przetwarzania</strong>: W&nbsp;tym celu
                      będziemy przetwarzać Twoje dane osobowe przez okres
                      niezbędny do realizacji umowy, a w przypadku niezawarcia
                      umowy Twoje dane osobowe nie będą przetwarzane po
                      zakończeniu czynności podjętych w celu zawarcia
                      wspomnianej umowy, chyba że wyrazisz zgodę na to, żebyśmy
                      mogli przetwarzać Twoje dane osobowe w celu przedstawiania
                      Ci przyszłych ofert lub do czasu odwołania zgody lub
                      zgłoszonego przez Ciebie sprzeciwu wobec przetwarzania
                      danych, który uznamy za skutecznie złożony.
                    </Paragraph>
                  </LeftPad>

                  <Title level={3} variant="condensed">
                    3.7 Korzystanie z programu Mobility 3.0
                  </Title>
                  <LeftPad>
                    <Paragraph size="small">
                      W przypadku spełnienia przez Ciebie warunków określonych w
                      Regulaminie programu Mobility 3.0 (dostępnym pod adresem{' '}
                      <SimpleLink
                        target="_blank"
                        href="/221023_Regulamin_Program_Mobility30.pdf"
                      >
                        https://carplatform.pl/221023_Regulamin_Program_Mobility30.pdf
                      </SimpleLink>
                      ) będziemy przetwarzać Twoje dane osobowe w celu
                      umożliwienia Ci korzystania z programu Mobility 3.0, w
                      ramach którego otrzymasz dodatkowo kartę benefitową, która
                      co miesiąc będzie zasilana określoną kwotą, na warunkach
                      wskazanych w Regulaminie programu Mobility 3.0. W
                      zależności od dostępnego pakietu będziesz mógł/mogła
                      przeznaczyć te środki na zakup dostępnych w programie
                      Mobility 3.0 produktów lub usług związanych z motoryzacją
                      oraz skorzystać z dostępnych zniżek na dodatkowe produkty
                      lub usługi, których zakup opłacać będziesz z własnych
                      środków.
                    </Paragraph>
                    <Paragraph size="small">
                      <strong>Zakres danych</strong>. W tym celu przetwarzamy
                      informacje o treści wyrażonego przez Ciebie oświadczenia o
                      przystąpieniu do programu Mobility 3.0, a także wszelkie
                      informacje o zakupionych przez Ciebie produktach lub
                      usługach związanych z mobilnością ze środków ulokowanych
                      przez Twojego pracodawcę, Mobility lub dealera na
                      przyznanej Ci karcie benefitowej. Możemy także przetwarzać
                      informacje o zakupionych przez Ciebie z środków własnych
                      produktach lub usługach z wykorzystaniem rabatu dostępnego
                      wyłącznie dla członków programu Mobility 3.0.
                    </Paragraph>
                    <Paragraph size="small">
                      <strong>Podstawa prawna</strong>. Realizacja umowy o
                      świadczenie przez nas usług w oparciu o{' '}
                      <SimpleLink as={Link} to={TosRoute}>
                        Regulamin
                      </SimpleLink>{' '}
                      (art. 6 ust. 1 lit. b RODO).
                    </Paragraph>
                    <Paragraph size="small">
                      <strong>Okres przetwarzania</strong>: W tym celu będziemy
                      przetwarzać Twoje dane przez czas Twojego udziału w
                      programie Mobility 3.0 oraz po zakończeniu korzystania z
                      korzyści w ramach program przez okres wymagany przepisami
                      prawa oraz konieczny dla zabezpieczenia się przed
                      ewentualnymi roszczeniami.
                    </Paragraph>
                  </LeftPad>

                  <Title level={3} variant="condensed">
                    3.8 Statystyka korzystania z&nbsp;poszczególnych
                    funkcjonalności Strony, ułatwienie korzystania ze Strony
                    oraz zapewnienie bezpieczeństwa informatycznego Strony
                  </Title>
                  <LeftPad>
                    <Paragraph size="small">
                      <strong>Zakres danych</strong>. W&nbsp;tych celach
                      przetwarzamy dane osobowe dotyczące Twojej aktywności na
                      Stronie, takie jak: odwiedzane podstrony oraz ilość
                      spędzanego na każdej z&nbsp;nich czasu, jak również dane
                      dotyczące Twojej historii wyszukiwania, Twojego adresu IP,
                      lokalizacji, ID urządzenia oraz dane dotyczące
                      przeglądarki oraz systemu operacyjnego.
                    </Paragraph>
                    <Paragraph size="small">
                      <strong>Podstawa prawna</strong>. Nasz prawnie uzasadniony
                      interes (art. 6 ust. 1 lit. f RODO), polegający na
                      ułatwieniu korzystania z&nbsp;usług świadczonych drogą
                      elektroniczną oraz na poprawie funkcjonalności tych usług,
                      generowaniu statystyk pomocnych w&nbsp;administrowaniu
                      Stroną oraz zapewnieniu bezpieczeństwa informatycznego
                      Strony.
                    </Paragraph>
                    <Paragraph size="small">
                      <strong>Okres przetwarzania</strong>: w&nbsp;tym celu
                      będziemy przetwarzać Twoje dane osobowe do czasu uznania
                      przez nas za skuteczny zgłoszonego przez Ciebie sprzeciwu
                      wobec przetwarzania Twoich danych. Dane osobowe
                      użytkowników zawarte w&nbsp;plikach cookies przechowujemy
                      przez czas odpowiadający cyklowi życia zapisanych na ich
                      urządzeniach plików cookies.
                    </Paragraph>
                  </LeftPad>

                  <Title level={3} variant="condensed">
                    3.9 Ustalenie, dochodzenie i obrona przed roszczeniami
                  </Title>
                  <LeftPad>
                    <Paragraph size="small">
                      <strong>Zakres danych</strong>. W&nbsp;celu ustalenia,
                      dochodzenia lub obrony przed roszczeniami możemy
                      przetwarzać niezbędne dane osobowe podane przez Ciebie na
                      Stronie, takie jak: imię, nazwisko, data urodzenia, dane
                      dotyczące sposobu, w&nbsp;jaki korzystasz z&nbsp;naszych
                      usług oraz zakresu świadczonych usług, a&nbsp;także inne
                      dane niezbędne do udowodnienia istnienia roszczenia,
                      w&nbsp;tym rozmiarów poniesionej szkody.
                    </Paragraph>
                    <Paragraph size="small">
                      <strong>Podstawa prawna</strong>. Nasz prawnie uzasadniony
                      interes (art. 6 ust. 1 lit. f RODO), polegający na
                      ustaleniu, dochodzeniu oraz obronie przed roszczeniami
                      w&nbsp;postępowaniu przed sądami i&nbsp;innymi organami
                      państwowymi.
                    </Paragraph>
                    <Paragraph size="small">
                      <strong>Okres przetwarzania</strong>: w&nbsp;tym celu
                      będziemy przetwarzać Twoje dane osobowe do czasu
                      przedawnienia roszczeń (naszych lub Twoich) mogących
                      powstać z&nbsp;związku z&nbsp;korzystaniem
                      z&nbsp;aplikacji lub Strony, a&nbsp;w&nbsp;przypadku
                      wszczęcia postępowania sądowego - przez okres prowadzenia
                      postępowania oraz 10 lat od czasu prawomocnego zakończenia
                      takiego postępowania.
                    </Paragraph>
                  </LeftPad>

                  <Title level={3} variant="condensed">
                    3.10 Rozpatrywanie skarg i wniosków, odpowiedzi na pytania
                  </Title>
                  <LeftPad>
                    <Paragraph size="small">
                      <strong>Zakres danych</strong>. W&nbsp;tym celu możemy
                      przetwarzać niektóre dane osobowe podane przez Ciebie na
                      Stronie, jak również dane dotyczące korzystania
                      z&nbsp;naszych usług, będących przyczyną skargi lub
                      wniosku, dane zawarte w&nbsp;dokumentach załączonych do
                      skargi lub wniosku.
                    </Paragraph>
                    <Paragraph size="small">
                      <strong>Podstawa prawna</strong>. Nasz prawnie uzasadniony
                      interes (art. 6 ust. 1 lit. f RODO), polegający na
                      rozpoznaniu zgłoszonej skargi lub wniosku, poprawie
                      funkcjonalności usług świadczonych drogą elektroniczną
                      oraz na budowaniu pozytywnych relacji z&nbsp;użytkownikami
                      oraz użytkownikami niezalogowanymi, opartych na
                      rzetelności i&nbsp;lojalności.
                    </Paragraph>
                    <Paragraph size="small">
                      <strong>Okres przetwarzania</strong>: Dane przetwarzamy
                      przez okres niezbędny do rozpatrzenia złożonego zapytania,
                      skargi lub wniosku, jednak nie dłużej niż przez okres
                      3&nbsp;lat od otrzymania określonej wiadomości. Jeżeli
                      określona wiadomość stanowi lub może stanowić dowód
                      w&nbsp;postępowaniu przed sądem lub innym organem
                      państwowym, możemy przechowywać takie wiadomości
                      i&nbsp;zawarte w&nbsp;nich dane osobowe do czasu
                      prawomocnego zakończenia postępowania.
                    </Paragraph>
                  </LeftPad>

                  <Title level={3} variant="condensed">
                    3.11 Live chat
                  </Title>
                  <LeftPad>
                    <Paragraph size="small">
                      <strong>Zakres danych</strong>. W&nbsp;przypadku
                      korzystania z&nbsp;usługi LiveChat, przetwarzamy również
                      dane udostępniane przez Ciebie w&nbsp;wiadomościach
                      przesyłanych za pośrednictwem tej usługi.
                    </Paragraph>
                    <Paragraph size="small">
                      <strong>Podstawa prawna</strong>. Nasz prawnie uzasadniony
                      interes (art. 6 ust. 1 lit. f&nbsp;RODO), polegający na
                      udzieleniu Ci dodatkowych informacji (zgodnie
                      z&nbsp;treścią zapytania przesłanego za pośrednictwem
                      LiveChat).
                    </Paragraph>
                    <Paragraph size="small">
                      <strong>Okres przetwarzania</strong>: w&nbsp;tym celu
                      będziemy przetwarzać Twoje dane osobowe przez okres do 6
                      miesięcy. Jeżeli określona wiadomość stanowi lub może
                      stanowić dowód w&nbsp;postępowaniu przed sądem lub innym
                      organem państwowym, możemy przechowywać takie wiadomości
                      i&nbsp;zawarte w&nbsp;nich dane osobowe do czasu
                      prawomocnego zakończenia postępowania.
                    </Paragraph>
                  </LeftPad>

                  <Paragraph size="small">
                    Podanie niektórych danych jest warunkiem skorzystania
                    z&nbsp;poszczególnych usług i&nbsp;funkcjonalności konta
                    (dane obligatoryjne). Konsekwencją nie podania tych danych
                    jest brak możliwości świadczenia przez nas określonych usług
                    i&nbsp;funkcjonalności konta. Poza danymi oznaczonymi na
                    Stronie jako obligatoryjne, podanie innych danych osobowych
                    jest dobrowolne.
                  </Paragraph>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary>
                  4. Udostępnianie danych
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Paragraph size="small">
                    Przekazujemy Twoje dane osobowe następującym kategoriom
                    odbiorców:
                  </Paragraph>

                  <Title level={3} variant="condensed">
                    4.1 Partnerzy
                  </Title>
                  <LeftPad>
                    <Paragraph size="small">
                      Po złożeniu przez Ciebie zapytania ofertowego za
                      pośrednictwem Strony, będziemy udostępniać naszemu
                      partnerowi lub naszym partnerom (w zależności od rodzaju
                      wybranej przez Ciebie oferty) Twoje dane w&nbsp;zakresie:
                      imię, nazwisko, numer telefonu, zakres zapytania
                      ofertowego obejmują m.in. model i&nbsp;wyposażenie
                      wybranego auta oraz wskazany rodzaj umowy.
                    </Paragraph>
                    <Paragraph size="small">
                      {/* Naszymi partnerami są w&nbsp;szczególności: BNP Paribas
                      Bank Polska S.A. z&nbsp;siedzibą w Warszawie, BNP Paribas
                      Leasing Services sp. z o.o. z&nbsp;siedzibą w Warszawie,
                      ARVAL SERVICE LEASE POLSKA Sp. z&nbsp;o.o. z&nbsp;siedzibą
                      w Warszawie, AUTO FUS GROUP Sp. j. z siedzibą
                      w&nbsp;Warszawie.  */}
                      Naszymi Partnerami w szczególności są: BNP Parisbas Bank
                      Polska S.A. z siedzibą w Warszawie, BNP Parisbas Leasing
                      Services sp. z o.o z siedzibą w Warszawie, Arval Service
                      Lease Polska z siedzibą w Warszawie, PKO Leasing z
                      siedzibą w Warszawie, Pekao Leasing z siedzibą w
                      Warszawie, Alior Leasing z siedzibą w Warszawie, VEHIS Sp.
                      z o.o z siedzibą w Katowicach, Prime Car Management S.A z
                      siedzibą w Gdańsku
                    </Paragraph>
                    <Paragraph size="small">
                      Naszym partnerem jest również Twój pracodawca. Nie
                      udostępniamy mu jednak szczegółowych informacji na Twój
                      temat - pracodawca nie jest informowany o&nbsp;fakcie
                      założenia konta czy też wybranych przez Ciebie ofertach.
                      Pracodawca może otrzymać jedynie informację o&nbsp;ilości
                      umów zawartych przez jego pracowników za pośrednictwem
                      Strony w&nbsp;okresie nie krótszym niż miesiąc.
                    </Paragraph>
                    <Paragraph size="small">
                      Naszymi partnerami są również autoryzowani dealerzy. Twoje
                      dane osobowe możemy przekazywać (udostępniać) im
                      w&nbsp;celu przygotowania dla Ciebie odpowiedniej oferty.
                      Podstawą prawną przekazania danych osobowych jest Twoja
                      zgoda (art. 6 ust. 1 lit a&nbsp;RODO).
                    </Paragraph>
                  </LeftPad>

                  <Title level={3} variant="condensed">
                    4.2 Dostawcy usług
                  </Title>
                  <LeftPad>
                    <Paragraph size="small">
                      Przekazujemy Twoje dane osobowe dostawcom usług,
                      z&nbsp;których korzystamy przy prowadzeniu Strony.
                    </Paragraph>
                    <Paragraph size="small">
                      Świadczą oni dla nas m.in. usługę hostingu w&nbsp;chmurze
                      obliczeniowej, dostarczają nam systemy do marketingu
                      online, do wysyłki wiadomości e-mail, do komunikacji
                      tekstowej i&nbsp;wideo, do analizy ruchu na Stronie, do
                      analizy skuteczności kampanii marketingowych, jak również
                      wspierają realizację określonych usług
                      i&nbsp;funkcjonalności konta. Jednym z naszych głównych
                      dostawców usług, dzięki któremu możliwe jest świadczenie
                      przez nas Programu Mobility 3.0, o którym mowa powyżej,
                      jest Edenred Polska sp. z o.o. z siedzibą Warszawie, ul.
                      Inflancka 4b, 00-189 Warszawa.
                    </Paragraph>
                    <Paragraph size="small">
                      Możemy przekazywać również Twoje dane osobowe innym
                      odbiorcom, którzy świadczą dla nas usługi prawne,
                      kurierskie czy też pocztowe.
                    </Paragraph>
                  </LeftPad>

                  <Title level={3} variant="condensed">
                    4.3 Organy państwowe
                  </Title>
                  <LeftPad>
                    <Paragraph size="small">
                      Udostępniamy Twoje dane osobowe, jeżeli zwrócą się do nas
                      o&nbsp;to uprawnione organy państwowe,
                      w&nbsp;szczególności jednostki organizacyjne prokuratury,
                      Policja, Prezes Urzędu Ochrony Danych Osobowych, Prezes
                      Urzędu Ochrony Konkurencji i&nbsp;Konsumentów lub Prezes
                      Urzędu Komunikacji Elektronicznej.
                    </Paragraph>
                  </LeftPad>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary>
                  5. Obszar przetwarzania danych osobowych
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Paragraph size="small">
                    Ponieważ korzystamy z usług Google Operations Ireland
                    Limited (Google Workspace) i Facebook Ireland Limited
                    (Facebook Pixel), LinkedIn, Freshmail i&nbsp;tym podobnych.
                    Twoje dane osobowe mogą być przekazywane przez te podmioty
                    poza Europejski Obszar Gospodarczy na podstawie
                    standardowych klauzul umownych (art. 46 ust. 2 lit.
                    c&nbsp;RODO) i&nbsp;odpowiednich technicznych środków
                    bezpieczeństwa. Wszelkie pytania dotyczące przekazywania
                    danych poza EOG należy kierować bezpośrednio do tych
                    podmiotów. Możesz zawnioskować o&nbsp;kopię danych
                    przekazanych poza EOG.
                  </Paragraph>
                  <Paragraph size="small">
                    Więcej informacji znajdziesz tutaj:
                  </Paragraph>
                  <Paragraph size="small">
                    <ul>
                      <li>
                        <SimpleLink
                          href="https://policies.google.com/privacy/frameworks"
                          target="_blank"
                        >
                          Zasady przesyłania danych Google
                        </SimpleLink>
                      </li>
                      <li>
                        <SimpleLink
                          href="https://www.facebook.com/legal/technology_terms"
                          target="_blank"
                        >
                          Warunki Narzędzi Biznesowych Facebook
                        </SimpleLink>
                      </li>
                      <li>
                        <SimpleLink
                          href="https://www.linkedin.com/help/linkedin/answer/62548/przekazywanie-danych-z-unii-europejskiej-europejskiego-obszaru-gospodarczego-i-szwajcarii?lang=pl"
                          target="_blank"
                        >
                          Przekazywanie danych z Unii Europejskiej LinkedIn
                        </SimpleLink>
                      </li>
                    </ul>
                  </Paragraph>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary>6. Profilowanie</ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Paragraph size="small">
                    Przetwarzanie Twoich danych nie jest oparte na decyzjach
                    podejmowanych automatycznie (w&nbsp;tym na automatycznym
                    profilowaniu).
                  </Paragraph>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary>
                  7. Jakie prawa Ci przysługują?
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Paragraph size="small">
                    7.1 <strong>Prawo dostępu</strong> do Twoich danych
                    osobowych, uzyskania potwierdzenia, czy Twoje dane osobowe
                    są wykorzystywane oraz do uzyskania ich kopii.
                  </Paragraph>

                  <Paragraph size="small">
                    7.2 <strong>Prawo do przenoszenia</strong> danych osobowych,
                    które nam dostarczyłaś/eś i przekazania jej Tobie lub innemu
                    wskazanemu pomiotowi w powszechnie używanym, możliwym do
                    odczytu komputerowego formacie.
                  </Paragraph>

                  <Paragraph size="small">
                    7.3 <strong>Prawo do sprostowania</strong> Twoich danych
                    osobowych, gdy są niekompletne lub nieprawidłowe.
                  </Paragraph>

                  <Paragraph size="small">
                    7.4 <strong>Prawo do usunięcia</strong> Twoich wszystkich
                    lub niektórych danych osobowych.
                  </Paragraph>

                  <Paragraph size="small">
                    7.5 <strong>Prawo do ograniczenia przetwarzania</strong>{' '}
                    Twoich danych osobowych.
                  </Paragraph>

                  <Paragraph size="small">
                    7.6 <strong>Prawo do wniesienia sprzeciwu</strong> wobec
                    przetwarzania, które dokonujemy w oparciu o&nbsp;nasz
                    uzasadniony interes.
                  </Paragraph>

                  <Paragraph size="small">
                    7.7 <strong>Prawo do wniesienia sprzeciwu</strong> wobec
                    przetwarzania dokonywanego w oparciu o&nbsp;nasz prawnie
                    uzasadniony interes.
                  </Paragraph>

                  <Paragraph size="small">
                    7.8 <strong>Prawo do cofnięcia wyrażonej zgody</strong>na
                    przetwarzanie danych osobowych w dowolnym momencie – jeśli
                    przetwarzanie odbywa się w&nbsp;oparciu o&nbsp;zgodę.
                    Cofnięcie zgody pozostaje jednak bez wpływu na zgodność
                    z&nbsp;prawem wykorzystywania danych na podstawie zgody
                    w&nbsp;okresie przed jej odwołaniem.
                  </Paragraph>

                  <Paragraph size="small">
                    Pamiętaj jednak, że nie zawsze będziemy mogli spełnić Twoją
                    prośbę lub żądanie np. ze względu na obowiązujące nas
                    przepisy prawa lub niespełnienie przesłanek do uznania
                    żądania. Poinformujemy Cię o&nbsp;podjętych działaniach lub
                    o&nbsp;powodach odmowy ich podjęcia. Aby skorzystać
                    z&nbsp;przysługujących Ci praw, skontaktuj się z&nbsp;nami
                    w&nbsp;sposób opisany w sekcji 2 powyżej{' '}
                    <i>„Jak się z&nbsp;nami skontaktować?”</i>
                  </Paragraph>

                  <Paragraph size="small">
                    Jeśli uważasz, że przetwarzając dane osobowe, działamy
                    niewłaściwie lub w&nbsp;sposób niezgodny z&nbsp;prawem,
                    możesz wnieść <strong>skargę do organu nadzorczego</strong>,
                    powołanego do nadzorowania przestrzegania przepisów
                    z&nbsp;zakresu ochrony danych osobowych w Polsce, czyli
                    Urzędu Ochrony Danych Osobowych (www.uodo.gov.pl).
                  </Paragraph>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary>
                  8. Odnośniki do innych stron internetowych
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Paragraph size="small">
                    Dla Twojej wygody i&nbsp;w celach informacyjnych na naszej
                    Stronie możemy zamieszczać odnośniki do innych stron
                    internetowych. Inne strony internetowe, do których
                    zamieszczamy odnośniki, zazwyczaj publikują własne polityki
                    dotyczące prywatności. Zachęcamy do zapoznania się z nimi
                    w&nbsp;przypadku odwiedzania takich stron. Nie ponosimy
                    odpowiedzialności za treści publikowane na innych stronach
                    internetowych, za same strony ani za praktyki prowadzących
                    je podmiotów dotyczące prywatności.
                  </Paragraph>

                  <Paragraph size="small">
                    Ponadto, możemy przetwarzać Twoje dane pozyskane za
                    pośrednictwem mediów społecznościowych. Jeżeli odwiedzasz
                    nasze profile (np. Facebook, Instagram, LinkedIn, Twitter),
                    w&nbsp;tym uczestniczysz w&nbsp;różnych naszych akcjach
                    organizowanych w&nbsp;takich serwisach społecznościowych,
                    przetwarzamy Twoje dane osobowe pozyskane za pośrednictwem
                    tych profili, m.in. Twoje imię i&nbsp;nazwisko, nazwę
                    użytkownika, zdjęcie profilowe (avatar), informacje zawarte
                    w&nbsp;Twoich postach i&nbsp;komentarzach. Podstawą prawną
                    takiego przetwarzania jest nasz prawnie uzasadniony interes
                    - pozwala nam to na budowanie pozytywnych relacji
                    z&nbsp;naszymi użytkownikami lub użytkownikami
                    niezalogowanymi w&nbsp;mediach społecznościowych. Dane
                    w&nbsp;tym zakresie będziemy przetwarzać przez okres
                    funkcjonowania naszego profilu lub do czasu zgłoszenia
                    Twojego sprzeciwu.
                  </Paragraph>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary>
                  9. Pliki Cookies i&nbsp;inne informacje przechowywane
                  w&nbsp;Twoim urządzeniu końcowym
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Title level={3} variant="condensed">
                    9.1 Czym są pliki cookies i&nbsp;inne informacje?
                  </Title>
                  <LeftPad>
                    <Paragraph size="small">
                      Na Stronie wykorzystujemy pliki „cookie”.
                    </Paragraph>
                    <Paragraph size="small">
                      Pliki „cookie” to pliki tekstowe przesyłane przez strony
                      internetowe na komputer lub inne połączone
                      z&nbsp;Internetem Twoje urządzenie, które umożliwiają
                      jednoznaczne zidentyfikowanie Twojej przeglądarki lub
                      zachowanie informacji o&nbsp;jej ustawieniach.
                    </Paragraph>
                    <Paragraph size="small">
                      Pliki cookies zazwyczaj zawierają nazwę witryny,
                      z&nbsp;której pochodzą, ich czas przechowywania na
                      urządzeniu końcowym i specjalny numer. Rejestrują one
                      w&nbsp;szczególności takie dane jak: kliknięcia na
                      Stronie, odwiedziny na Stronie (głównej
                      i&nbsp;podstronach) czy też dane dotyczące korzystania
                      z&nbsp;określonych usług na Stronie.
                    </Paragraph>
                    <Paragraph size="small">
                      Ponadto, stosujemy rozwiązania pozwalające nam na
                      rozpoznawanie urządzeń (w oparciu o analizę zebranych
                      informacji dotyczących Twojego urządzenia), token
                      użytkownika oraz rozwiązania pomagające nam określić, czy
                      z danej przeglądarki miało już miejsce logowanie do
                      aplikacji (poprzez nadanie identyfikatora danym
                      wynikającym z użycia urządzenia).
                    </Paragraph>
                  </LeftPad>

                  <Title level={3} variant="condensed">
                    9.2 W jakich celach używamy plików cookies i&nbsp;innych
                    informacji?
                  </Title>
                  <LeftPad>
                    <Paragraph size="small">
                      Wykorzystujemy je w&nbsp;następujących celach:
                    </Paragraph>
                    <LeftPad>
                      <Paragraph size="small">
                        a) w celu zapewnienia bezpieczeństwa np. w celu
                        wykrywania nadużyć w zakresie uwierzytelniania w ramach
                        Strony (w tym celu przechowujemy listę adresów IP, z
                        których łączą się z aplikacją użytkownicy, aby
                        ograniczyć ryzyko ataków DDoS oraz wykorzystujemy
                        rozwiązania pomagające nam określić, czy z danej
                        przeglądarki miało już miejsce logowanie do aplikacji);
                      </Paragraph>
                      <Paragraph size="small">
                        b) utrzymania Twojej sesji (jeśli jesteś zalogowany)
                        oraz umożliwienia Ci skorzystania z&nbsp;usług
                        dostępnych w&nbsp;ramach Strony, co oznacza, że nie
                        musisz ponownie dokonywać logowania na&nbsp;każdej
                        podstronie Strony (w&nbsp;tym celu nadajemy użytkownikom
                        unikalny i&nbsp;zaszyfrowany token, przechowywany w
                        przeglądarce użytkownika);
                      </Paragraph>
                      <Paragraph size="small">
                        c) przygotowania statystyk dotyczących wyświetleń stron
                        i&nbsp;podstron Strony (analityczne pliki cookies) -
                        używamy plików cookies naszych partnerów (np. Google
                        Analytics) w&nbsp;celu liczenia wizyt na Stronie, ich
                        długości oraz określenia, jakie funkcjonalności Strony
                        lub&nbsp;jej części były najczęściej wykorzystywane lub
                        odwiedzane. Zbierane w&nbsp;ten sposób informacje
                        pozwalają nam analizować wydajność Strony oraz określać
                        kierunki rozwoju nowych funkcjonalności i usług.
                      </Paragraph>
                      <Paragraph size="small">
                        d) w celu umożliwienia Ci skorzystania z&nbsp;usługi
                        LiveChat.
                      </Paragraph>
                    </LeftPad>
                  </LeftPad>

                  <Title level={3} variant="condensed">
                    9.3 Z jakich rodzajów plików cookies i&nbsp;innych
                    informacji korzystamy?
                  </Title>
                  <LeftPad>
                    <Paragraph size="small">
                      Na Stronie Mobility stosuje następujące pliki cookies
                      i&nbsp;inne informacje:
                    </Paragraph>
                    <table style={{ width: '100%', textAlign: 'center' }}>
                      <tr>
                        <th>Nazwa pliku cookie</th>
                        <th>Dostawca</th>
                        <th>Cel</th>
                        <th>Okres przechowywania</th>
                      </tr>
                      <tr>
                        <td colSpan={4} style={{ padding: '0.5rem' }}>
                          PREFERENCYJNE
                        </td>
                      </tr>
                      <tr>
                        <td>li_gc</td>
                        <td>linkedin.com</td>
                        <td>
                          Przechowywanie wyrażonych zgód na poszczególne pliki
                          cookies
                        </td>
                        <td>2 lata</td>
                      </tr>
                      <tr>
                        <td>test_cookie</td>
                        <td>doubleclick.net</td>
                        <td>
                          Sprawdzanie kompatybilności przeglądarki użytkownika z
                          wykorzystywanymi na stronie plikami
                        </td>
                        <td>1 dzień</td>
                      </tr>
                      <tr>
                        <td colSpan={4} style={{ padding: '0.5rem' }}>
                          NIEZBĘDNE
                        </td>
                      </tr>
                      <tr>
                        <td>@@lc_auth_token:#</td>
                        <td>secure.livechatinc.com</td>
                        <td>
                          Identyfikacja użytkowników strony w celu optymalizacji
                          funkcjonalności czatu na stronie internetowej
                        </td>
                        <td>Trwały</td>
                      </tr>
                      <tr>
                        <td>__lc_cid</td>
                        <td>accounts.livechatinc.com</td>
                        <td>
                          Niezbędny do działania funkcji chatboxa na stronie
                          internetowej
                        </td>
                        <td>2 lata</td>
                      </tr>
                      <tr>
                        <td>__lc_cst</td>
                        <td>accounts.livechatinc.com</td>
                        <td>
                          Niezbędny do działania funkcji chatboxa na stronie
                          internetowej
                        </td>
                        <td>2 lata</td>
                      </tr>
                      <tr>
                        <td>_oauth_redirect_detector</td>
                        <td>accounts.livechatinc.com</td>
                        <td>
                          Identyfikacja użytkowników strony w celu optymalizacji
                          funkcjonalności czatu na stronie internetowej
                        </td>
                        <td>1 dzień</td>
                      </tr>
                      <tr>
                        <td>lang</td>
                        <td>ads.linkedin.com</td>
                        <td>
                          Zapamiętywanie wybranego przez użytkownika języka na
                          stronie
                        </td>
                        <td>Podczas sesji</td>
                      </tr>
                      <tr>
                        <td>side_storage_#</td>
                        <td>secure.livechatinc.com</td>
                        <td />
                        <td>Trwały</td>
                      </tr>
                      <tr>
                        <td colSpan={4} style={{ padding: '0.5rem' }}>
                          STATYSTYCZNE
                        </td>
                      </tr>
                      <tr>
                        <td>_ga</td>
                        <td>carplatform.pl</td>
                        <td>
                          Zbieranie informacji o sposobie korzystania ze strony
                          internetowej
                        </td>
                        <td>2 lata</td>
                      </tr>
                      <tr>
                        <td>_gat</td>
                        <td>carplatform.pl</td>
                        <td>Ustalenie przepustowości strony internetowe</td>
                        <td>1 dzień</td>
                      </tr>
                      <tr>
                        <td>_gid</td>
                        <td>carplatform.pl</td>
                        <td>
                          Zbieranie informacji o sposobie korzystania ze strony
                          internetowej
                        </td>
                        <td>1 dzień</td>
                      </tr>
                      <tr>
                        <td>_hjAbsoluteSessionInProgress</td>
                        <td>carplatform.pl</td>
                        <td>
                          Ustalenie ilości wyświetleń strony przez użytkownika
                        </td>
                        <td>1 dzień</td>
                      </tr>
                      <tr>
                        <td>_hjFirstSeen</td>
                        <td>carplatform.pl</td>
                        <td>
                          Ustalenie czy użytkownik odwiedza stronę pierwszy raz
                        </td>
                        <td>1 dzień</td>
                      </tr>
                      <tr>
                        <td>_hjIncludedInPageviewSample</td>
                        <td>carplatform.pl</td>
                        <td>
                          Ustalenie czy interakcje użytkownika są uwzględnione w
                          danych analitycznych strony internetowej
                        </td>
                        <td>1 dzień</td>
                      </tr>
                      <tr>
                        <td>_hjIncludedInSessionSample</td>
                        <td>carplatform.pl</td>
                        <td>
                          Rejestrowanie zachowań użytkowników strony
                          internetowej
                        </td>
                        <td>1 dzień</td>
                      </tr>
                      <tr>
                        <td>hjRecordingLastActivity</td>
                        <td>carplatform.pl</td>
                        <td>Zbieranie danych behawioralnych do statystyk</td>
                        <td>Podczas sesji</td>
                      </tr>
                      <tr>
                        <td>_hjSession_#</td>
                        <td>carplatform.pl</td>
                        <td>
                          Zbieranie danych statystycznych o użytkownikach strony
                          internetowej
                        </td>
                        <td>1 dzień</td>
                      </tr>
                      <tr>
                        <td>_hjSessionUser_#</td>
                        <td>carplatform.pl</td>
                        <td>
                          Zbieranie danych statystycznych o użytkownikach strony
                          internetowej
                        </td>
                        <td>1 rok</td>
                      </tr>
                      <tr>
                        <td>_livechat_has_visited</td>
                        <td>secure.livechatinc.com</td>
                        <td>
                          Identyfikacja użytkowników w celu dostosowania
                          funkcjonalności chatbox.
                        </td>
                        <td>Trwałe</td>
                      </tr>
                      <tr>
                        <td>AnalyticsSyncHistory</td>
                        <td>linkedin.com</td>
                        <td>
                          Synchronizacja danych z administratorem trzeciej
                          strony
                        </td>
                        <td>30 dni</td>
                      </tr>
                      <tr>
                        <td>hjViewportId</td>
                        <td>carplatform.pl</td>
                        <td>Pozyskiwanie danych behawioralnych</td>
                        <td>Podczas sesji</td>
                      </tr>
                      <tr>
                        <td colSpan={4} style={{ padding: '0.5rem' }}>
                          MARKETINGOWE
                        </td>
                      </tr>
                      <tr>
                        <td>_fbp</td>
                        <td>carplatform.pl</td>
                        <td>
                          Dostarczanie spersonalizowanych reklam (Facebook)
                        </td>
                        <td>3 miesiące</td>
                      </tr>
                      <tr>
                        <td>gcl_au</td>
                        <td>carplatform.pl</td>
                        <td>
                          Dostarczanie spersonalizowanych reklam (Google
                          AdSense)
                        </td>
                        <td>3 miesiące</td>
                      </tr>
                      <tr>
                        <td>_hjRecordingEnabled</td>
                        <td>carplatform.pl</td>
                        <td>Optymalizacja wyświetlanych rekla</td>
                        <td>Podczas sesji</td>
                      </tr>
                      <tr>
                        <td>bcookie</td>
                        <td>linkedin.com</td>
                        <td>
                          Zbieranie danych o wykorzystywaniu innych usług
                          (LinkedIn)
                        </td>
                        <td>2 lata</td>
                      </tr>
                      <tr>
                        <td>bscookie</td>
                        <td>linkedin.com</td>
                        <td>
                          Zbieranie danych o wykorzystywaniu innych usług
                          (LinkedIn)
                        </td>
                        <td>2 lata</td>
                      </tr>
                      <tr>
                        <td>lang</td>
                        <td>linkedin.com</td>
                        <td>
                          Wykorzystanie przycisku „Obserwuj nas”
                          przekierowującego na profil na LinkedIn
                        </td>
                        <td>Podczas sesji</td>
                      </tr>
                      <tr>
                        <td>lidc</td>
                        <td>linkedin.com</td>
                        <td>
                          Zbieranie danych o wykorzystywaniu innych usług
                          (LinkedIn)
                        </td>
                        <td>1 dzień</td>
                      </tr>
                      <tr>
                        <td>pagead/1p-user-list/#</td>
                        <td>google.com</td>
                        <td>
                          Obserwacja zainteresowania określonymi produktami
                        </td>
                        <td>Podczas sesji</td>
                      </tr>
                      <tr>
                        <td>tr</td>
                        <td>facebook.com</td>
                        <td>Dostarczanie reklam przez Facebook</td>
                        <td>Podczas sesji</td>
                      </tr>
                      <tr>
                        <td>UserMatchHistory</td>
                        <td>linkedin.com</td>
                        <td>
                          Śledzenie użytkowników na kilku stronach w celu
                          dostosowania reklam
                        </td>
                        <td>30 dni</td>
                      </tr>
                      <tr>
                        <td colSpan={4} style={{ padding: '0.5rem' }}>
                          INNE
                        </td>
                      </tr>
                      <tr>
                        <td>11952573:state</td>
                        <td>secure.livechatinc.com</td>
                        <td />
                        <td>Trwałe</td>
                      </tr>
                      <tr>
                        <td>persist:auth</td>
                        <td>carplatform.pl</td>
                        <td />
                        <td>Trwałe</td>
                      </tr>
                      <tr>
                        <td>persist:cookies</td>
                        <td>carplatform.pl</td>
                        <td />
                        <td>Trwałe</td>
                      </tr>
                    </table>
                  </LeftPad>

                  <Title level={3} variant="condensed">
                    9.4 Jak zarządzać plikami cookies i&nbsp;innymi
                    informacjami?
                  </Title>
                  <LeftPad>
                    <Paragraph size="small">
                      Przeglądarka internetowa bardzo często domyślnie dopuszcza
                      przechowywanie plików cookies w&nbsp;Twoim urządzeniu
                      końcowym. Możesz jednak sam skonfigurować przeglądarkę
                      internetową w&nbsp;taki sposób, aby pliki cookies nie były
                      zapisywane na Twoim urządzeniu (może to dotyczyć albo
                      wszystkich plików cookies, albo plików cookies stron
                      trzecich), a&nbsp;także usunąć pliki cookies, które już
                      zostały zapisane.
                    </Paragraph>
                    <Paragraph size="small">
                      W pasku menu przeglądarki internetowej w&nbsp;sekcji
                      &bdquo; Pomoc&rdquo; można znaleźć informacje, jak
                      odrzucić zapisywanie nowych plików cookies, jak usunąć
                      dotychczas zapisane pliki cookies, jak żądać powiadamiania
                      o&nbsp;zapisaniu nowego pliku cookies oraz jak zablokować
                      działanie plików cookies.
                    </Paragraph>
                    <Paragraph size="small">
                      Poniżej znajdują się linki do informacji
                      o&nbsp;ustawieniach cookies, w&nbsp;tym informacji
                      o&nbsp;tym usuwać cookies w&nbsp;najpopularniejszych
                      przeglądarkach internetowych:
                    </Paragraph>
                    <Paragraph size="small">
                      <ul>
                        <li>
                          Firefox:{' '}
                          <SimpleLink
                            href="https://support.mozilla.org/pl/kb/ciasteczka"
                            target="_blank"
                          >
                            tutaj
                          </SimpleLink>
                        </li>
                        <li>
                          Opera:{' '}
                          <SimpleLink
                            href="https://www.opera.com/help/tutorials/security/privacy/"
                            target="_blank"
                          >
                            tutaj
                          </SimpleLink>
                        </li>
                        <li>
                          Internet Explorer:{' '}
                          <SimpleLink
                            href="https://support.microsoft.com/pl-pl/help/17442"
                            target="_blank"
                          >
                            tutaj
                          </SimpleLink>
                        </li>
                        <li>
                          Microsoft Edge:{' '}
                          <SimpleLink
                            href="https://support.microsoft.com/pl-pl/help/4027947/microsoft-edge-delete-cookies"
                            target="_blank"
                          >
                            tutaj
                          </SimpleLink>
                        </li>
                        <li>
                          Chrome:{' '}
                          <SimpleLink
                            href="https://support.google.com/chrome/answer/95647?hl=pl"
                            target="_blank"
                          >
                            tutaj
                          </SimpleLink>
                        </li>
                        <li>
                          Safari:{' '}
                          <SimpleLink
                            href="https://support.apple.com/pl-pl/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
                            target="_blank"
                          >
                            tutaj
                          </SimpleLink>
                        </li>
                      </ul>
                    </Paragraph>
                  </LeftPad>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary>
                  10. Aktualizacje Polityki prywatności i&nbsp;cookies
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Paragraph size="small">
                    Niniejsza Polityka prywatności i&nbsp;cookies może być
                    okresowo aktualizowana. Informację o&nbsp;wszelkich
                    istotnych zmianach w&nbsp;tym dokumencie zamieścimy na
                    Stronie, a&nbsp;w&nbsp;górnej części niniejszego dokumentu
                    wskażemy datę ostatniej aktualizacji.
                  </Paragraph>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </section>
          </ScrollContent>
        </FlexScrollContainer>
      </Container>
    </AsideMainMenuLayout>
  );
};

// eslint-disable-next-line import/no-default-export
export default PrivacyPolicyAndCookiesPage;
