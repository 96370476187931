import React, { useCallback } from 'react';
import { SearchHeaderFilter } from 'src/app/search/components/SearchHeader/SearchHeaderFilter';
import { FilterGroupPanel } from 'src/app/search/models/filterGroupPanel';
import { PriceRangeFilter } from 'src/app/search/components/PriceRangeFilter';
import Sliders from 'src/assets/icons/Sliders';
import styled, { css } from 'styled-components';
import { media, widthBreakpoints } from 'src/config/breakpoints';
import { Button } from 'src/common/components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { activeSearchPanelSelector } from 'src/app/search/state/searchPanel/searchPanelSelectors';
import { searchPanelActions } from 'src/app/search/state/searchPanel/searchPanelActions';
import { useMediaQuery } from 'react-responsive';
import { B2BB2CSwitch } from 'src/common/components/B2BB2CSwitch';
import { FinancingTypeSwitch } from 'src/common/components/FinancingTypeSwitch';
import { colors } from 'src/config/colors';
import { HorizontalToggleButton } from 'src/common/components/HorizontalToggleButton';
import { AvailabilitySwitch } from 'src/common/components/AvailabilitySwitch';
import { Box } from '@mui/material';
import { SelectedOptions } from 'src/app/search/components/SearchHeader/SelectedOptions';
import { SortingButton } from 'src/common/components/SortingButton';

const PriceFilterContainer = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: min-content 1fr;

  ${media.w.max.px992(css`
    display: none;
  `)};
`;

const FiltersButton = styled(Button).attrs({
  kind: 'box',
  variant: 'transparent',
})`
  flex: 0 0 auto;
  margin-left: auto;
  font-size: 16px !important;

  ${media.w.min.px1200(css`
    display: none;
  `)};
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  ${media.w.max.px1366(css`
    justify-content: space-between;
  `)};
`;

const StyledAvailabilitySwitch = styled(AvailabilitySwitch)`
  background-color: rgba(255, 255, 255, 0.2);
  border: none;

  ${media.w.max.px992(css`
    display: none;
  `)};
`;

const StyledB2BB2CSwitch = styled(B2BB2CSwitch)`
  margin-left: 0.625rem;
  background-color: rgba(255, 255, 255, 0.2);
  border: none;

  ${media.w.min.px1366(css`
    margin-left: 3rem;
  `)};

  ${media.w.max.px992(css`
    display: none;
  `)};
`;

const StyledFinancingTypeSwitch = styled(FinancingTypeSwitch)`
  margin-left: 0.625rem;
  background-color: rgba(255, 255, 255, 0.2);
  border: none;

  ${media.w.min.px1366(css`
    margin-left: 3rem;
  `)};

  ${media.w.max.px992(css`
    display: none;
  `)};
`;

const PriceRangeTitle = styled.div`
  color: ${colors.background};
  font-size: 0.75rem;
  margin-right: 0;
  margin-left: 1.5rem;

  ${media.w.max.px1200(css`
    margin-right: 0.75rem;
    margin-left: 0.75rem;
  `)};

  ${media.w.min.px1366(css`
    margin-right: 0.75rem;
    margin-left: 3rem;
  `)};
`;

const PriceRangeFilterContainer = styled.div`
  width: 16.25rem;
`;

const Content = styled.div`
  display: flex;
`;

const StyledHorizontalToggleButton = styled(HorizontalToggleButton)<{
  value: boolean;
}>`
  color: ${colors.background};

  &:enabled {
    @media (hover: hover) {
      &:hover {
        background: rgba(255, 255, 255, 0.7);
      }
    }

    &:focus {
      border-color: ${colors.white};
    }
  }

  ${({ value }) =>
    value &&
    css`
      color: ${colors.background};
      background-color: ${colors.white};
      font-weight: 400;

      &:enabled {
        @media (hover: hover) {
          &:hover {
            background-color: ${colors.white};
          }
        }

        &:focus {
          border-color: ${colors.white};
        }
      }
    `}
  &:disabled {
    opacity: 0.3;
  }
`;

export const SearchHeaderOptions = () => {
  const activePanel = useSelector(activeSearchPanelSelector);
  const dispatch = useDispatch();
  const closeFilters = useCallback(() => dispatch(searchPanelActions.close()), [
    dispatch,
  ]);
  const openFilters = useCallback(
    () => dispatch(searchPanelActions.openCrossroad()),
    [dispatch]
  );
  const isDesktop = useMediaQuery({ minWidth: widthBreakpoints.px1200 });

  return (
    <Box>
      <Container>
        <Content id="SearchHeaderFilters">
          <SearchHeaderFilter group={FilterGroupPanel.General} />
          <SearchHeaderFilter group={FilterGroupPanel.Performance} />
          <SearchHeaderFilter group={FilterGroupPanel.Appearance} />
        </Content>

        <StyledAvailabilitySwitch
          toggleButtonComponent={StyledHorizontalToggleButton}
        />
        <StyledB2BB2CSwitch
          toggleButtonComponent={StyledHorizontalToggleButton}
        />
        <StyledFinancingTypeSwitch
          toggleButtonComponent={StyledHorizontalToggleButton}
        />
        <PriceFilterContainer>
          <PriceRangeTitle>MIESIĘCZNA RATA</PriceRangeTitle>
          <PriceRangeFilterContainer>
            <PriceRangeFilter />
          </PriceRangeFilterContainer>
        </PriceFilterContainer>
        <SortingButton />
        <FiltersButton
          onClick={activePanel != null ? closeFilters : openFilters}
          icon={Sliders}
        >
          Wyszukiwanie zaawansowane
        </FiltersButton>
      </Container>
      {isDesktop && <SelectedOptions />}
    </Box>
  );
};
