import React, { memo, useCallback, useEffect, useState } from 'react';
import { HomeWelcomeTitle } from 'src/app/home/components/HomeWelcomeTitle';
import { HomePanelFiltersTabs } from 'src/app/home/components/HomePanel/HomePanelFiltersTab';
import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import { below, media, widthBreakpoints } from 'src/config/breakpoints';
import { Logo } from 'src/common/components/Logo';
import { Button, CleanButton } from 'src/common/components/Button';
import {
  Availability,
  EngineType,
  GearboxType,
} from 'src/app/common/models/product';
import { HorizontalToggleButton } from 'src/common/components/HorizontalToggleButton';
import { ToggleButtonGroup } from 'src/common/components/ToggleButtonGroup';
import { HorizontalToggleGroupContainer } from 'src/common/components/HorizontalToggleButtonGroup';
import { LabelRoboto } from 'src/app/common/components/typography/Label';
import { skewLeft } from 'src/common/styles/skew';
import { countLabelFormatter, prune } from '@summer/react-kit/functions';
import ArrowRight from 'src/assets/icons/ArrowRight';
import {
  ExoticFilter,
  FiltersState,
  FilterStateKindMap,
  OptionFilter,
  RangeFilter,
} from 'src/app/common/models/filter';
import { localFormatNumber } from 'src/lib/localFormatNumber';
import { HomePriceRangeFilter } from 'src/app/home/components/HomePriceRangeFilter';
import { searchWith } from 'src/app/search/services/filters';
import {
  searchProducts,
  searchUsedProducts,
} from 'src/app/search/services/product';
import { formattedSearchFilter } from 'src/app/search/services/filterSerializer';
import { AppHeader } from 'src/common/components/AppHeader';
import { searchFiltersActions } from 'src/app/search/state/searchFilters/searchFiltersActions';
import { useDispatch, useSelector } from 'react-redux';
import { filterConfig } from 'src/app/common/models/filterConfig';
import { productsPreferenceSelector } from 'src/state/settings/settingsSelectors';
import { customerTypeSelector } from 'src/app/state/customerType/customerTypeSelectors';
import { isAuthenticatedSelector } from 'src/state/auth/authSelectors';
import { navigate } from 'gatsby';
import { LoginRoute } from 'src/public/publicRoutes';
import { useMediaQuery } from 'react-responsive';
import { rgba } from 'polished';
import { CarPlatformBusinessBenefitsModal } from 'src/common/components/CarPlatformBusinessBenefitsModal';
import { ModalPortal } from 'src/app/common/components/ModalPortal';
import { EventName, pushEvent } from 'src/common/services/googleTagManager';
import { typography } from 'src/config/typography';
import {
  pushEngineChoiceEvent,
  pushGearboxChoiceEvent,
} from 'src/app/home/services/homeGoogleTagManagerEvents';
import { ToggleButtonGroupMany } from 'src/common/components/ToggleButtonGroupMany';
import { brandsListSelector } from 'src/app/state/commonFilters/commonFiltersSelectors';
import { useLocation } from '@reach/router';
import { filter, isEmpty, pipe, split } from 'ramda';

const PanelContent = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  color: ${colors.white};
  padding: 1.125rem 1rem;
  height: 100%;

  ${media.w.min.px768(css`
    font-size: 1.3125rem;
    padding: 1.5rem 1.5rem;

    ${media.h.min.any(750)(css`
      padding: 2.5rem 1.5rem;
    `)}
  `)}
`;

const Header = styled.header`
  display: flex;
  align-items: center;

  ${media.w.max.px768(css`
    display: none;
  `)}
`;

const AdditionalFilters = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-row-gap: 0.625rem;
  grid-column-gap: 0.375rem;
  grid-template-rows: min-content;
  align-items: center;

  ${media.w.min.px576(
    css`
      grid-column-gap: 1rem;
    `
  )};

  ${media.w.min.px768(
    css`
      ${media.h.max.any(750)(css`
        grid-row-gap: 0.625rem;
      `)}
      grid-template-columns: max-content 1fr;
      grid-column-gap: 2.25rem;
    `
  )};
`;
const AdditionalFiltersVehicleType = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-row-gap: 0.625rem;
  grid-column-gap: 0.6rem;
  grid-template-rows: min-content;
  align-items: center;

  ${media.w.min.px576(
    css`
      grid-column-gap: 1.25rem;
    `
  )};

  ${media.w.min.px768(
    css`
      ${media.h.max.any(750)(css`
        grid-row-gap: 0.625rem;
      `)}
      grid-template-columns: max-content 1fr;
      grid-column-gap: 2.5rem;
    `
  )};
`;

const StyledLabelRoboto = styled(LabelRoboto)`
  margin-bottom: 0;
  font-size: 0.625rem;

  ${media.w.min.px1200(css`
    margin: 0 0 0 1.5rem;
  `)}

  ${media.w.min.px1366(css`
    margin: 0 0.75rem;
  `)}

  ${media.w.min.px768(css`
    font-size: 0.75rem;
  `)}
`;

const ShowOffersButton = styled(Button)`
  display: inline-block;
  align-self: flex-start;
  z-index: 10;
  width: 100%;
  margin-top: 1.875rem;

  & span {
    width: 100%;
  }

  ${media.w.max.px768(css`
    align-self: flex-start;

    &.btn-skew.btn-default > .btn__content {
      padding-left: 2.5rem;
      margin-right: -2rem;
      ${skewLeft('1.4375rem')};
      background: ${colors.white};
    }
  `)}

  ${media.w.min.px768(css`
    margin-top: 2.5rem;

    ${media.h.min.any(750)(css`
      margin-top: 3rem;
    `)}
  `)};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${media.h.min.any(750)(
    css`
      height: 100%;
    `
  )};
`;

const RangeFilterContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-column-gap: 0.375rem;
  margin-top: 0.625rem;

  ${media.w.min.px768(
    css`
      grid-template-columns: max-content 1fr;
      grid-column-gap: 2.25rem;
    `
  )};
`;

const StyledHorizontalToggleButton = styled(HorizontalToggleButton)`
  flex: auto;
`;

const availabilityToggleButtonGroupProps = {
  limit: 1,
  items: [
    { id: Availability.Available, label: 'Od ręki' },
    { id: Availability.Unavailable, label: 'Do produkcji' },
  ],
  toggleButtonComponent: HorizontalToggleButton,
};

const gearboxToggleButtonGroupProps = {
  limit: 1,
  items: [
    { id: GearboxType.AT, label: 'Automatyczna' },
    { id: GearboxType.MT, label: 'Manualna' },
  ],
  toggleButtonComponent: HorizontalToggleButton,
};

export const carsAndMotorcycleGroupProps = {
  limit: 1,
  items: [
    { id: 'car', label: 'Samochody' },
    { id: 'motorcycle', label: 'Motocykle' },
  ],
  toggleButtonComponent: HorizontalToggleButton,
};

const engineToggleButtonGroupProps = {
  limit: 1,
  items: [
    { id: EngineType.Diesel, label: 'DIESEL' },
    { id: EngineType.Petrol, label: 'BENZYNA' },
    { id: EngineType.Hybrid, label: 'HYBRYDA' },
    { id: EngineType.Electric, label: 'ELEKTRYCZNY' },
  ],
  toggleButtonComponent: StyledHorizontalToggleButton,
};

const offerCountLabelFormatter = countLabelFormatter(
  '0 ofert',
  '1 ofertę',
  (count) => `${localFormatNumber(count)} oferty`,
  (count) => `${localFormatNumber(count)} ofert`
);

const StyledAppHeader = styled(AppHeader)<{ $tooltipShown?: boolean }>`
  ${media.w.min.px768(
    css`
      display: none;
    `
  )};

  ${({ $tooltipShown }) =>
    $tooltipShown &&
    css`
      margin-bottom: 3.5rem;
    `};
`;

const StyledLogo = styled(Logo)`
  margin-right: 2rem;
`;

const StyledNewUsedHorizontalToggleButton = styled(HorizontalToggleButton)`
  color: ${colors.primary};

  &:enabled {
    @media (hover: hover) {
      &:hover {
        background-color: ${colors.lightGray};
        color: ${colors.grayBasic};
      }
    }

    &:focus {
      border-color: ${colors.white};
    }
  }

  ${({ value }) =>
    value &&
    css`
      color: ${colors.white};
      background-color: ${colors.grayBasic};

      &:enabled {
        @media (hover: hover) {
          &:hover {
            background-color: ${colors.lightGray};
            color: ${colors.grayBasic};
          }
        }

        &:focus {
          border-color: ${colors.white};
        }
      }
    `}
  &:disabled {
    opacity: 0.3;
  }
`;

const NewUsedProductsPreferenceSwitchWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;

const NewUsedProductsSwitchLabel = styled.span`
  font-family: ${typography.fontFamilyCondensed};
  font-size: 0.75rem;
  font-weight: 300;
  margin-right: 0.625rem;
`;

const MobileNewUsedSwitchToggleButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 64.47px 1fr;
  grid-column-gap: 0.375rem;
  margin-bottom: 0.5rem;
`;

const Gradient = styled.div`
  width: 100%;
  height: 7.375rem;
  left: 0;
  position: absolute;
  z-index: -1;
  top: 0;
  background: radial-gradient(
    44.79% 79.17% at 50% 100%,
    #0081ff 0%,
    rgba(0, 129, 255, 0) 100%
  );
  opacity: 0.4;
  transform: matrix(1, 0, 0, -1, 0, 0);

  ${media.w.min.px768(css`
    display: none;
  `)};
`;

const HeaderChildrenWrapper = styled.div`
  flex: 0 0 auto;
  margin-left: auto;
`;

const LoginButton = styled(Button)`
  font-weight: 700;
  text-align: left;
`;

const LoginButtonTooltip = styled.div`
  position: absolute;
  left: -10rem;
  width: 16.5625rem;
`;

const TooltipArrow = styled.div`
  width: 1.5rem;
  height: 1.25rem;
  clip-path: polygon(0 1rem, 100% 0, 100% 100%, 0 100%);
  background-color: ${colors.primary};
  margin-left: 12rem;
  margin-bottom: -0.5rem;
`;

const TooltipContent = styled(CleanButton)`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 0.625rem;
  line-height: 0.875rem;
  padding: 0.375rem;
  color: ${colors.white};
  background-color: ${colors.primary};
  white-space: normal;
`;

const TooltipIcon = styled.div`
  margin-left: -0.75rem;
  margin-right: 0.5rem;
`;

export interface HomePanelFiltersState extends FiltersState {
  [OptionFilter.Availability]: FilterStateKindMap[OptionFilter.Brand];
  [OptionFilter.Gearbox]: FilterStateKindMap[OptionFilter.Brand];
  [OptionFilter.Brand]: FilterStateKindMap[OptionFilter.Brand];
  [OptionFilter.Body]: FilterStateKindMap[OptionFilter.Brand];
  [OptionFilter.Category]: FilterStateKindMap[OptionFilter.Brand];
  [OptionFilter.Engine]: FilterStateKindMap[OptionFilter.Brand];
  [RangeFilter.Price]: FilterStateKindMap[RangeFilter.Price];
}

export interface HomePanelContent {
  activeBrand?: string;
  onBackToAdvert: () => void;
  onTabChange?: () => void;
  scrollToBottom: () => void;
}

export const HomePanelContent = memo<HomePanelContent>(({ onTabChange }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [total, setTotal] = useState<number>();

  const brands = useSelector(brandsListSelector);
  const isLoggedIn = useSelector(isAuthenticatedSelector);
  const productsPreference = useSelector(productsPreferenceSelector);
  const userCustomerType = useSelector(customerTypeSelector);

  const [state, setState] = useState<HomePanelFiltersState>(() => {
    const codes: Record<string, string> = {};

    (brands ?? []).forEach((brand) => {
      codes[brand.code] = brand.id;
    });

    const selectedBrands = pipe(
      split('/'),
      filter((x) => !isEmpty(x))
    )(pathname);

    return {
      [OptionFilter.Availability]: {
        value: filterConfig[OptionFilter.Availability].initialValue,
      },
      [OptionFilter.Gearbox]: {
        value: filterConfig[OptionFilter.Gearbox].initialValue,
      },
      [OptionFilter.Brand]: {
        value: selectedBrands.map((x) => codes[x]),
      },
      [OptionFilter.Body]: {
        value: filterConfig[OptionFilter.Body].initialValue,
      },
      [OptionFilter.Category]: {
        value: filterConfig[OptionFilter.Category].initialValue,
      },
      [OptionFilter.Engine]: {
        value: filterConfig[OptionFilter.Engine].initialValue,
      },
      [RangeFilter.Price]: {
        value: filterConfig[RangeFilter.Price].initialValue,
      },
      [RangeFilter.RimSize]: {
        value: filterConfig[RangeFilter.RimSize].initialValue,
      },
      [RangeFilter.Range]: {
        value: filterConfig[RangeFilter.Range].initialValue,
      },
      [RangeFilter.Power]: {
        value: filterConfig[RangeFilter.Power].initialValue,
      },
      [RangeFilter.Capacity]: {
        value: filterConfig[RangeFilter.Capacity].initialValue,
      },
      [RangeFilter.FuelConsumption]: {
        value: filterConfig[RangeFilter.FuelConsumption].initialValue,
      },
      [ExoticFilter.Model]: {
        value: filterConfig[ExoticFilter.Model].initialValue,
      },
    };
  });

  const [carMotorcycleValue, setCarMotorcycleValue] = useState<string[]>([
    'car',
  ]);

  const onClose = useCallback(() => setShowModal(false), [setShowModal]);

  const handleLogin = () => {
    navigate(LoginRoute);
    pushEvent(EventName.TopMenu, {
      menu_type: 'text_menu',
      menu_item: 'login',
    });
  };

  const buildBrandsUrl = (selectedBrands?: string[]) => {
    if (!brands || !selectedBrands) {
      return;
    }

    return prune(
      brands.map((brand) =>
        selectedBrands.includes(brand.id) ? brand.code : undefined
      )
    ).join('/');
  };

  const saveBrandsInUrl = (selectedBrands: string[]) => {
    const newUrl = `/${buildBrandsUrl(selectedBrands)}`;

    if (!newUrl) {
      return;
    }

    navigate(newUrl, {
      replace: true,
    });
  };

  const changeValue = (
    value: string[] | [number, number],
    kind: OptionFilter | RangeFilter | ExoticFilter
  ) => {
    setState({
      ...state,
      [kind]: { value: value },
    });

    if (kind === 'brand') {
      saveBrandsInUrl(value as string[]);
    }
  };

  const reset = (kind: OptionFilter) => {
    setState({ ...state, [kind]: { value: [] } });

    if (kind === 'brand') {
      saveBrandsInUrl([]);
    }
  };

  const search = () => {
    const brandsUrl = buildBrandsUrl(state.brand.value);

    if (state.model) {
      searchWith(brandsUrl, {
        [OptionFilter.Brand]: state.brand.value,
        [ExoticFilter.Model]: state.model.value,
        [OptionFilter.Body]: state.body.value,
        [OptionFilter.Category]: state.category.value,
        [OptionFilter.Engine]: state.engine.value,
        [OptionFilter.Availability]: state.availability.value,
        [OptionFilter.Gearbox]: state.gearbox.value,
        [RangeFilter.Price]: state.price.value,
      });
    } else {
      searchWith(brandsUrl, {
        [OptionFilter.Brand]: state.brand.value,
        [OptionFilter.Body]: state.body.value,
        [OptionFilter.Category]: state.category.value,
        [OptionFilter.Engine]: state.engine.value,
        [OptionFilter.Availability]: state.availability.value,
        [OptionFilter.Gearbox]: state.gearbox.value,
        [RangeFilter.Price]: state.price.value,
      });
    }

    pushEvent(EventName.ButtonClick, { click_cta: 'show_offers' });
  };

  useEffect(() => {
    const newUrl = `/${buildBrandsUrl(state[OptionFilter.Brand]?.value)}`;

    if (pathname !== newUrl) {
      navigate(newUrl, {
        replace: true,
      });
    }

    dispatch(searchFiltersActions.clearFiltersValues());
  }, []);

  useEffect(() => {
    setIsLoading(true);
    if (productsPreference === 'new') {
      searchProducts({
        ...formattedSearchFilter(state),
        newProductOffersPagination: { page: 1, size: 1 },
      }).then((response) => {
        setTotal(response.total);
        setIsLoading(false);
      });
    } else {
      searchUsedProducts({
        ...formattedSearchFilter(state),
        usedProductOffersPagination: { page: 1, size: 1 },
      }).then((response) => {
        setTotal(response.total);
        setIsLoading(false);
      });
    }
  }, [state, productsPreference, userCustomerType]);

  const isMobile = useMediaQuery({
    maxWidth: below(widthBreakpoints.px768),
  });

  // useEffect(() => {
  //   dispatch(searchFiltersActions.clearFiltersValues());
  // }, []);

  const handleCarSelect = () => {
    const object = {
      ...state,
      [OptionFilter.Brand]: { value: [] },
      [ExoticFilter.Model]: { value: {} },
    };
    setState(object);
    setCarMotorcycleValue(['car']);
  };
  const brandsList = useSelector(brandsListSelector);

  const handleMotorcycleSelect = () => {
    const motorcycleId = brandsList?.find((item) => item.code === 'motorcycle')
      ?.id;
    if (motorcycleId) {
      const object = {
        ...state,
        brand: { value: [motorcycleId] },
      };
      setState(object);
      setCarMotorcycleValue(['motorcycle']);
    }
  };

  return (
    <>
      <PanelContent>
        <div>
          <Header>
            <StyledLogo variant="light" />
          </Header>
          <StyledAppHeader logoVariant="light" $tooltipShown={!isLoggedIn}>
            <HeaderChildrenWrapper>
              {isMobile && (
                <>
                  {isLoggedIn ? (
                    <Button
                      size="small"
                      kind="box"
                      variant="transparent"
                      onClick={() => setShowModal(true)}
                      style={{ fontSize: '0.75rem' }}
                    >
                      TWOJE <br /> BONUSY
                    </Button>
                  ) : (
                    <div style={{ position: 'relative' }}>
                      <LoginButton
                        kind="box"
                        size="small"
                        variant="transparent"
                        onClick={handleLogin}
                      >
                        ZALOGUJ
                      </LoginButton>
                    </div>
                  )}
                </>
              )}
            </HeaderChildrenWrapper>
          </StyledAppHeader>
          <Gradient />
        </div>

        <FilterContainer>
          <HomeWelcomeTitle />
          <AdditionalFiltersVehicleType>
            <StyledLabelRoboto style={{ marginTop: 0 }}>
              Rodzaj pojazdu
            </StyledLabelRoboto>
            <HorizontalToggleGroupContainer>
              <ToggleButtonGroup
                {...carsAndMotorcycleGroupProps}
                variant="secondary"
                value={carMotorcycleValue}
                onChange={(value) => {
                  if (value[0] === 'car') handleCarSelect();
                  else if (value[0] === 'motorcycle') handleMotorcycleSelect();
                }}
              />
            </HorizontalToggleGroupContainer>
          </AdditionalFiltersVehicleType>
          <HomePanelFiltersTabs
            state={state}
            changeValue={changeValue}
            reset={reset}
            onTabChange={onTabChange}
          />

          <Container>
            <AdditionalFilters>
              <StyledLabelRoboto style={{ marginTop: 0 }}>
                Rodzaj silnika
              </StyledLabelRoboto>
              <HorizontalToggleGroupContainer>
                <ToggleButtonGroupMany
                  {...engineToggleButtonGroupProps}
                  variant="secondary"
                  value={state.engine.value}
                  onChange={(value) => {
                    const newValue = value[0];
                    const currenetValues = state.engine.value;
                    const newValues = currenetValues.includes(newValue)
                      ? currenetValues.filter((v) => v !== newValue)
                      : [...currenetValues, newValue];
                    changeValue(newValues, OptionFilter.Engine);
                    pushEngineChoiceEvent(
                      engineToggleButtonGroupProps,
                      newValues
                    );
                  }}
                />
              </HorizontalToggleGroupContainer>
              <StyledLabelRoboto style={{ marginTop: 0 }}>
                Skrzynia biegów
              </StyledLabelRoboto>
              <HorizontalToggleGroupContainer>
                <ToggleButtonGroup
                  {...gearboxToggleButtonGroupProps}
                  variant="secondary"
                  value={state.gearbox.value}
                  onChange={(value) => {
                    changeValue(value, OptionFilter.Gearbox);
                    pushGearboxChoiceEvent(
                      gearboxToggleButtonGroupProps,
                      value
                    );
                  }}
                />
              </HorizontalToggleGroupContainer>
            </AdditionalFilters>
            <RangeFilterContainer>
              <StyledLabelRoboto style={{ marginTop: 0 }}>
                Miesięczna rata
              </StyledLabelRoboto>
              <HomePriceRangeFilter state={state} changeValue={changeValue} />
            </RangeFilterContainer>
            <ShowOffersButton
              variant="secondary"
              kind="skew"
              disabled={isLoading}
              icon={ArrowRight}
              onClick={search}
            >
              {!isLoading && !!total && (
                <>Pokaż {offerCountLabelFormatter(total)}</>
              )}
              {!isLoading && !total && <>ZNAJDŹ AUTO MARZEŃ</>}
              {isLoading && <>Wyszukiwanie ofert</>}
            </ShowOffersButton>
          </Container>
        </FilterContainer>
      </PanelContent>

      <ModalPortal
        open={showModal}
        onClose={onClose}
        background={rgba(colors.background, 0.8)}
        blur={10}
      >
        <CarPlatformBusinessBenefitsModal onClose={onClose} />
      </ModalPortal>
    </>
  );
});
