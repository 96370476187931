import React, { memo, useEffect, useMemo } from 'react';
import { OrderLayout } from 'src/app/product/components/OrderLayout';
import { navigate } from '@reach/router';
import { OrderRequest } from 'src/app/product/components/OrderRequest';
import { useDispatch, useSelector } from 'react-redux';
import {
  activeOfferSelector,
  activeProductSelector,
} from 'src/app/product/state/products/productsSelectors';
import {
  buildOrderThankYouRouteFromUrlParams,
  buildProductItemPageRouteFromUrlParams,
  SearchPageRoute,
} from 'src/app/routes';
import { snackbarActions } from 'src/state/notification/notificationActions';
import { useStorePageUrl } from 'src/common/hooks/useStorePageUrl';
import { currentPageUrlSelector } from 'src/state/pageContext/pageContextSelectors';
import { OrderPageProps } from 'src/app/product/types';

export const OrderPage = memo<OrderPageProps>((urlParams) => {
  useStorePageUrl();

  const dispatch = useDispatch();

  const activeProduct = useSelector(activeProductSelector);
  const activeOffer = useSelector(activeOfferSelector);
  const currentPageUrl = useSelector(currentPageUrlSelector);

  const backPath = useMemo(() => {
    const basePath = buildProductItemPageRouteFromUrlParams(urlParams);

    const params =
      urlParams.offerId != null
        ? new URLSearchParams({ offerId: urlParams.offerId })
        : undefined;

    return `${basePath}${params ? `?${params}` : ''}`;
  }, [urlParams]);

  const thankYouPagePath = useMemo(() => {
    return buildOrderThankYouRouteFromUrlParams(urlParams);
  }, [urlParams]);

  useEffect(() => {
    if (activeProduct == null) {
      navigate(SearchPageRoute, {
        replace: true,
      });
    } else if (activeOffer?.id !== urlParams.offerId) {
      navigate(backPath, {
        replace: true,
      }).then(() =>
        dispatch(
          snackbarActions.enqueueSnackbar({
            message:
              'W celu złożenia zapytania o ofertę należy wcześniej skonfigurować ją przy użyciu kalkulatora',
            options: { variant: 'warning' },
          })
        )
      );
    }
  }, [activeOffer, backPath, activeProduct]);

  return (
    <OrderLayout backPath={backPath}>
      <OrderRequest
        onSubmit={() =>
          navigate(thankYouPagePath, {
            state: { orderFormPageUrl: currentPageUrl },
          })
        }
      />
    </OrderLayout>
  );
});
