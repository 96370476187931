// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-app-index-tsx": () => import("./../../../src/pages/app/index.tsx" /* webpackChunkName: "component---src-pages-app-index-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app/[...].tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-browser-support-tsx": () => import("./../../../src/pages/browser-support.tsx" /* webpackChunkName: "component---src-pages-browser-support-tsx" */),
  "component---src-pages-car-platform-business-tsx": () => import("./../../../src/pages/car-platform-business.tsx" /* webpackChunkName: "component---src-pages-car-platform-business-tsx" */),
  "component---src-pages-change-company-tsx": () => import("./../../../src/pages/change-company.tsx" /* webpackChunkName: "component---src-pages-change-company-tsx" */),
  "component---src-pages-company-confirmation-tsx": () => import("./../../../src/pages/company-confirmation.tsx" /* webpackChunkName: "component---src-pages-company-confirmation-tsx" */),
  "component---src-pages-confirm-company-tsx": () => import("./../../../src/pages/confirm-company.tsx" /* webpackChunkName: "component---src-pages-confirm-company-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-contact-thank-you-tsx": () => import("./../../../src/pages/contact/thank-you.tsx" /* webpackChunkName: "component---src-pages-contact-thank-you-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-forgot-password-tsx": () => import("./../../../src/pages/forgot-password.tsx" /* webpackChunkName: "component---src-pages-forgot-password-tsx" */),
  "component---src-pages-fuel-special-offer-tsx": () => import("./../../../src/pages/fuel-special-offer.tsx" /* webpackChunkName: "component---src-pages-fuel-special-offer-tsx" */),
  "component---src-pages-how-it-works-tsx": () => import("./../../../src/pages/how-it-works.tsx" /* webpackChunkName: "component---src-pages-how-it-works-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-privacy-policy-and-cookies-tsx": () => import("./../../../src/pages/privacy-policy-and-cookies.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-and-cookies-tsx" */),
  "component---src-pages-register-step-2-success-tsx": () => import("./../../../src/pages/register-step2-success.tsx" /* webpackChunkName: "component---src-pages-register-step-2-success-tsx" */),
  "component---src-pages-register-step-2-tsx": () => import("./../../../src/pages/register-step2.tsx" /* webpackChunkName: "component---src-pages-register-step-2-tsx" */),
  "component---src-pages-register-success-tsx": () => import("./../../../src/pages/register-success.tsx" /* webpackChunkName: "component---src-pages-register-success-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-reset-password-tsx": () => import("./../../../src/pages/reset-password.tsx" /* webpackChunkName: "component---src-pages-reset-password-tsx" */),
  "component---src-pages-tos-tsx": () => import("./../../../src/pages/tos.tsx" /* webpackChunkName: "component---src-pages-tos-tsx" */),
  "component---src-pages-tsx": () => import("./../../../src/pages/[...].tsx" /* webpackChunkName: "component---src-pages-tsx" */)
}

