import React, { ReactNode } from 'react';
import {
  Availability,
  Body,
  CabinSpace,
  Drive,
  EngineType,
  GearboxType,
  PaintType,
  TrunkSpace,
  UpholsteryType,
} from 'src/app/common/models/product';
import { IconCode } from 'src/common/components/Icon';
import { FinancingType } from 'src/common/models/offer';

export const AvailabilityLabelMapper = (
  availability?: Availability | string
) => {
  switch (availability) {
    case Availability.Available:
      return 'Od ręki';
    case Availability.Unavailable:
      return 'Do produkcji';
    default:
      return '';
  }
};

export const brandIconMapper = (brand?: string) => {
  switch (brand) {
    case 'aprilia':
      return IconCode.ApriliaLogo;
    case 'BMW Motorrad':
      return IconCode.BMWMotorradLogo;
    case 'Ducati':
      return IconCode.DucatiLogo;
    case 'Honda':
      return IconCode.HondaMotorcycleLogo;
    case 'Harley-Davidson':
      return IconCode.HarleyDavidsonLogo;
    case 'Kawasaki':
      return IconCode.KawasakiLogo;
    case 'KTM':
      return IconCode.KTMLogo;
    case 'Piaggio':
      return IconCode.PiaggioLogo;
    case 'Suzuki':
      return IconCode.SuzukiLogo;
    case 'Vespa':
      return IconCode.VespaLogo;
    case 'Yamaha':
      return IconCode.YamahaLogo;
    case 'audi':
      return IconCode.AudiLogo;
    case 'bmw':
      return IconCode.BMWLogo;
    case 'alfaRomeo':
      return IconCode.AlfaRomeoLogo;
    case 'alpina':
      return IconCode.AlpinaLogo;
    case 'astonMartin':
      return IconCode.AstonMartinLogo;
    case 'mcLaren':
      return IconCode.McLarenLogo;
    case 'motorcycle':
      return IconCode.MotorcycleLogo;
    case 'rollsRoyce':
      return IconCode.RollsRoyceLogo;
    case 'tesla':
      return IconCode.TeslaLogo;
    case 'opel':
      return IconCode.OpelLogo;
    case 'cupra':
      return IconCode.CupraLogo;
    case 'jeep':
      return IconCode.JeepLogo;
    case 'mercedes':
      return IconCode.MercedesLogo;
    case 'mini':
      return IconCode.MiniLogo;
    case 'fiat':
      return IconCode.FiatLogo;
    case 'citroen':
      return IconCode.CitroenLogo;
    case 'ds':
      return IconCode.DSLogo;
    case 'dacia':
      return IconCode.DaciaLogo;
    case 'peugeot':
      return IconCode.PeugeotLogo;
    case 'renault':
      return IconCode.RenaultLogo;
    case 'ford':
      return IconCode.FordLogo;
    case 'hyundai':
      return IconCode.HyundaiLogo;
    case 'jaguar':
      return IconCode.JaguarLogo;
    case 'kia':
      return IconCode.KiaLogo;
    case 'landRover':
      return IconCode.LandRoverLogo;
    case 'mazda':
      return IconCode.MazdaLogo;
    // case 'ineos':
    //   return IconCode.GrenadierLogo;
    case 'mitsubishi':
      return IconCode.MitsubishiLogo;
    case 'voyah':
      return IconCode.VoyahLogo;
    case 'nissan':
      return IconCode.NissanLogo;
    case 'volvo':
      return IconCode.VolvoLogo;
    case 'toyota':
      return IconCode.ToyotaLogo;
    case 'lexus':
      return IconCode.LexusLogo;
    case 'porsche':
      return IconCode.PorscheLogo;
    case 'volkswagen':
      return IconCode.VolkswagenLogo;
    case 'honda':
      return IconCode.HondaLogo;
    case 'seat':
      return IconCode.SeatLogo;
    case 'skoda':
      return IconCode.SkodaLogo;
    default:
      return undefined;
  }
};

export const brandIconResizeRates: { [a: string]: number } = {
  [IconCode.AudiLogo]: 1.35,
  [IconCode.ApriliaLogo]: 1.35,
  [IconCode.BMWMotorradLogo]: 1.85,
  [IconCode.DucatiLogo]: 1.35,
  [IconCode.HondaMotorcycleLogo]: 1.35,
  [IconCode.HarleyDavidsonLogo]: 1.65,
  [IconCode.KawasakiLogo]: 1.35,
  [IconCode.KTMLogo]: 1.65,
  [IconCode.PiaggioLogo]: 1.35,
  [IconCode.SuzukiLogo]: 1.55,
  [IconCode.VespaLogo]: 1.65,
  [IconCode.YamahaLogo]: 1.35,
  [IconCode.MitsubishiLogo]: 1.15,
  [IconCode.GrenadierLogo]: 1.5,
  [IconCode.VoyahLogo]: 1.1,
  [IconCode.BMWLogo]: 1,
  [IconCode.AlfaRomeoLogo]: 1,
  [IconCode.AlpinaLogo]: 1,
  [IconCode.AstonMartinLogo]: 1.5,
  [IconCode.McLarenLogo]: 1.5,
  [IconCode.MotorcycleLogo]: 1.5,
  [IconCode.RollsRoyceLogo]: 1,
  [IconCode.TeslaLogo]: 1.25,
  [IconCode.CupraLogo]: 1,
  [IconCode.OpelLogo]: 1,
  [IconCode.JeepLogo]: 1.2,
  [IconCode.MercedesLogo]: 0.95,
  [IconCode.MiniLogo]: 1.4,
  [IconCode.FiatLogo]: 1,
  [IconCode.CitroenLogo]: 1.25,
  [IconCode.DSLogo]: 1.4,
  [IconCode.DaciaLogo]: 1.1,
  [IconCode.PeugeotLogo]: 1.1,
  [IconCode.RenaultLogo]: 1.1,
  [IconCode.FordLogo]: 1.4,
  [IconCode.HyundaiLogo]: 1.2,
  [IconCode.JaguarLogo]: 1.4,
  [IconCode.KiaLogo]: 1.2,
  [IconCode.LandRoverLogo]: 1.3,
  [IconCode.MazdaLogo]: 1,
  [IconCode.NissanLogo]: 1,
  [IconCode.VolvoLogo]: 0.92,
  [IconCode.ToyotaLogo]: 1.2,
  [IconCode.LexusLogo]: 1.45,
  [IconCode.PorscheLogo]: 1,
  [IconCode.VolkswagenLogo]: 0.95,
  [IconCode.HondaLogo]: 1.4,
  [IconCode.SeatLogo]: 1.3,
  [IconCode.SkodaLogo]: 1.2,
};

export const bodyIconMapper = (body?: Body | string) => {
  switch (body) {
    case Body.Hatchback:
      return IconCode.HatchbackBody;
    case Body.Sedan:
      return IconCode.SedanBody;
    case Body.Liftback:
      return IconCode.LiftbackBody;
    case Body.Combi:
      return IconCode.CombiBody;
    case Body.Suv:
      return IconCode.SuvBody;
    case Body.Van:
      return IconCode.VanBody;
    case Body.Cabrio:
      return IconCode.CabrioBody;
    case Body.Coupe:
      return IconCode.CoupeBody;
    case Body.Crossover:
      return IconCode.CrossoverBody;
    case Body.Motorcycle:
      return IconCode.MotorcycleBody;
    default:
      return undefined;
  }
};

export const bodyLabelMapper = (body?: Body | string) => {
  switch (body) {
    case Body.Hatchback:
      return 'Hatchback';
    case Body.Sedan:
      return 'Sedan';
    case Body.Liftback:
      return 'Liftback';
    case Body.Combi:
      return 'Kombi';
    case Body.Suv:
      return 'SUV';
    case Body.Van:
      return 'VAN';
    case Body.Cabrio:
      return 'Cabrio';
    case Body.Coupe:
      return 'Coupé';
    case Body.Crossover:
      return 'Crossover';
    case Body.Motorcycle:
      return 'Motocykl';
    default:
      return '';
  }
};

export const categoryIconMapper = (category?: string) => {
  switch (category) {
    case 'gadget':
      return IconCode.Gadget;
    case 'travel':
      return IconCode.Compass;
    case 'prestige':
      return IconCode.Diamond;
    case 'eko':
      return IconCode.EcoDriving;
    case 'single':
      return IconCode.Person;
    case 'performance':
      return IconCode.SportsCar;
    case 'gift':
      return IconCode.Gift;
    case 'offroad':
      return IconCode.Mountains;
    default:
      return undefined;
  }
};

export const engineTypeIconMapper = (engineType?: EngineType | string) => {
  switch (engineType) {
    case EngineType.Electric:
      return IconCode.ElectricEngine;
    default:
      return IconCode.Engine;
  }
};

export const engineTypeFuelIconMapper = (engineType?: EngineType | string) => {
  switch (engineType) {
    case EngineType.Diesel:
      return IconCode.DieselPump;
    case EngineType.Electric:
      return IconCode.Battery;
    default:
      return IconCode.PetrolPump;
  }
};

export const engineTypeLabelMapper = (engineType?: EngineType | string) => {
  switch (engineType) {
    case EngineType.Diesel:
      return 'Diesel';
    case EngineType.Electric:
      return 'Elektryczny';
    case EngineType.Hybrid:
      return 'Hybryda';
    case EngineType.Petrol:
      return 'Benzyna';
    default:
      return '';
  }
};

export const engineTypeUnitMapper = (engineType?: EngineType | string) => {
  switch (engineType) {
    case EngineType.Electric:
      return 'kWh';
    default:
      return 'cm3';
  }
};

export const engineTypeValueMapper = (
  engineType?: EngineType | string,
  capacity?: number,
  batteryCapacity?: number
) => {
  switch (engineType) {
    case EngineType.Electric:
      return batteryCapacity ?? 0;
    default:
      return capacity ?? 0;
  }
};

export const gearboxTypeLabelMapper = (gearboxType?: GearboxType | string) => {
  switch (gearboxType) {
    case GearboxType.AT:
      return 'Automatyczna';
    case GearboxType.MT:
      return 'Manualna';
    default:
      return '';
  }
};

export const driveIconMapper = (drive?: Drive | string) => {
  switch (drive) {
    case Drive.FWD:
      return IconCode.FrontWheelDrive;
    case Drive.RWD:
      return IconCode.RearDrive;
    default:
      return IconCode.FourWheelDrive;
  }
};

export const driveLabelMapper = (drive?: Drive | string) => {
  switch (drive) {
    case Drive.AWD:
      return 'Na cztery koła';
    case Drive.FWD:
      return 'Przedni';
    case Drive.RWD:
      return 'Tylny';
    default:
      return '';
  }
};

export const financingTypeLabelMapper = (
  financingType?: FinancingType | string
) => {
  switch (financingType) {
    case FinancingType.Rent:
      return 'Najem';
    case FinancingType.Leasing:
      return 'Leasing';
    case FinancingType.Loan:
      return 'Kredyt';
    default:
      return '';
  }
};

export const trunkSpaceLabelMapper = (trunkSpace?: TrunkSpace | ReactNode) => {
  switch (trunkSpace) {
    case TrunkSpace.S:
      return (
        <>
          1 duży <br /> 2 podręczne
        </>
      );
    case TrunkSpace.M:
      return (
        <>
          2 duży <br /> 4 podręczne
        </>
      );
    case TrunkSpace.L:
      return (
        <>
          3 duży <br /> 6 podręczne
        </>
      );
    case TrunkSpace.XL:
      return (
        <>
          4 duży <br /> 8 podręczne
        </>
      );
    default:
      return '';
  }
};

export const financingTypeMapper = (financingType?: FinancingType | string) => {
  switch (financingType) {
    case FinancingType.Rent:
      return 'Najem';
    case FinancingType.Leasing:
      return 'Leasing';
    case FinancingType.Loan:
      return 'Kredyt';
    default:
      return '';
  }
};

export const paintTypeLabelMapper = (paintType?: PaintType | string) => {
  switch (paintType) {
    case PaintType.Mat:
      return 'Matowy';
    case PaintType.Metallic:
      return 'Metalizowany';
    case PaintType.Uni:
      return 'Niemetalizowany';
    default:
      return '';
  }
};

export const upholsteryTypeLabelMapper = (
  upholsteryType?: UpholsteryType | string
) => {
  switch (upholsteryType) {
    case UpholsteryType.Leather:
      return 'Skóra';
    case UpholsteryType.Material:
      return 'Materiał';
    case UpholsteryType.MaterialLeather:
      return 'Materiał i skóra';
    case UpholsteryType.AlcantaraLeather:
      return 'Alcantara i skóra';
    case UpholsteryType.AlcantaraMaterial:
      return 'Alcantara i materiał';
    default:
      return '';
  }
};

export const cabinSpaceLabelMapper = (cabinSpace?: CabinSpace | string) => {
  switch (cabinSpace) {
    case CabinSpace.S:
      return 'S';
    case CabinSpace.M:
      return 'M';
    case CabinSpace.L:
      return 'L';
    case CabinSpace.XL:
      return 'XL';
    default:
      return '';
  }
};
