import React, { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { Seo } from 'src/public/components/Seo';
import { ScrollContainer } from 'src/common/components/ScrollContainer';
import { ContactSection } from 'src/public/components/car-platform-business/ContactSection';
import { Footer } from 'src/public/components/Footer';
import { ScrollContext } from 'src/common/contexts/ScrollContext';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import styled, { css } from 'styled-components';
import { media } from 'src/config/breakpoints';
import { ConstraintContainer } from 'src/common/components/ConstraintContainer';
import { GeneralBenefitsSection } from 'src/public/components/car-platform-business/GeneralBenefitsSection';
import { useStorePageUrl } from 'src/common/hooks/useStorePageUrl';
import { FormSection } from 'src/public/components/business-partner-registration/FormSection';
import { HeroSection } from 'src/public/components/business-partner-registration/HeroSection';
import { businessPartnerActions } from 'src/state/businessPartner/businessPartnerActions';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from '@reach/router';
import { useRequestEffect } from 'src/lib/useRequestEffect';
import { navigate } from 'gatsby';
import { businessPartnerSelector } from 'src/state/businessPartner/businessPartnerSelectors';
import { getResponsive } from 'src/common/models/image';
import ArrowsDown from 'src/assets/images/doubled-chevron-down.svg';
import { Button } from 'src/common/components/Button';
import { removeAllSlashes } from 'src/lib/stringTransformations';
import { isAuthenticatedSelector } from 'src/state/auth/authSelectors';
import { isPlatformBrowser } from 'src/lib/isPlatformBrowser';
import { AppRoute } from 'src/app/routes';

const FullPageLayout = styled.div`
  width: 100%;
  height: 100%;
`;

const HeroSectionContainer = styled(ConstraintContainer)`
  overflow: hidden;
  position: relative;

  ${media.w.min.px768(css`
    padding: 0 5rem;
  `)};

  &:after {
    content: '';
    position: absolute;
    z-index: -2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(
      58.8% 58.8% at 30.82% 74.91%,
      #000000 67.29%,
      #2a2b32 100%
    );
    opacity: 0.5;
    transform: rotate(-180deg);
  }
`;

const ScrollButton = styled(Button)`
  ${media.w.max.px992(css`
    display: none;
  `)};

  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  display: block;

  & > .btn__content {
    border: none;
    border-radius: 50%;
    height: 2.5625rem;
    width: 2.5625rem;
    justify-content: center;
  }
`;

export const BusinessPartnerPage = () => {
  useStorePageUrl();

  const isLoggedIn = useSelector(isAuthenticatedSelector);

  if (isPlatformBrowser() && isLoggedIn) {
    navigate(AppRoute);
    return null;
  }

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const registrationPath = useMemo(() => {
    return removeAllSlashes(pathname).toLowerCase();
  }, [pathname]);

  useEffect(() => {
    dispatch(businessPartnerActions.request(registrationPath));
  }, []);

  useRequestEffect(businessPartnerActions.request, {
    onError: () => navigate('/404'),
  });

  const businessPartnerData = useSelector(businessPartnerSelector);
  const logoUrl = useMemo(() => {
    return getResponsive('original', businessPartnerData?.logo) ?? '';
  }, [businessPartnerData]);

  const formSectionRef = useRef<HTMLDivElement>(null);
  const [scrollRef, setScrollRef] = useState<OverlayScrollbarsComponent | null>(
    null
  );

  const scrollToSection = (ref: RefObject<HTMLDivElement>) => {
    ref?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    const elements = scrollRef?.osInstance()?.getElements();

    if (elements) {
      elements.viewport.tabIndex = -1;
      elements.viewport.focus();
    }
  }, [scrollRef]);

  return (
    <ScrollContext.Provider value={scrollRef}>
      <FullPageLayout>
        <Seo title="Business Partner" />

        <ScrollContainer ref={setScrollRef} style={{ height: '100%' }}>
          <main>
            <HeroSectionContainer>
              <HeroSection
                logo={logoUrl}
                onPrimaryButtonClick={() => scrollToSection(formSectionRef)}
              />
              <ScrollButton
                kind="box"
                variant="gray"
                icon={ArrowsDown}
                onClick={() => scrollToSection(formSectionRef)}
              />
            </HeroSectionContainer>
            <div ref={formSectionRef}>
              <FormSection path={registrationPath} />
            </div>
            <GeneralBenefitsSection />
            <ContactSection />
            <Footer />
          </main>
        </ScrollContainer>
      </FullPageLayout>
    </ScrollContext.Provider>
  );
};
