import React, { FC, useState } from 'react';
import { Product } from 'src/app/common/models/product';
import { ProductPageSummaryOfferVariants } from 'src/app/product/components/ProductPageSummary/ProductPageSummaryOfferVariants';
import { FinancingType, Offer, PackageType } from 'src/common/models/offer';
import { ProductSlider } from 'src/app/product/components/ProductSlider';
import { localFormatNumber } from 'src/lib/localFormatNumber';
import { ProductPageSummaryOfferOptions } from 'src/app/product/components/ProductPageSummary/ProductPageSummaryOfferOptions';
import MediaQuery from 'react-responsive';
import { media, widthBreakpoints } from 'src/config/breakpoints';
import { ProductPrice } from 'src/app/product/components/ProductPrice';
import { ProductPageSummaryListPrice } from 'src/app/product/components/ProductPageSummary/ProductPageSummaryListPrice';
import styled, { css } from 'styled-components';
import { B2BB2CSwitch } from 'src/common/components/B2BB2CSwitch';
import { transparentize } from 'polished';
import { colors } from 'src/config/colors';
import { useInternalCalculatorConfigurations } from 'src/app/product/hooks/useInternalCalculatorConfigurations';
import {
  financingTypeMapper,
  packageTypeMapper,
} from 'src/app/product/services/offerMappers';
import { Text } from 'src/common/components/typography/Text';
import { EventName, pushEvent } from 'src/common/services/googleTagManager';
import { useSelector } from 'react-redux';
import { financingTypesListSelector } from 'src/app/product/state/products/productsSelectors';

const Configuration = styled.div`
  margin-bottom: 1.25rem;

  ${media.w.min.px1200(css`
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  `)}
`;

const StyledB2BB2CSwitch = styled(B2BB2CSwitch)`
  width: 100%;
  background: ${transparentize(0.9, colors.white)};
  border-color: transparent;
`;

const calculatePercent = (percent?: number, num?: number) => {
  if (percent && num) {
    const percentValue: number = (percent / 100) * num;
    return Math.round(percentValue / 100) * 100;
  }
  return 0;
};

export interface InternalCalculatorProps {
  activeOffer: Offer;
  onFinancingTypeChange: (financingType: string[]) => void;
  product: Product;
}

export const InternalCalculator: FC<InternalCalculatorProps> = ({
  activeOffer,
  onFinancingTypeChange,
  product,
}) => {
  const [isTiresClicked, setIsTiresClicked] = useState(false);
  const financingTypesList = useSelector(financingTypesListSelector);
  const {
    packageTypesList,
    setPackageType,
    initialPaymentsList,
    setInitialPayment,
    contractPeriodsList,
    setContractPeriod,
    annualMileageList,
    setAnnualMileage,
    buyoutPriceList,
    setBuyoutPrice,
  } = useInternalCalculatorConfigurations(product);

  if (activeOffer == null) {
    return <Text>Brak ofert</Text>;
  }

  const handlePackageTypeButtonChange = (a: string[]) => {
    if (a.length > 0 && activeOffer.packageType !== a[0]) {
      setPackageType(a[0] as PackageType);

      const type = packageTypesList.find((type) => type === a[0]);

      type &&
        pushEvent(EventName.ButtonClick, {
          click_category: 'offer_details_subscription',
          click_cta: packageTypeMapper[type].toLowerCase(),
        });
    }
  };

  return (
    <>
      <Configuration>
        <StyledB2BB2CSwitch value={activeOffer.customerType} />

        {financingTypesList.length > 0 && (
          <ProductPageSummaryOfferVariants
            items={financingTypesList.map((id) => ({
              id,
              label: financingTypeMapper[id],
            }))}
            name="Forma finansowania"
            onChange={(a) => onFinancingTypeChange(a)}
            value={activeOffer.financingType}
          />
        )}

        {activeOffer.financingType === FinancingType.Rent &&
          product.type !== 'NEW_LUXURY_CAR' &&
          activeOffer.packageType && (
            <ProductPageSummaryOfferVariants
              items={packageTypesList.map((id) => ({
                id,
                label: packageTypeMapper[id],
              }))}
              name="Pakiet abonamentu"
              onChange={(a) => handlePackageTypeButtonChange(a)}
              value={activeOffer.packageType}
            />
          )}

        {initialPaymentsList?.length > 0 && (
          <ProductSlider
            min={0}
            label="Wkład własny"
            step={1}
            suffix="zł"
            content={localFormatNumber(
              calculatePercent(
                activeOffer.initialPayment,
                activeOffer.fullPrice
              )
            )}
            value={initialPaymentsList.findIndex(
              (payment) => payment === activeOffer!.initialPayment
            )}
            max={initialPaymentsList.length - 1}
            onChange={(_, v) =>
              typeof v === 'number' &&
              activeOffer.initialPayment !== initialPaymentsList[v] &&
              setInitialPayment(initialPaymentsList[v])
            }
            disabled={initialPaymentsList.length === 1}
          />
        )}

        {contractPeriodsList.length > 0 && (
          <ProductSlider
            min={0}
            label="Liczba rat"
            step={1}
            suffix="mc"
            content={activeOffer.contractPeriod}
            value={contractPeriodsList.findIndex(
              (payment) => payment === activeOffer.contractPeriod
            )}
            max={contractPeriodsList.length - 1}
            onChange={(_, v) => {
              if (
                typeof v === 'number' &&
                activeOffer.contractPeriod !== contractPeriodsList[v]
              ) {
                setContractPeriod(contractPeriodsList[v]);
                pushEvent(EventName.ButtonClick, {
                  click_category: 'offer_details_installment_no',
                  click_cta: contractPeriodsList[v].toString(),
                });
              }
            }}
            disabled={contractPeriodsList.length === 1}
          />
        )}

        {buyoutPriceList.length > 0 && (
          <ProductSlider
            min={0}
            label="Kwota wykupu"
            step={1}
            suffix="zł"
            content={localFormatNumber(
              calculatePercent(activeOffer.buyoutPrice, activeOffer.fullPrice)
            )}
            value={buyoutPriceList.findIndex(
              (payment) => payment === activeOffer.buyoutPrice
            )}
            max={buyoutPriceList.length - 1}
            onChange={(_, v) =>
              typeof v === 'number' &&
              activeOffer.buyoutPrice !== buyoutPriceList[v] &&
              setBuyoutPrice(buyoutPriceList[v])
            }
            disabled={buyoutPriceList.length === 1}
          />
        )}

        {annualMileageList.length > 0 && (
          <ProductSlider
            min={0}
            label="Planowany przebieg roczny"
            step={1}
            suffix="km"
            content={localFormatNumber(activeOffer.annualMileage!)}
            value={annualMileageList.findIndex(
              (payment) => payment === activeOffer.annualMileage
            )}
            max={annualMileageList.length - 1}
            onChange={(_, v) => {
              if (
                typeof v === 'number' &&
                activeOffer.annualMileage !== annualMileageList[v]
              ) {
                setAnnualMileage(annualMileageList[v]);
                pushEvent(EventName.ButtonClick, {
                  click_category: 'offer_details_mileage',
                  click_cta: annualMileageList[v].toString(),
                });
              }
            }}
            disabled={annualMileageList.length === 1}
          />
        )}

        <ProductPageSummaryOfferOptions
          financingType={activeOffer.financingType}
          packageType={activeOffer.packageType}
          productType={product.type}
          isTiresClicked={isTiresClicked}
          onTiresClick={() => setIsTiresClicked(!isTiresClicked)}
        />
      </Configuration>

      <MediaQuery minWidth={widthBreakpoints.px1200}>
        <>
          <ProductPrice
            offer={activeOffer}
            discountBadge
            isTiresClicked={isTiresClicked}
          />

          {product?.listPrice && (
            <ProductPageSummaryListPrice price={product.listPrice} />
          )}
        </>
      </MediaQuery>
    </>
  );
};
