import React, { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  searchProductsResultsSelector,
  searchProductsResultsTotalSelector,
} from 'src/app/search/state/searchProducts/searchProductsSelectors';
import styled, { css } from 'styled-components';
import { media } from 'src/config/breakpoints';
import { productOfferTileMaxWidth } from 'src/app/product/components/ProductOfferTile';
import {
  calculatePagesCount,
  Pagination,
} from 'src/app/search/components/Pagination';
import { NewProductOfferTile } from 'src/app/search/components/NewProductOfferTile';
import { NewProductOfferButton } from 'src/app/product/components/ProductOfferButton';
import { useNewProductOffersPaginationFilterControl } from 'src/app/search/hooks/useFilterControl';
import { useResponsivePageSize } from 'src/app/search/hooks/useResponsivePageSize';
import { buildProductItemPageRoute } from 'src/app/routes';
import {
  LoadingIndicatorLimiter,
  ProductsLoadingIndicator,
} from 'src/app/search/components/LoadingIndicator';
import { ConstraintContainer } from 'src/common/components/ConstraintContainer';
import { productsPreferenceSelector } from 'src/state/settings/settingsSelectors';
import { DreamProductTile } from 'src/app/search/components/DreamProductTile';
import { Link } from 'gatsby';
import { useRequest } from 'src/lib/useRequest';
import { findProductsRequestActions } from 'src/app/search/state/searchProducts/searchProductsActions';
import { isInitialRequest } from 'src/state/requestStatus/requestStatus';
import { EventName, pushEvent } from 'src/common/services/googleTagManager';
import { CustomerType } from 'src/common/models/customerType';
import { NewProductOfferButtonChanged } from 'src/app/product/components/ProductOfferButtonChanged';

const ProductOffersGrid = styled.div`
  position: relative;
  display: grid;
  min-height: 19.125rem;
  margin-bottom: 2rem;
  justify-content: center;
  grid-row-gap: 2rem;

  ${media.w.min.px768(css`
    grid-template-columns: repeat(
      2,
      minmax(21rem, ${productOfferTileMaxWidth})
    );
    grid-column-gap: 8%;
  `)}

  ${media.w.min.px1200(css`
    grid-row-gap: 3rem;
    grid-template-columns: repeat(
      3,
      minmax(21rem, ${productOfferTileMaxWidth})
    );
  `)}
`;

export const NewProductOffers: FC<{ onDreamCarClick: () => void }> = ({
  onDreamCarClick,
}) => {
  const {
    state: {
      value: { page, size: pageSize },
    },
    setValue,
  } = useNewProductOffersPaginationFilterControl();
  const productsPreference = useSelector(productsPreferenceSelector);
  const newPageSize = useResponsivePageSize(
    6,
    productsPreference === 'new' ? 9 : 6
  );

  useEffect(() => {
    setTimeout(() => {
      if (newPageSize != pageSize) {
        setValue({ page, size: newPageSize });
      }
    }, 100);
  }, [newPageSize]);

  const products = useSelector(searchProductsResultsSelector);
  const total = useSelector(searchProductsResultsTotalSelector);
  const pagesCount = calculatePagesCount(total, pageSize);
  const [pageChanged, setPageChanged] = useState(false);

  const { isLoading, status } = useRequest(findProductsRequestActions.request);
  const [showDreamCar, setShowDreamCar] = useState(
    page === pagesCount && !isLoading && !isInitialRequest(status)
  );
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setShowDreamCar((current) => {
      if (current) {
        if (page !== pagesCount && !isLoading) {
          // User changed from last page to other and it has finished loading
          return false;
        }
      } else {
        if (page === pagesCount && !isLoading) {
          // User changed from other page to last and it has finished loading
          return true;
        }
      }

      return current;
    });
  }, [page, pagesCount, isLoading]);

  useEffect(() => {
    if (pageChanged) {
      containerRef?.current?.scrollIntoView();
    }
  }, [isLoading]);

  const handlePageChange = (newPage: number) => {
    setValue({ page: newPage, size: pageSize });
    setPageChanged(true);
  };

  return (
    <LoadingIndicatorLimiter>
      <ConstraintContainer>
        <ProductOffersGrid ref={containerRef}>
          <ProductsLoadingIndicator />
          {products.map((product) => (
            <NewProductOfferButtonChanged
              key={product.id}
              as={Link}
              tabIndex={0}
              to={buildProductItemPageRoute({
                annualMileage: product.offer.annualMileage,
                brandCode: product.brand?.code,
                buyoutPrice: product.offer.buyoutPrice,
                contractPeriod: product.offer.contractPeriod,
                customerType: product.offer.customerType,
                financingType: product.offer.financingType,
                initialPayment: product.offer.initialPayment,
                modelLabel: product.model?.label,
                packageType: product.offer.packageType,
                productId: product.id,
                version: product.version,
              })}
              onClick={() =>
                pushEvent(EventName.SelectItem, {
                  item_list_name: 'offer_select',
                  items: [
                    {
                      item_id: product.id,
                      item_name: product.label.toLowerCase(),
                      item_brand: product.label.toLowerCase().split(' ')[0],
                      item_variant:
                        product.offer.customerType === CustomerType.B2B
                          ? 'price_netto'
                          : 'price_brutto',
                      price: product.offer.price.toString(),
                    },
                  ],
                })
              }
            >
              <NewProductOfferTile product={product} />
            </NewProductOfferButtonChanged>
          ))}
          {showDreamCar && (
            <NewProductOfferButton tabIndex={0} onClick={onDreamCarClick}>
              <DreamProductTile />
            </NewProductOfferButton>
          )}
        </ProductOffersGrid>

        {products.length > 0 && (
          <Pagination
            page={page}
            pageSize={pageSize}
            total={total}
            onChange={handlePageChange}
          />
        )}
      </ConstraintContainer>
    </LoadingIndicatorLimiter>
  );
};
