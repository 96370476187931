import { RouteComponentProps, useNavigate } from '@reach/router';
import Observer from '@researchgate/react-intersection-observer';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React, {
  createRef,
  FC,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import styled, { css } from 'styled-components';
import { ProductPageMobileBar } from 'src/app/product/components/ProductPageSummary/ProductPageMobileBar';
import {
  ProductPageSectionGallery,
  ProductPageSectionGalleryRef,
} from 'src/app/product/components/ProductPageSectionGallery';
import { ProductPageSectionParameters } from 'src/app/product/components/ProductPageSectionParameters';
import { ProductPageSectionTop } from 'src/app/product/components/ProductPageSectionTop';
import { ProductPageSummary } from 'src/app/product/components/ProductPageSummary';
import { ProductPageSection } from 'src/app/product/models/productPageSection';
import { IconCode } from 'src/common/components/Icon';
import { ScrollContainer } from 'src/common/components/ScrollContainer';
import { below, media, widthBreakpoints } from 'src/config/breakpoints';
import { useSelector } from 'react-redux';
import { activeOfferSelector } from 'src/app/product/state/products/productsSelectors';
import { ProductPageTests } from 'src/app/product/components/ProductPageTests';
import { ProductDetailsHeader } from 'src/app/product/components/ProductDetailsHeader';
import { ConstraintContainer } from 'src/common/components/ConstraintContainer';
import { ProductPageBenefits } from 'src/app/product/components/ProductPageBenefits';
import { ProductPageSectionEquipment } from 'src/app/product/components/ProductPageSectionEquipment';
import { GetNewCarInstructionsStepSection } from 'src/app/common/components/GetNewCarInstructionsStepSection';
import { ProductPageNavigationDesktop } from 'src/app/product/components/ProductPageNavigation/ProductPageNavigationDesktop';
import { colors } from 'src/config/colors';
import { buildOrderItemPageRoute, SearchPageRoute } from 'src/app/routes';
import { ProductPageNavigationMobile } from 'src/app/product/components/ProductPageNavigation/ProductPageNavigationMobile';
import { useKeyPress } from 'src/app/common/hooks/useKeyPress';
import { CODE_ESCAPE, KEY_ESCAPE } from 'keycode-js';
import { stringify } from 'qs';
import { currentSearchParamsVersion } from 'src/app/search/searchParamsVersion';
import { Button } from 'src/common/components/Button';
import ArrowRight from 'src/assets/icons/ArrowRight';
import { isUsedProduct, Product } from 'src/app/common/models/product';
import { ProductPageSectionUsedProductParameters } from 'src/app/product/components/ProductPageSectionUsedProductParameters';
import { useStorePageUrl } from 'src/common/hooks/useStorePageUrl';
import { Link } from 'gatsby';

const StyledScrollContainer = styled(ScrollContainer)`
  margin-top: 4rem;
  width: 100%;
  height: calc(100% - 4rem);
`;

const MainContent = styled.main`
  position: relative;
  color: ${colors.lightGray};
`;

const AbsoluteContainerSpace = styled(ConstraintContainer)`
  display: flex;
  justify-content: space-between;
  padding-right: 0;
`;

const AbsolutePath = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 50;
  width: 0;
`;

const Navigation = styled.div`
  position: sticky;
  top: 15rem;
  padding-bottom: 15rem;

  ${media.w.max.px1200(css`
    bottom: 4rem;
    order: 2;
    position: sticky;
    z-index: 100;
  `)}
`;

const Section = styled.div`
  position: relative;
`;

const StyledGetNewCarInstructionsStepSection = styled(
  GetNewCarInstructionsStepSection
)`
  ${media.w.min.px1200(css`
    margin-top: 6rem;
    padding: 5rem 0 4.375rem;
  `)}
`;

const intersectionThreshold = [0.25, 0.5, 0.75, 1];

const hasEquipment = (product: Product) =>
  (product.equipment ?? []).length > 0 ||
  (product.fullEquipment ?? []).length > 0;

export interface ProductPageProps extends RouteComponentProps {
  product: Product;
}

export const ProductPage: FC<ProductPageProps> = ({ product }) => {
  useStorePageUrl();

  const sections = useRef<{ [key: string]: ProductPageSection }>({
    start: {
      icon: IconCode.Home,
      key: 'start',
      menuTitle: 'start',
      menuTitleSm: 'start',
      ref: createRef(),
      subtitle: '',
      title: '',
      variant: 'light',
    },
    benefits: {
      icon: IconCode.ProductBenefits,
      key: 'benefits',
      menuTitle: 'zalety',
      menuTitleSm: 'zalety',
      ref: createRef(),
      subtitle: 'Dlaczego polubisz to auto?',
      title: 'zalety',
      variant: 'dark',
    },
    equipment: {
      icon: IconCode.ProductEquipment,
      key: 'equipment',
      menuTitle: 'wyposażenie',
      menuTitleSm: 'wyposażenie',
      ref: createRef(),
      subtitle: 'Zobacz, czy auto ma to czego szukasz',
      title: 'wyposażenie',
      variant: 'dark',
    },
    gallery: {
      icon: IconCode.ProductGallery,
      key: 'gallery',
      menuTitle: 'zobacz z bliska',
      menuTitleSm: 'galeria',
      ref: createRef(),
      subtitle: 'Poznaj detale, które pokochasz',
      title: 'zobacz z bliska',
      variant: 'light',
    },
    parameters: {
      icon: IconCode.ProductParameters,
      key: 'parameters',
      menuTitle: 'parametry',
      menuTitleSm: 'parametry',
      ref: createRef(),
      subtitle: 'Odpowiedz na własne potrzeby',
      title: 'parametry',
      variant: 'dark',
    },
    ourTests: {
      icon: IconCode.ProductAdvice,
      key: 'tests',
      menuTitle: 'nasze testy',
      menuTitleSm: 'testy',
      ref: createRef(),
      subtitle: 'Sprawdż, co mówi nasz genius',
      title: 'nasze testy',
      variant: 'dark',
    },
  });

  const navigate = useNavigate();

  const isDesktop = useMediaQuery({
    minWidth: widthBreakpoints.px1200,
  });

  const activeOffer = useSelector(activeOfferSelector);

  const scrollRef = useRef<OverlayScrollbarsComponent>(null);
  const summarySectionRef = useRef<HTMLDivElement>(null);
  const [activeNavigationItem, setActiveNavigationItem] = useState<string>();
  const [isPageTop, setIsPageTop] = useState(true);
  const [isSummaryVisible, setIsSummaryVisible] = useState(false);

  const sortedSections = [
    sections.current.start.key,
    sections.current.benefits.key,
    sections.current.ourTests.key,
    sections.current.parameters.key,
    sections.current.gallery.key,
    sections.current.equipment.key,
    'steps',
  ];

  const intersectionValues = useRef<{ [key: string]: number }>({});
  const handleIntersection = (
    entry: IntersectionObserverEntry,
    key: string
  ) => {
    intersectionValues.current[key] = entry.intersectionRatio;

    const current = sortedSections.find(
      (a) => intersectionValues.current[a] >= 0.5
    );

    if (current !== activeNavigationItem) {
      setActiveNavigationItem(current);
    }
  };

  const handleScroll = () =>
    setIsPageTop(scrollRef.current?.osInstance()?.scroll().position.y === 0);

  const handleSummaryVisibilityChange = useCallback(
    (event: IntersectionObserverEntry) => {
      setIsSummaryVisible(event.intersectionRatio >= 0.5);

      if (!isDesktop) {
        handleIntersection(event, 'summary');
      }
    },
    [activeNavigationItem, isDesktop]
  );

  const scrollToSection = (sectionRef: RefObject<HTMLDivElement>) =>
    sectionRef.current &&
    scrollRef.current?.osInstance()?.scroll(
      {
        el: sectionRef.current,
      },
      500
    );

  const handleSummaryButtonClick = useCallback(
    () =>
      isSummaryVisible && activeOffer
        ? navigate(
            buildOrderItemPageRoute({
              annualMileage: activeOffer.annualMileage,
              brandCode: product.brand?.code,
              buyoutPrice: activeOffer.buyoutPrice,
              contractPeriod: activeOffer.contractPeriod,
              customerType: activeOffer.customerType,
              financingType: activeOffer.financingType,
              initialPayment: activeOffer.initialPayment,
              modelLabel: product.model?.label,
              offerId: activeOffer.id,
              packageType: activeOffer.packageType,
              productId: product.id,
              version: product.version,
            })
          )
        : scrollToSection(summarySectionRef),
    [isSummaryVisible, activeOffer?.id]
  );

  const navigationItems = useMemo(() => {
    const navigationItems = [sections.current.start];

    if (product.benefits?.length > 0) {
      navigationItems.splice(1, 0, sections.current.benefits);
    }

    if (product.ourTests.length > 0) {
      navigationItems.push(sections.current.ourTests);
    }

    navigationItems.push(sections.current.parameters);

    if (product.insidePhotos.length || product.outsidePhotos.length) {
      navigationItems.push(sections.current.gallery);
    }

    if (hasEquipment(product)) {
      navigationItems.push(sections.current.equipment);
    }

    return navigationItems;
  }, [product]);

  const [escapeNavigateDisabled, setEscapeNavigateDisabled] = useState(false);
  useKeyPress(KEY_ESCAPE, CODE_ESCAPE, () => {
    if (!escapeNavigateDisabled) {
      navigate(
        `${SearchPageRoute}?${stringify({
          v: currentSearchParamsVersion,
        })}`
      );
    }
  });

  const productPageSectionGalleryRef = useRef<ProductPageSectionGalleryRef | null>(
    null
  );

  useEffect(() => {
    const elements = scrollRef.current?.osInstance()?.getElements();

    if (elements) {
      elements.viewport.tabIndex = -1;
      elements.viewport.focus();
    }
  }, []);

  return (
    <>
      <ProductDetailsHeader product={product} showTitle={!isPageTop} />
      <StyledScrollContainer
        options={{
          callbacks: {
            onScroll: handleScroll,
          },
        }}
        ref={scrollRef}
      >
        <MainContent>
          <MediaQuery minWidth={widthBreakpoints.px1200}>
            <AbsoluteContainerSpace>
              <div>
                <AbsolutePath>
                  <Navigation>
                    <ProductPageNavigationDesktop
                      active={activeNavigationItem}
                      items={navigationItems}
                      onClick={scrollToSection}
                      slim={!isPageTop}
                    />
                  </Navigation>
                </AbsolutePath>
              </div>

              <div>
                <AbsolutePath>
                  <ProductPageSummary isPageTop={isPageTop} product={product} />
                </AbsolutePath>
              </div>
            </AbsoluteContainerSpace>
          </MediaQuery>

          <Observer
            onChange={(entry) =>
              handleIntersection(entry, sections.current.start.key)
            }
            threshold={intersectionThreshold}
          >
            <Section ref={sections.current.start.ref}>
              <ProductPageSectionTop
                isPageTop={isPageTop}
                product={product}
                onImageClick={() =>
                  productPageSectionGalleryRef.current?.openGalleryModal()
                }
              />
            </Section>
          </Observer>

          {isUsedProduct(product) && (
            <Section>
              <ProductPageSectionUsedProductParameters product={product} />
            </Section>
          )}

          {product.benefits?.length > 0 && (
            <Observer
              onChange={(entry) =>
                handleIntersection(entry, sections.current.benefits.key)
              }
              threshold={intersectionThreshold}
            >
              <Section ref={sections.current.benefits.ref}>
                <ProductPageBenefits
                  benefits={product.benefits}
                  section={sections.current.benefits}
                />
              </Section>
            </Observer>
          )}

          {product.ourTests.length > 0 && (
            <Observer
              onChange={(entry) =>
                handleIntersection(entry, sections.current.ourTests.key)
              }
              threshold={intersectionThreshold}
            >
              <Section ref={sections.current.ourTests.ref}>
                <ProductPageTests
                  product={product}
                  section={sections.current.ourTests}
                  onModalToggle={setEscapeNavigateDisabled}
                />
              </Section>
            </Observer>
          )}

          <Observer
            onChange={(entry) =>
              handleIntersection(entry, sections.current.parameters.key)
            }
            threshold={intersectionThreshold}
          >
            <Section ref={sections.current.parameters.ref}>
              <ProductPageSectionParameters
                product={product}
                section={sections.current.parameters}
              />
            </Section>
          </Observer>

          {(product.insidePhotos?.length > 0 ||
            product.outsidePhotos?.length > 0) && (
            <Observer
              onChange={(entry) =>
                handleIntersection(entry, sections.current.gallery.key)
              }
              threshold={intersectionThreshold}
            >
              <Section ref={sections.current.gallery.ref}>
                <ProductPageSectionGallery
                  ref={productPageSectionGalleryRef}
                  product={product}
                  section={sections.current.gallery}
                  onModalToggle={setEscapeNavigateDisabled}
                />
              </Section>
            </Observer>
          )}

          {hasEquipment(product) && (
            <Observer
              onChange={(entry) =>
                handleIntersection(entry, sections.current.equipment.key)
              }
              threshold={intersectionThreshold}
            >
              <Section ref={sections.current.equipment.ref}>
                <ProductPageSectionEquipment
                  product={product}
                  section={sections.current.equipment}
                  onModalToggle={setEscapeNavigateDisabled}
                />
              </Section>
            </Observer>
          )}
          <MediaQuery maxWidth={below(widthBreakpoints.px1200)}>
            <ProductPageNavigationMobile
              active={activeNavigationItem}
              items={navigationItems}
              onClick={scrollToSection}
            />
          </MediaQuery>
        </MainContent>

        <Observer
          onChange={(entry) => handleIntersection(entry, 'steps')}
          threshold={intersectionThreshold}
        >
          <StyledGetNewCarInstructionsStepSection
            cta={
              <Button
                as={Link}
                to={SearchPageRoute}
                icon={ArrowRight}
                kind="skew"
              >
                Szukaj
              </Button>
            }
          />
        </Observer>

        <MediaQuery maxWidth={below(widthBreakpoints.px1200)}>
          <Observer
            onChange={handleSummaryVisibilityChange}
            threshold={intersectionThreshold}
          >
            <Section ref={summarySectionRef}>
              <ProductPageSummary isPageTop={isPageTop} product={product} />
            </Section>
          </Observer>

          <ProductPageMobileBar
            isSummaryVisible={isSummaryVisible}
            onButtonClick={handleSummaryButtonClick}
          />
        </MediaQuery>
      </StyledScrollContainer>
    </>
  );
};
