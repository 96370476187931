import { RouteComponentProps } from '@reach/router';
import React, { FC, memo, useEffect, useState } from 'react';
import { HomeAdvert } from 'src/app/home/components/HomeAdvert';
import { HomeMobileEntrance } from 'src/app/home/components/HomeMobileEntrance';
import { HomePanel } from 'src/app/home/components/HomePanel';
import { injectHomeAdvertState } from 'src/app/home/state';
import { media } from 'src/config/breakpoints';
import { triStateMapper } from '@summer/react-kit/functions';
import styled, { css } from 'styled-components';
import { HomeDealBanner } from 'src/app/home/components/HomeDealBanner';
import { useIsHomePage } from 'src/app/common/hooks/useIsHomePage';
import { isNil } from 'ramda';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;

  ${media.w.max.px768(css`
    &.enter {
      transform: translateY(0);
      transition: transform 600ms ease-in-out;
    }

    &.exit {
      transform: translateY(-100%);
      transition: transform 600ms ease-in-out;
    }
  `)};
`;

injectHomeAdvertState();
import('src/app/search/SearchPage');

export const BRAND_AUTOCOMPELTE_LOCAL_STORAGE_KEY =
  'brandAutocompleteTriggered';
const BRAND_AUTOCOMPELTE_TIMEOUT_ = 60_000;

const setAutocompleteTimestamp = () => {
  localStorage.setItem(
    BRAND_AUTOCOMPELTE_LOCAL_STORAGE_KEY,
    Date.now().toString()
  );
};

const getAutocompleteState = () => {
  const storedTimestamp = localStorage.getItem(
    BRAND_AUTOCOMPELTE_LOCAL_STORAGE_KEY
  );
  if (!storedTimestamp) return false;

  const timestamp = parseInt(storedTimestamp, 10);
  const isRecent = Date.now() - timestamp < BRAND_AUTOCOMPELTE_TIMEOUT_;

  if (!isRecent) {
    localStorage.removeItem(BRAND_AUTOCOMPELTE_LOCAL_STORAGE_KEY);
  }

  return isRecent;
};

const HomePage: FC<RouteComponentProps> = memo(() => {
  const isHomePage = useIsHomePage();
  const [panelOpen, setPanelOpen] = useState<boolean | null>(null);

  useEffect(() => {
    setPanelOpen(getAutocompleteState());
  }, []);

  const containerClassName = triStateMapper(
    'exit',
    'enter',
    undefined
  )(panelOpen);
  const panelClassName = triStateMapper('enter', 'exit', undefined)(panelOpen);

  if (isNil(isHomePage)) {
    return null;
  }

  return (
    <>
      <Container className={containerClassName}>
        <HomeAdvert />
        <HomeMobileEntrance
          onClick={() => {
            setAutocompleteTimestamp();
            setPanelOpen(true);
          }}
        />
        <HomeDealBanner />
      </Container>

      <HomePanel
        className={panelClassName}
        onBackToAdvert={() => setPanelOpen(false)}
      />
    </>
  );
});

// eslint-disable-next-line import/no-default-export
export default HomePage;
