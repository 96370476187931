import React, { FC, ReactNode, useState } from 'react';
import styled from 'styled-components';
import { CleanButton } from 'src/common/components/Button';
import { colors } from 'src/config/colors';
import { Icon } from 'src/common/components/Icon';
import { typography } from 'src/config/typography';
import { transparentize } from 'polished';
import { Link } from 'gatsby';
import { useSelector } from 'react-redux';
import { activeOfferSelector } from 'src/app/product/state/products/productsSelectors';

const Container = styled(CleanButton)<{ isTiresClicked: boolean }>`
  background: ${({ isTiresClicked }) =>
    isTiresClicked
      ? transparentize(0.9, colors.white)
      : transparentize(0.1, colors.gray1)};
  border-radius: 0.125rem;
  padding: 0.5rem 0.125rem 0.25rem;
  width: 3.25rem;
  height: 4.25rem;

  display: inline-grid;
  grid-template-rows: min-content 1fr;
  grid-template-columns: 100%;
  grid-row-gap: 0.25rem;
  align-items: flex-start;
  justify-content: center;
  text-align: center;

  white-space: normal;

  &:hover:not(:focus):not(:active):not(:disabled) {
    background: ${({ isTiresClicked }) =>
      isTiresClicked
        ? transparentize(0.8, colors.primary)
        : transparentize(0.8, colors.primary)};
  }

  &:focus {
    background: ${colors.darkBlue};
    outline: 2px solid ${colors.primary};
    outline-offset: -2px;
  }
`;

const IconWrapper = styled.div`
  position: relative;
`;

const Badge = styled.span`
  position: absolute;
  display: flex;
  top: -0.0625rem;
  left: 0.675rem;
`;

const Label = styled.div`
  font-family: ${typography.fontFamilyCondensed};
  font-size: 0.625rem;
  line-height: 0.75rem;
`;

const activeIcon = (
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 4C8 6.20914 6.20914 8 4 8C1.79086 8 0 6.20914 0 4C0 1.79086 1.79086 0 4 0C6.20914 0 8 1.79086 8 4Z"
      fill="#0081FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 2.67018L3.0479 6.5L1.5 5L2.19158 4.32982L3.0479 5.15964L6.30842 2L7 2.67018Z"
      fill="white"
    />
  </svg>
);

const inactiveIcon = (
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 4C8 6.20914 6.20914 8 4 8C1.79086 8 0 6.20914 0 4C0 1.79086 1.79086 0 4 0C6.20914 0 8 1.79086 8 4Z"
      fill="#EC2B39"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.99526 5.64733L2.32373 3.0237L3.01531 2.35352L5.67597 4.98768L4.99526 5.64733Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.00425 5.64733L5.67578 3.0237L4.9842 2.35352L2.32354 4.98768L3.00425 5.64733Z"
      fill="white"
    />
  </svg>
);

export interface OfferOptionButtonProps {
  label: ReactNode;
  icon: string;
  state: 'active' | 'inactive';
  href?: string;
  fillColor?: string;
  isTiresClicked?: boolean;
  onTiresClick?: () => void;
}

export const OfferOptionButton: FC<OfferOptionButtonProps> = ({
  label,
  icon,
  state,
  href,
  fillColor,
  isTiresClicked,
  onTiresClick,
}) => {
  const activeOffer = useSelector(activeOfferSelector);

  const isLeasing = activeOffer?.financingType === 'leasing';
  if (state !== 'active') {
    return null;
  }

  if (React.isValidElement(label) && label.props.children === 'Opony') {
    return (
      <Container
        as={href ? Link : undefined}
        to={href ?? ''}
        isTiresClicked={isTiresClicked || isLeasing}
        onClick={onTiresClick}
      >
        <IconWrapper>
          <Icon
            code={icon}
            height={28}
            width={32}
            color={
              isTiresClicked ? fillColor : transparentize(0.6, colors.white)
            }
          />
          {isTiresClicked && (
            <Badge>{state === 'active' ? activeIcon : inactiveIcon}</Badge>
          )}
        </IconWrapper>
        <Label
          style={{
            color: isTiresClicked
              ? fillColor
              : transparentize(0.6, colors.white),
          }}
        >
          {label}
        </Label>
      </Container>
    );
  }

  return (
    <Container
      as={href ? Link : undefined}
      to={href ?? ''}
      isTiresClicked={true}
    >
      <IconWrapper>
        <Icon code={icon} height={28} width={32} color={fillColor} />
        <Badge>{state === 'active' ? activeIcon : inactiveIcon}</Badge>
      </IconWrapper>
      <Label>{label}</Label>
    </Container>
  );
};
