import { useDispatchRequest } from 'src/lib/useDispatchRequest';
import { commonFiltersActions } from 'src/app/state/commonFilters/commonFiltersActions';
import { useLocation } from '@reach/router';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { brandsListSelector } from 'src/app/state/commonFilters/commonFiltersSelectors';
import { all, filter, isEmpty, pipe, split } from 'ramda';

export const useIsHomePage = () => {
  useDispatchRequest(commonFiltersActions.request);

  const { pathname } = useLocation();
  const [isHomePage, setIsHomePage] = useState<boolean | null>(null);

  const brands = useSelector(brandsListSelector);

  useEffect(() => {
    if (!brands) {
      return;
    }

    const selectedBrands = pipe(
      split('/'),
      filter((x) => !isEmpty(x))
    )(pathname);

    setIsHomePage(
      all<string>(
        (selectedBrand) =>
          brands.findIndex((brand) => brand.code === selectedBrand) > -1
      )(selectedBrands)
    );
  }, [brands, pathname]);

  return isHomePage;
};
