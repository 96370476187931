import { useDispatch, useSelector } from 'react-redux';
import {
  activeOfferSelector,
  customerTypeOffersSelector,
} from 'src/app/product/state/products/productsSelectors';
import { useLocation } from '@reach/router';
import {
  Artefakta4DekraExternal,
  Artefakta4DekraOffer,
} from 'src/common/models/offer';
import { useEffect, useMemo, useState } from 'react';
import { productsActions } from 'src/app/product/state/products/productsActions';
import { UsedProduct } from 'src/app/common/models/product';
import { mapObjIndexed, omit } from 'ramda';
import { customerTypeSelector } from 'src/app/state/customerType/customerTypeSelectors';
import { debounce } from 'lodash';
import { CustomerType } from 'src/common/models/customerType';
import { navigate } from 'gatsby';
import { buildProductItemPageRoute } from 'src/app/routes';

const artefaktaOrigin = 'https://kalkulator.radosczjazdy.pl';

const artefaktaWidgetUrl = `${artefaktaOrigin}/API/BMW_CARPLATFORM/GET/car_sliders`;

export const useArtefaktaCalculatorConfigurations = (product: UsedProduct) => {
  const availableOffers = useSelector(
    customerTypeOffersSelector
  ) as Artefakta4DekraOffer[];
  const location = useLocation();
  const reduxDispatch = useDispatch();
  const customerType = useSelector(customerTypeSelector);
  const activeOffer = useSelector(activeOfferSelector);

  // Initially get offer from URL
  const initialOffer = useMemo(() => {
    if (
      activeOffer != null &&
      availableOffers.find((a) => a.id === activeOffer.id) != null
    ) {
      return activeOffer as Artefakta4DekraOffer;
    }

    const query = new URLSearchParams(location.search);
    const offerId = query.get('offerId');
    const stringifiedDetails = query.get('offerDetails');
    const offer =
      offerId != null ? availableOffers.find((a) => a.id === offerId) : null;

    if (offer != null && stringifiedDetails != null) {
      const details = JSON.parse(atob(stringifiedDetails));

      return { ...offer, external: { ...offer.external, details } };
    } else {
      return availableOffers[0];
    }
  }, []);

  const [offer, setOffer] = useState<Artefakta4DekraOffer | undefined>(
    initialOffer
  );

  const carDetails = useMemo(
    () => ({
      total: product.listPrice,
      age: new Date().getFullYear() - product.productionYear!,
      mileage: product.resellDetails.mileage,
      VIN: product.vins[0],
    }),
    []
  );

  const widgetUrl = useMemo(
    () =>
      `${artefaktaWidgetUrl}?${new URLSearchParams({
        ...mapObjIndexed((a) => `${a}`, carDetails),
        FP: JSON.stringify(
          mapObjIndexed((a) => `${a}`, offer?.external.details ?? {})
        ),
      })}`,
    [availableOffers]
  );

  useEffect(() => {
    const artefaktaListener = debounce((event: MessageEvent<string>) => {
      if (event.origin !== artefaktaOrigin) {
        return;
      }

      try {
        const data = JSON.parse(
          event.data
        ) as Artefakta4DekraExternal['details'] & {
          rate: number;
          rate_netto: number;
        };

        setOffer((o) => ({
          ...(o ?? availableOffers[0]),
          price:
            customerType === CustomerType.B2B ? data.rate_netto : data.rate,
          external: {
            ...(o ?? availableOffers[0]).external,
            priceB2B: data.rate,
            priceB2C: data.rate_netto,
            details: omit(['rate', 'rate_netto'], data),
          },
        }));
      } catch (e) {
        // ...
      }
    }, 300);

    window.addEventListener('message', artefaktaListener);

    return () => {
      window.removeEventListener('message', artefaktaListener);
    };
  }, []);

  // If there is no current offer, reset state
  useEffect(() => {
    if (
      offer == null ||
      availableOffers.find((a) => a.id === offer?.id) == null
    ) {
      setOffer(availableOffers[0]);
    }
  }, [offer, availableOffers]);

  // After offer changes, set the URL properly and push offer to state
  useEffect(() => {
    if (offer != null) {
      const newUrl = buildProductItemPageRoute({
        annualMileage: offer.annualMileage,
        brandCode: product.brand?.code,
        buyoutPrice: offer.buyoutPrice,
        contractPeriod: offer.contractPeriod,
        customerType: offer.customerType,
        financingType: offer.financingType,
        initialPayment: offer.initialPayment,
        modelLabel: product.model?.label,
        packageType: offer.packageType,
        productId: product.id,
        version: product.version,
      });

      const params = new URLSearchParams(location.search);

      params.set('offerId', offer.id);
      params.set('offerDetails', btoa(JSON.stringify(offer.external.details)));

      navigate(`${newUrl}?${params}`, {
        replace: true,
      });

      reduxDispatch(productsActions.setActiveOffer(offer));
    } else {
      reduxDispatch(productsActions.setActiveOffer(undefined));
    }
  }, [offer]);

  return {
    widgetUrl,
  };
};
