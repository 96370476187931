import { values } from 'ramda';

export enum OptionFilter {
  Availability = 'availability',
  Brand = 'brand',
  Body = 'body',
  Category = 'category',
  Drive = 'drive',
  FinancingType = 'financingType',
  Engine = 'engine',
  Gearbox = 'gearbox',
  UpholsteryType = 'upholsteryType',
  CabinSpace = 'cabinSpace',
  PaintColorGroup = 'paintColorGroup',
  UpholsteryColorGroup = 'upholsteryColorGroup',
  EquipmentPackage = 'equipmentPackage',
  EquipmentItemGroup = 'equipmentItemGroup',
  TrunkSpace = 'trunkSpace',
  Sort = 'sort',
}

export const isOptionFilter = (a: unknown): a is OptionFilter =>
  (values(OptionFilter) as unknown[]).includes(a);

export enum RangeFilter {
  Price = 'price',
  Power = 'power',
  Capacity = 'capacity',
  FuelConsumption = 'fuelConsumption',
  Emission = 'emission',
  Range = 'range',
  RimSize = 'rimSize',
}

export const isRangeFilter = (a: unknown): a is RangeFilter =>
  (values(RangeFilter) as unknown[]).includes(a);

export enum ExoticFilter {
  Model = 'model',
  NewProductOffersPagination = 'newProductOffersPagination',
  UsedProductOffersPagination = 'usedProductOffersPagination',
}

export const isExoticFilter = (a: unknown): a is ExoticFilter =>
  (values(ExoticFilter) as unknown[]).includes(a);

export type FilterKind = OptionFilter | RangeFilter | ExoticFilter;

export const isFilterKind = (a: unknown): a is FilterKind =>
  isRangeFilter(a) || isOptionFilter(a) || isExoticFilter(a);

export interface FilterState<T> {
  value: T;
}

export type RangeFilterState = FilterState<[number, number]>;

export type OptionFilterState<
  T extends { id: string; label: string } = { id: string; label: string }
> = FilterState<string[]> & { options?: T[] };

export type ColorOptionFilterState = OptionFilterState<{
  id: string;
  label: string;
  hex: string;
}>;

export type EquipmentItemGroupFilterState = FilterState<string[]> & {
  options?: never;
} & {
  groups?: {
    id: string;
    label: string;
    options: { id: string; label: string }[];
  }[];
};

export type EquipmentPackageFilterState = OptionFilterState<{
  id: string;
  label: string;
  brand?: { id: string; code: string };
}>;

export type ExternalOptionFilterState = OptionFilterState<never>;
export type ModelFilter = { [brand: string]: string[] };
export type ModelFilterState = FilterState<ModelFilter>;
export type PaginationFilter = { page: number; size: number };
export type PaginationFilterState = FilterState<PaginationFilter>;

export interface FilterStateKindMap {
  [OptionFilter.Availability]: OptionFilterState;
  [OptionFilter.Brand]: ExternalOptionFilterState;
  [ExoticFilter.Model]: ModelFilterState;
  [ExoticFilter.NewProductOffersPagination]: PaginationFilterState;
  [ExoticFilter.UsedProductOffersPagination]: PaginationFilterState;
  [OptionFilter.Body]: ExternalOptionFilterState;
  [OptionFilter.Category]: ExternalOptionFilterState;
  [OptionFilter.Drive]: OptionFilterState;
  [OptionFilter.FinancingType]: OptionFilterState;
  [OptionFilter.TrunkSpace]: OptionFilterState;
  [OptionFilter.Engine]: OptionFilterState;
  [OptionFilter.Gearbox]: OptionFilterState;
  [OptionFilter.UpholsteryType]: OptionFilterState;
  [OptionFilter.CabinSpace]: OptionFilterState;
  [OptionFilter.PaintColorGroup]: ColorOptionFilterState;
  [OptionFilter.UpholsteryColorGroup]: ColorOptionFilterState;
  [OptionFilter.EquipmentPackage]: EquipmentPackageFilterState;
  [OptionFilter.EquipmentItemGroup]: EquipmentItemGroupFilterState;
  [OptionFilter.Sort]: OptionFilterState;
  [RangeFilter.Price]: RangeFilterState;
  [RangeFilter.Power]: RangeFilterState;
  [RangeFilter.Capacity]: RangeFilterState;
  [RangeFilter.FuelConsumption]: RangeFilterState;
  [RangeFilter.Emission]: RangeFilterState;
  [RangeFilter.Range]: RangeFilterState;
  [RangeFilter.RimSize]: RangeFilterState;
}

export type FilterValues = {
  [P in FilterKind]?: FilterStateKindMap[P]['value'];
};

export type FiltersState = {
  [P in FilterKind]?: FilterStateKindMap[P];
};
