import React, { FC } from 'react';
import { useOptionFilterControl } from 'src/app/search/hooks/useFilterControl';
import { OptionFilter } from 'src/app/common/models/filter';
import { SortValues } from 'src/app/common/models/product';
import styled, { css } from 'styled-components';
import { Button } from 'src/common/components/Button';
import { colors } from 'src/config/colors';
import { media } from 'src/config/breakpoints';
import ChevronDown from 'src/assets/icons/ChevronDown';
import ChevronUp from 'src/assets/icons/ChevronUp';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SortingButtonProps {}

const StyledButton = styled(Button)`
  ${({ active }) =>
    active &&
    css`
      .btn__content {
        background: ${colors.primary}!important;
        color: ${colors.white};
      }
    `};
`;

const Container = styled.div`
  position: relative;
  margin-right: 0.5rem;

  ${media.w.max.px1200(css`
    display: none;
  `)};
`;

export const SortingButton: FC<SortingButtonProps> = ({}) => {
  const { state, setValue } = useOptionFilterControl(OptionFilter.Sort);
  console.log('state', state);
  const handleSortClick = () => {
    switch (state.value[0]) {
      case SortValues.Ascending:
        setValue([SortValues.Descending]);
        break;
      case SortValues.Descending:
        setValue([SortValues.Ascending]);
        break;
    }
  };

  return (
    <Container>
      <StyledButton
        kind="dropdown"
        icon={state.value[0] === SortValues.Ascending ? ChevronUp : ChevronDown}
        onClick={handleSortClick}
      >
        {state.value[0] === SortValues.Ascending ? 'rosnąco' : 'malejąco'}
      </StyledButton>
    </Container>
  );
};
