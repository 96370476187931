import {
  ExoticFilter,
  FilterKind,
  FilterStateKindMap,
  OptionFilter,
  RangeFilter,
} from 'src/app/common/models/filter';
import { IconCode } from 'src/common/components/Icon';
import { equals } from 'ramda';
import {
  Availability,
  Body,
  CabinSpace,
  Drive,
  EngineType,
  GearboxType,
  SortValues,
  TrunkSpace,
  UpholsteryType,
} from 'src/app/common/models/product';
import {
  AvailabilityLabelMapper,
  bodyLabelMapper,
  cabinSpaceLabelMapper,
  driveLabelMapper,
  engineTypeLabelMapper,
  financingTypeLabelMapper,
  gearboxTypeLabelMapper,
  trunkSpaceLabelMapper,
  upholsteryTypeLabelMapper,
} from 'src/app/product/services/productMappers';
import { FinancingType } from 'src/common/models/offer';
import { SearchFiltersState } from 'src/app/search/state/searchFilters/searchFiltersReducer';

type FilterConfigType = {
  [P in FilterKind]: {
    initialValue: FilterStateKindMap[P]['value'];
    name?: string;
    shortName?: string;
  };
} &
  {
    [P in RangeFilter]: {
      min: number;
      max: number;
      step: number;
      unit?: string;
      icon?: IconCode;
    };
  } &
  {
    [P in OptionFilter]: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      options: any[];
    };
  };

export const filterConfig: FilterConfigType = {
  [OptionFilter.Availability]: {
    initialValue: [],
    name: 'Dostępność',
    shortName: 'Dostępność',
    options: [Availability.Available, Availability.Unavailable].map((id) => ({
      id,
      label: AvailabilityLabelMapper(id),
    })),
  },
  [OptionFilter.Brand]: {
    initialValue: [],
    name: 'Marka',
    shortName: 'Marka',
    options: [],
  },
  [ExoticFilter.Model]: {
    initialValue: {},
    name: 'Model',
    shortName: 'Model',
  },
  [ExoticFilter.NewProductOffersPagination]: {
    initialValue: {
      page: 1,
      size: 9,
    },
    name: 'newProductsOffersPage',
    shortName: 'npoPage',
  },
  [ExoticFilter.UsedProductOffersPagination]: {
    initialValue: { page: 1, size: 6 },
    name: 'UsedProductOfferPage',
    shortName: 'upoPage',
  },
  [OptionFilter.Body]: {
    initialValue: [],
    name: 'Nadwozie',
    shortName: 'Nadwozie',
    options: [
      Body.Hatchback,
      Body.Sedan,
      Body.Liftback,
      Body.Combi,
      Body.Suv,
      Body.Van,
      Body.Cabrio,
      Body.Coupe,
      Body.Crossover,
      Body.Motorcycle,
    ].map((id) => ({ id, label: bodyLabelMapper(id) })),
  },
  [OptionFilter.Category]: {
    initialValue: [],
    name: 'Twoja potrzeba',
    shortName: 'Potrzeba',
    options: [],
  },
  [OptionFilter.Engine]: {
    initialValue: [],
    name: 'Silnik',
    shortName: 'Silnik',
    options: [
      EngineType.Petrol,
      EngineType.Diesel,
      EngineType.Electric,
      EngineType.Hybrid,
    ].map((id) => ({ id, label: engineTypeLabelMapper(id) })),
  },
  [OptionFilter.Drive]: {
    initialValue: [],
    name: 'Napęd',
    shortName: 'Napęd',
    options: [Drive.FWD, Drive.RWD, Drive.AWD].map((id) => ({
      id,
      label: driveLabelMapper(id),
    })),
  },
  [OptionFilter.TrunkSpace]: {
    initialValue: [],
    name: 'Bagażnik',
    shortName: 'Bagaż',
    options: [TrunkSpace.S, TrunkSpace.M, TrunkSpace.L, TrunkSpace.XL].map(
      (id) => ({
        id,
        label: trunkSpaceLabelMapper(id),
      })
    ),
  },
  [OptionFilter.FinancingType]: {
    initialValue: [],
    name: 'Finansowanie',
    shortName: 'Finansowanie',
    options: [
      FinancingType.Rent,
      FinancingType.Leasing,
      FinancingType.Loan,
    ].map((id) => ({
      id,
      label: financingTypeLabelMapper(id),
    })),
  },
  [OptionFilter.Gearbox]: {
    initialValue: [],
    name: 'Skrzynia biegów',
    shortName: 'Skrzynia',
    options: [GearboxType.AT, GearboxType.MT].map((id) => ({
      id,
      label: gearboxTypeLabelMapper(id),
    })),
  },
  [OptionFilter.UpholsteryType]: {
    initialValue: [],
    name: 'Tapicerka - rodzaj',
    shortName: 'Tapicerka',
    options: [
      UpholsteryType.Material,
      UpholsteryType.Leather,
      UpholsteryType.MaterialLeather,
      UpholsteryType.AlcantaraMaterial,
      UpholsteryType.AlcantaraLeather,
    ].map((id) => ({ id, label: upholsteryTypeLabelMapper(id) })),
  },
  [OptionFilter.CabinSpace]: {
    initialValue: [],
    name: 'Rozmiar kabiny',
    shortName: 'Kabina',
    options: [
      CabinSpace.S,
      CabinSpace.M,
      CabinSpace.L,
      CabinSpace.XL,
    ].map((id) => ({ id, label: cabinSpaceLabelMapper(id) })),
  },
  [OptionFilter.PaintColorGroup]: {
    initialValue: [],
    name: 'Kolor nadwozia',
    shortName: 'Kolor',
    options: [],
  },
  [OptionFilter.UpholsteryColorGroup]: {
    initialValue: [],
    name: 'Tapicerka - kolor',
    shortName: 'Tapicerka',
    options: [],
  },
  [OptionFilter.EquipmentItemGroup]: {
    initialValue: [],
    name: 'Opcje wyposażenia',
    shortName: '',
    options: [],
  },
  [OptionFilter.EquipmentPackage]: {
    initialValue: [],
    name: 'Popularne pakiety wyposażenia',
    shortName: '',
    options: [],
  },
  [OptionFilter.Sort]: {
    initialValue: [SortValues.Ascending],
    name: 'Tapicerka - kolor',
    shortName: 'Tapicerka',
    options: [],
  },
  [RangeFilter.Price]: {
    initialValue: [0, 100000] as [number, number],
    name: 'Rata',
    shortName: 'Rata',
    unit: 'zł/msc',
    min: 0,
    max: 5000,
    step: 500,
  },
  [RangeFilter.Power]: {
    initialValue: [20, 1200] as [number, number],
    name: 'Moc',
    shortName: 'Moc',
    unit: 'KM',
    icon: IconCode.Speedometer,
    min: 20,
    max: 1200,
    step: 10,
  },
  [RangeFilter.Capacity]: {
    initialValue: [100, 8000] as [number, number],
    name: 'Pojemność',
    shortName: 'Pojemność',
    unit: 'cm³',
    icon: IconCode.Cylinder,
    min: 100,
    max: 8000,
    step: 100,
  },
  [RangeFilter.FuelConsumption]: {
    initialValue: [0, 14] as [number, number],
    name: 'Zużycie',
    shortName: 'Zużycie',
    unit: 'l / 100km',
    icon: IconCode.PetrolPump,
    min: 0,
    max: 14,
    step: 1,
  },
  [RangeFilter.Emission]: {
    initialValue: [0, 310] as [number, number],
    name: 'Emisja',
    shortName: 'Emisja',
    unit: 'g / km',
    icon: IconCode.ExhaustEmission,
    min: 0,
    max: 310,
    step: 10,
  },
  [RangeFilter.Range]: {
    initialValue: [50, 1000] as [number, number],
    name: 'Zasięg',
    shortName: 'Zasięg',
    unit: 'km',
    icon: IconCode.Range,
    min: 50,
    max: 1000,
    step: 10,
  },
  [RangeFilter.RimSize]: {
    initialValue: [14, 22] as [number, number],
    name: 'Rozmiar felg',
    shortName: 'Felga',
    unit: 'cale',
    icon: IconCode.Rim,
    min: 14,
    max: 22,
    step: 1,
  },
};

export const defaultFiltersState = {
  [OptionFilter.Availability]: {
    value: filterConfig[OptionFilter.Availability].initialValue,
  },
  [OptionFilter.Brand]: {
    value: filterConfig[OptionFilter.Brand].initialValue,
  },
  [ExoticFilter.Model]: {
    value: filterConfig[ExoticFilter.Model].initialValue,
  },
  [ExoticFilter.NewProductOffersPagination]: {
    value: filterConfig[ExoticFilter.NewProductOffersPagination].initialValue,
  },
  [ExoticFilter.UsedProductOffersPagination]: {
    value: filterConfig[ExoticFilter.UsedProductOffersPagination].initialValue,
  },
  [OptionFilter.Body]: {
    value: filterConfig[OptionFilter.Body].initialValue,
  },
  [OptionFilter.Category]: {
    value: filterConfig[OptionFilter.Category].initialValue,
  },
  [OptionFilter.Engine]: {
    value: filterConfig[OptionFilter.Engine].initialValue,
    options: filterConfig[OptionFilter.Engine].options,
  },
  [OptionFilter.Gearbox]: {
    value: filterConfig[OptionFilter.Gearbox].initialValue,
    options: filterConfig[OptionFilter.Gearbox].options,
  },
  [OptionFilter.UpholsteryType]: {
    value: filterConfig[OptionFilter.UpholsteryType].initialValue,
    options: filterConfig[OptionFilter.UpholsteryType].options,
  },
  [OptionFilter.CabinSpace]: {
    value: filterConfig[OptionFilter.CabinSpace].initialValue,
    options: filterConfig[OptionFilter.CabinSpace].options,
  },
  [OptionFilter.PaintColorGroup]: {
    value: filterConfig[OptionFilter.PaintColorGroup].initialValue,
    options: [],
  },
  [OptionFilter.UpholsteryColorGroup]: {
    value: filterConfig[OptionFilter.UpholsteryColorGroup].initialValue,
    options: [],
  },
  [OptionFilter.EquipmentItemGroup]: {
    value: filterConfig[OptionFilter.EquipmentItemGroup].initialValue,
    groups: [],
  },
  [OptionFilter.EquipmentPackage]: {
    value: filterConfig[OptionFilter.EquipmentPackage].initialValue,
    options: [],
  },
  [OptionFilter.Sort]: {
    value: filterConfig[OptionFilter.Sort].initialValue,
  },
  [OptionFilter.FinancingType]: {
    value: filterConfig[OptionFilter.FinancingType].initialValue,
    options: [],
  },
  [OptionFilter.Drive]: {
    value: filterConfig[OptionFilter.Drive].initialValue,
    options: filterConfig[OptionFilter.Drive].options,
  },
  [OptionFilter.TrunkSpace]: {
    value: filterConfig[OptionFilter.TrunkSpace].initialValue,
    options: filterConfig[OptionFilter.TrunkSpace].options,
  },
  [RangeFilter.Price]: {
    value: filterConfig[RangeFilter.Price].initialValue,
  },
  [RangeFilter.Power]: {
    value: filterConfig[RangeFilter.Power].initialValue,
  },
  [RangeFilter.Capacity]: {
    value: filterConfig[RangeFilter.Capacity].initialValue,
  },
  [RangeFilter.FuelConsumption]: {
    value: filterConfig[RangeFilter.FuelConsumption].initialValue,
  },
  [RangeFilter.Emission]: {
    value: filterConfig[RangeFilter.Emission].initialValue,
  },
  [RangeFilter.Range]: {
    value: filterConfig[RangeFilter.Range].initialValue,
  },
  [RangeFilter.RimSize]: {
    value: filterConfig[RangeFilter.RimSize].initialValue,
  },
};

export const isFilterPristine = (kind: FilterKind, value: unknown) =>
  equals(value, filterConfig[kind].initialValue);

export const allFiltersPristine = (filtersState: SearchFiltersState) => {
  const selectedFilter = Object.keys(filtersState).find((key) => {
    return !equals(
      filtersState[key as FilterKind].value,
      filterConfig[key as FilterKind].initialValue
    );
  });
  return !selectedFilter;
};
