import React, { memo, useEffect, useMemo } from 'react';
import { OrderLayout } from 'src/app/product/components/OrderLayout';
import { navigate, RouteComponentProps, useLocation } from '@reach/router';
import { OrderNextSteps } from 'src/app/product/components/OrderNextSteps';
import { useDispatch, useSelector } from 'react-redux';
import {
  activeOfferSelector,
  activeProductSelector,
} from 'src/app/product/state/products/productsSelectors';
import {
  buildProductItemPageRouteFromUrlParams,
  SearchPageRoute,
} from 'src/app/routes';
import { useStorePageUrl } from 'src/common/hooks/useStorePageUrl';
import { RedirectUrl } from 'src/state/pageContext/pageContextPayloads';
import { snackbarActions } from 'src/state/notification/notificationActions';

export type OrderPageProps = RouteComponentProps<{
  offerId: string;
  productId: string;
}>;

export const OrderThankYouPage = memo<OrderPageProps>((urlParams) => {
  const dispatch = useDispatch();

  const activeProduct = useSelector(activeProductSelector);
  const activeOffer = useSelector(activeOfferSelector);
  const { state } = useLocation();

  const statePrevPageUrl = (state as { orderFormPageUrl: RedirectUrl })
    ?.orderFormPageUrl;

  const backPath = useMemo(() => {
    const basePath = buildProductItemPageRouteFromUrlParams(urlParams);

    const params =
      urlParams.offerId != null
        ? new URLSearchParams({ offerId: urlParams.offerId })
        : undefined;

    return `${basePath}${params ? `?${params}` : ''}`;
  }, [urlParams]);

  useStorePageUrl();

  useEffect(() => {
    if (activeProduct == null) {
      navigate(SearchPageRoute, {
        replace: true,
      });
    } else if (activeOffer?.id !== urlParams.offerId) {
      navigate(backPath, {
        replace: true,
      }).then(() =>
        dispatch(
          snackbarActions.enqueueSnackbar({
            message:
              'W celu złożenia zapytania o ofertę należy wcześniej skonfigurować ją przy użyciu kalkulatora',
            options: { variant: 'warning' },
          })
        )
      );
    }
  }, [activeOffer, backPath, activeProduct]);

  if (!statePrevPageUrl) {
    navigate(backPath).then(() =>
      dispatch(
        snackbarActions.enqueueSnackbar({
          message:
            'W celu złożenia zapytania o ofertę należy wcześniej skonfigurować ją przy użyciu kalkulatora',
          options: { variant: 'warning' },
        })
      )
    );
    return null;
  }

  return (
    <OrderLayout backPath={backPath}>
      <OrderNextSteps />
    </OrderLayout>
  );
});
