import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { IconCode } from 'src/common/components/Icon';
import {
  OfferOptionButton,
  OfferOptionButtonProps,
} from 'src/app/product/components/ProductPageSummary/OfferOptionButton';
import { OfferParameterHeader } from 'src/app/product/components/ProductPageSummary/OfferParameterHeader';
import { FinancingType, PackageType } from 'src/common/models/offer';
import { useSelector } from 'react-redux';
import { customerTypeSelector } from 'src/app/state/customerType/customerTypeSelectors';
import { CustomerType } from 'src/common/models/customerType';
import { luxuryCarTypes, ProductType } from 'src/app/common/models/product';
import { FuelSpecialOfferRoute } from 'src/public/publicRoutes';
import { colors } from 'src/config/colors';
import { isAuthenticatedSelector } from 'src/state/auth/authSelectors';

const Grid = styled.div`
  display: grid;
  grid-column-gap: 0.25rem;
  grid-row-gap: 0.25rem;
  grid-template-columns: auto auto auto auto;
  justify-content: flex-start;
  grid-auto-rows: 1fr;
`;

interface ProductPageSummaryOfferProps {
  financingType: FinancingType;
  packageType: PackageType | null;
  productType: ProductType;
  isTiresClicked?: boolean;
  onTiresClick?: () => void;
}

const financingTypeMap = {
  [FinancingType.Loan]: 'kredycie',
  [FinancingType.Leasing]: 'leasingu',
  [FinancingType.Rent]: 'najmie',
};

export const ProductPageSummaryOfferOptions: FC<ProductPageSummaryOfferProps> = ({
  financingType,
  packageType,
  productType,
  isTiresClicked,
  onTiresClick,
}) => {
  const userCustomerType = useSelector(customerTypeSelector);
  const isLoggedIn = useSelector(isAuthenticatedSelector);

  const rentalOptions = useMemo(() => {
    const isRentFinancingType = financingType === FinancingType.Rent;
    const isPremiumPackage =
      isRentFinancingType && packageType === PackageType.Premium;

    const options: OfferOptionButtonProps[] = [
      {
        icon: IconCode.Insurance,
        label:
          userCustomerType === CustomerType.B2B ? (
            <>OC / AC / NNW</>
          ) : (
            <>OC / AC</>
          ),
        state: isPremiumPackage ? 'active' : 'inactive',
      },
      {
        icon: IconCode.Rim,
        label: <>Opony</>,
        state: isPremiumPackage ? 'active' : 'inactive',
      },
      {
        icon: IconCode.ServicePackage,
        label: <>Serwis</>,
        state: isRentFinancingType ? 'active' : 'inactive',
      },
      {
        icon: IconCode.Assistance,
        label: <>Assistance</>,
        state: isPremiumPackage ? 'active' : 'inactive',
      },
      {
        icon: IconCode.FuelCard,
        label: <>Karta paliwowa</>,
        state: isPremiumPackage ? 'active' : 'inactive',
      },
      {
        icon: IconCode.ReplacementCar,
        label: <>Samochód zastępczy</>,
        state: isPremiumPackage ? 'active' : 'inactive',
      },
    ];

    if (!isLoggedIn) {
      options.push({
        icon: IconCode.FuelCanister,
        label: (
          <>
            2 baki <br /> w prezencie
          </>
        ),
        state: 'active',
        fillColor: colors.yellow,
        href: FuelSpecialOfferRoute,
      });
    }

    if (
      financingType === FinancingType.Rent &&
      !luxuryCarTypes.includes(productType)
    ) {
      options.unshift({
        icon: IconCode.Deposit,
        label: <>Wpłaty własnej</>,
        state: 'active',
      });
    }

    return options;
  }, [financingType, packageType, productType, isLoggedIn]);

  return (
    <>
      <OfferParameterHeader
        title={`Dostępne opcje w ${financingTypeMap[financingType]}`}
      />
      <Grid>
        {rentalOptions.map((option, index) => (
          <OfferOptionButton
            {...option}
            key={index}
            isTiresClicked={isTiresClicked}
            onTiresClick={onTiresClick}
          />
        ))}
      </Grid>
    </>
  );
};
