import React, { memo, useCallback, useEffect, useState } from 'react';
import { Button } from 'src/common/components/Button';
import { colors } from 'src/config/colors';
import ChevronDown from 'src/assets/icons/ChevronDown';
import {
  ProductPageEquipmentModal,
  ProductPageEquipmentModalProps,
} from 'src/app/product/components/ProductPageSectionEquipment/ProductPageEquipmentModal';
import { ModalPortal } from 'src/app/common/components/ModalPortal';
import { downloadPDF } from 'src/app/product/components/PdfGenerator/download-pdf';
import { activeOfferSelector } from 'src/app/product/state/products/productsSelectors';
import { useSelector } from 'react-redux';
import { userSelector } from 'src/state/auth/authSelectors';
export type ProductPageEquipmentModalLauncherProps = Omit<
  ProductPageEquipmentModalProps,
  'onClose'
>;

export const ProductPageEquipmentModalLauncher = memo<ProductPageEquipmentModalLauncherProps>(
  (props) => {
    const [showModal, setShowModal] = useState(false);
    const activeOffer = useSelector(activeOfferSelector);
    const [imageDimensions, setImageDimensions] = useState({
      width: 0,
      height: 0,
    });

    useEffect(() => {
      props.onModalToggle?.(showModal);
    }, [showModal]);

    const onClose = useCallback(() => setShowModal(false), [setShowModal]);

    const [fetchedImage, setFetchedImage] = useState<any>('');

    useEffect(() => {
      const fetchImage = async () => {
        if (!props.product.mainPhoto?.large) return;

        const path = props.product.mainPhoto?.large.replace(
          'https://wdacmsstorage.blob.core.windows.net/',
          'https://carplatformstorageprod.blob.core.windows.net/'
        );

        try {
          const response = await fetch(path);
          const fileBlob = await response.blob();
          setFetchedImage(fileBlob);
          const objectURL = URL.createObjectURL(fileBlob);

          const img = new Image();
          img.onload = () => {
            setImageDimensions({
              width: img.width,
              height: img.height,
            });
            URL.revokeObjectURL(objectURL);
          };
          img.src = objectURL;
        } catch (error) {}
      };
      fetchImage();
    }, [props.product.mainPhoto?.large]);

    const user: any = useSelector(userSelector);

    const nameAndSurname = user.value
      ? `${user.value.firstName || ''} ${user.value.lastName || ''} - ${
          user.value.businessPartner.name || ''
        }`
      : '';

    return (
      <>
        <Button
          kind="box"
          icon={ChevronDown}
          variant="transparent"
          onClick={() => setShowModal(true)}
        >
          informacje ogólne
        </Button>
        <Button
          onClick={() => {
            downloadPDF(
              props.product,
              activeOffer,
              fetchedImage,
              imageDimensions,
              nameAndSurname
            );
          }}
        >
          Wygeneruj PDF
        </Button>
        <ModalPortal
          open={showModal}
          onClose={onClose}
          background={colors.background}
          title="Informacje ogólne"
        >
          <ProductPageEquipmentModal onClose={onClose} {...props} />
        </ModalPortal>
      </>
    );
  }
);
