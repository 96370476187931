import { AuthorizedAxios } from 'src/common/services/axios';
import { PartialProduct } from 'src/app/common/models/product';
import { FilterValues } from 'src/app/common/models/filter';
import { flatten } from 'src/lib/axios';
import { evolve } from 'ramda';
import { imageUrlTransformer } from 'src/common/services/image';
import { emptyProductId } from 'src/app/search/emptyProductId';
import { PaginatedResponse } from 'src/app/common/models/paginatedResponse';
import { MOTORCYCLE_ID } from 'src/common/constants';

export const searchProducts = ({
  newProductOffersPagination,
  usedProductOffersPagination,
  sort, // Added sort parameter
  ...rest
}: FilterValues) =>
  AuthorizedAxios.post<PaginatedResponse<PartialProduct>>('products/search', {
    ...rest,
    type: 'new',
    pagination: newProductOffersPagination,
    sort: {
      price: sort ? sort[0] : 'asc',
    },
    ...(rest.brand?.includes(MOTORCYCLE_ID)
      ? { vehicleType: 'motor' }
      : rest.brand
      ? {}
      : { vehicleType: 'car' }),
  })
    .then(flatten)
    .then((response) => ({
      ...response,
      results: response.results.map(
        evolve({
          mainPhoto: imageUrlTransformer,
        })
      ),
    }));

export const searchUsedProducts = ({
  newProductOffersPagination,
  usedProductOffersPagination,
  sort, // Added sort parameter
  ...rest
}: FilterValues) =>
  AuthorizedAxios.post<PaginatedResponse<PartialProduct>>('products/search', {
    ...rest,
    type: 'used',
    pagination: usedProductOffersPagination,
    sort: {
      price: sort ? sort[0] : 'asc',
    },
  })
    .then(flatten)
    .then((response) => ({
      ...response,
      results: response.results.map(
        evolve({
          mainPhoto: imageUrlTransformer,
        })
      ),
    }));

export const getProductIndexFromResults = (
  id?: string,
  products: PartialProduct[] = []
) =>
  id === emptyProductId
    ? products?.length ?? 0
    : products.findIndex((a) => a.id === id) ?? 0;
