import {
  Document,
  Page,
  StyleSheet,
  Text,
  pdf,
  Image,
  View,
  Font,
  Link,
} from '@react-pdf/renderer';
import React, { FC } from 'react';

import logo from 'src/assets/images/logo.png';
import topElement1 from 'src/assets/images/top-element-1.png';
import topElement2 from 'src/assets/images/top-element-2.png';
import power from 'src/assets/images/power.png';
import acceleration from 'src/assets/images/acceleration.png';
import gearbox from 'src/assets/images/gearbox.png';
import fuel from 'src/assets/images/fuel.png';
import trunk from 'src/assets/images/trunk.png';
import drive from 'src/assets/images/drive.png';
import service from 'src/assets/images/service.png';
import assistance from 'src/assets/images/assistance.png';
import insurance from 'src/assets/images/insurance.png';
import replacement from 'src/assets/images/replacement.png';
import spec from 'src/assets/images/spec.png';
import facebook from 'src/assets/images/facebook-x.png';
import instagram from 'src/assets/images/instagram.png';
import linkedin from 'src/assets/images/linkedin.png';
import youtube from 'src/assets/images/youtube.png';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Me5Q.ttf',
    },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v20/KFOkCnqEu92Fr1Mu51xIIzc.ttf',
      fontWeight: 'bold',
    },
  ],
});

const headerStyles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    position: 'relative',
    margin: 0,
    padding: 0,
  },
  //header
  logo: {
    position: 'absolute',
    top: '3.2%',
    right: '4.5%',
    width: '27%',
    height: '3.2%',
  },
  topElement1: {
    position: 'absolute',
    top: '1.8%',
    left: 0,
    width: '5.04%',
    height: '5.17%',
  },
  topElement2: {
    position: 'absolute',
    top: '4.15%',
    left: '1.52%',
    width: '2.52%',
    height: '2.85%',
  },
  leasingText: {
    position: 'absolute',
    top: '3.2%',
    left: '8.4%',
    fontSize: 24,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    color: '#027fff',
  },
});

const carStyles = StyleSheet.create({
  yellowBox: {
    position: 'absolute',
    border: 2,
    borderColor: '#f2d38f',
    top: '8.57%',
    left: '8.23%',
    width: '87.22%',
    height: '8.27%',
    padding: 10,
    zIndex: 0,
  },
  whiteBox: {
    position: 'absolute',
    backgroundColor: 'white',
    top: '7.7%',
    left: '9.41%',
    width: '24%',
    height: '2.1%',
  },
  selectedCar: {
    position: 'absolute',
    color: '#808080',
    top: '7.7%',
    left: '10%',
    fontWeight: 'bold',
    fontSize: 15,
  },
  carText: {
    fontWeight: 'black',
    color: '#027fff',
    marginTop: 7,
    fontSize: 15,
    fontFamily: 'Roboto',
  },
});

const styles = StyleSheet.create({
  table: {
    position: 'absolute',
    top: '41.3%',
    left: '5.1%',
    width: '90%',
    flexDirection: 'column',
    marginTop: 16,
    marginBottom: 16,
    // borderWidth: 1,
    // borderColor: 'red',
    borderStyle: 'solid',
  },
  row: {
    flexDirection: 'row',
  },
  tableHeader: {
    flexDirection: 'row',
    backgroundColor: '#027fff',
    height: 30,
  },
  tableCell: {
    flex: 1,
    fontSize: 9,
  },
  tableHeaderCell: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tableHeaderCellText: {
    fontSize: 9,
    padding: 3,
    fontWeight: 'bold',
    color: 'white',
    fontFamily: 'Roboto',
  },
  greyText: {
    fontSize: 9,
    padding: 3,
    fontWeight: 'bold',
    color: '#949494',
    paddingTop: 10,
    paddingBottom: 10,
    fontFamily: 'Roboto',
  },
  blueText: {
    fontSize: 9,
    padding: 3,
    fontWeight: 'bold',
    color: '#027fff',
    paddingTop: 10,
    paddingBottom: 10,
    fontFamily: 'Roboto',
  },

  blueTextBig: {
    fontSize: 20,
    padding: 3,
    fontWeight: 'bold',
    color: '#027fff',
    paddingTop: 10,
    paddingBottom: 10,
    fontFamily: 'Roboto',
  },
  yellowText: {
    fontSize: 9,
    fontWeight: 'bold',
    color: '#e6b026',
    paddingTop: 10,
    paddingBottom: 10,
    fontFamily: 'Roboto',
  },
});

const carParametersStyles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: '24%',
    width: '70%',
    height: '3.78%',
    right: '2%',
    display: 'flex',
    flexDirection: 'row',
  },
  element: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 5,
  },
  icon: {
    width: 22,
    height: 22,
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  topText: {
    color: '#027fff',
    fontSize: 6,
    fontFamily: 'Roboto',
  },
  bottomText: {
    color: '#909090',
    fontSize: 6,
    fontFamily: 'Roboto',
  },
  divider: {
    width: 1,
    height: '100%',
    backgroundColor: '#e6b026',
    marginLeft: 5,
  },
});

const leasingStyles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: '29%',
    left: '8.23%',
    width: '87.22%',
    marginTop: 16,
    marginBottom: 16,
    // borderWidth: 1,
    // borderColor: 'red',
    // borderStyle: 'solid',
  },
  titleSection: {
    height: 40,
    backgroundColor: '#027fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingLeft: 8,
  },
  titleText: {
    fontSize: 18,
    color: 'white',
  },
  row: {
    height: 32,
    backgroundColor: '#f3f3f3',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 16,
    borderBottomWidth: 1,
    borderBottomColor: '#808080',
  },
  lastRow: {
    height: 32,
    backgroundColor: '#f3f3f3',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 16,
    borderBottomWidth: 2,
    borderBottomColor: '#808080',
  },
  leftCell: {
    height: 30,
    width: '40%',
    display: 'flex',
    justifyContent: 'center',
  },
  rightCell: {
    height: 30,
    width: '60%',
    paddingLeft: 16,
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
  },
  cellText: {
    fontFamily: 'Roboto',
    fontSize: 12,
  },
  highlightedText: {
    fontFamily: 'Roboto',
    fontSize: 14,
  },
});

const rentalOptions = StyleSheet.create({
  container: {
    position: 'absolute',
    top: '28.3%',
    width: '70%',
    height: '16%',
    right: '2%',
  },
  headerText: {
    fontSize: 12,
    color: '#808080',
    fontFamily: 'Roboto',
    marginBottom: 10,
  },

  headerBlueText: {
    fontSize: 12,
    color: '#027fff',
    fontFamily: 'Roboto',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '40%',
  },
  cell: {
    width: '50%',
    display: 'flex',
    flexDirection: 'row',
  },
  icon: {
    width: 45,
    height: 45,
  },
  topText: {
    marginTop: 5,
    fontSize: 9,
    color: '#027fff',
    fontFamily: 'Roboto',
  },

  bottomText: {
    fontSize: 8,
    color: '#808080',
    fontFamily: 'Roboto',
  },
  iconSection: {
    width: '30%',
  },
  textSection: {
    display: 'flex',
    flexDirection: 'column',
    width: '70%',
    marginRight: 10,
  },
});

export const GeneratePdfFromFormBlocks: FC<{
  product: any;
  activeOffer: any;
  image: any;
  imageDimensions: any;
  nameAndSurname: string;
}> = ({ product, activeOffer, image, imageDimensions, nameAndSurname }) => {
  const generateTableData = () => {
    const resultMap = new Map();
    product.offers.forEach((item: any) => {
      const key = `${item.contractPeriod}-${item.annualMileage}`;
      const commonFields = {
        contractPeriod: item.contractPeriod,
        initialPayment: item.initialPayment,
        annualMileage: item.annualMileage,
      };

      if (!resultMap.has(key)) {
        resultMap.set(key, {
          ...commonFields,
          priceNetto: null,
          priceBrutto: null,
        });
      }

      const current = resultMap.get(key);

      if (item.customerType === 'b2b') {
        current.priceNetto = item.price;
      } else if (item.customerType === 'b2c') {
        current.priceBrutto = item.price;
      }

      resultMap.set(key, current);
    });

    const result = Array.from(resultMap.values());

    result.sort((a, b) => {
      if (a.contractPeriod !== b.contractPeriod) {
        return a.contractPeriod - b.contractPeriod;
      }
      return a.annualMileage - b.annualMileage;
    });

    const filteredResult = result.slice(0, 9);
    return filteredResult;
  };

  const generateTextBasedOnOffer = () => {
    if (!activeOffer) return;
    switch (activeOffer.packageType) {
      case 'basic':
        return 'WYNAJEM BAZOWY';
      case 'premium':
        return 'WYNAJEM KOMPLEKSOWY';
      case null:
        return 'LEASING';
      default:
        return '';
    }
  };

  const title = generateTextBasedOnOffer();

  const tableData = generateTableData();

  const generateYearsData = () => {
    const yearsData: any[] = [];
    tableData.forEach((item) => {
      yearsData.push(item.contractPeriod);
    });
    const unique = Array.from(new Set(yearsData));

    return unique.map((item) => {
      return {
        period: item === 60 ? `${item / 12} lat` : `${item / 12} lata`,
      };
    });
  };

  const yearsData = generateYearsData();

  return (
    <Document
      style={{
        margin: 0,
        padding: 0,
      }}
    >
      <Page style={headerStyles.page}>
        <Text style={headerStyles.leasingText}>{title}</Text>
        <Image style={headerStyles.logo} src={logo} />
        <Image style={headerStyles.topElement1} src={topElement1} />
        <Image style={headerStyles.topElement2} src={topElement2} />
        <View style={carStyles.yellowBox}>
          <Text style={carStyles.carText}>{product.label}</Text>
          {image && (
            <Image
              style={{
                position: 'absolute',
                top: 10,
                right: '6%',
                width: '50%',
                height: 115 * (imageDimensions.height / 500),
                zIndex: 999,
              }}
              src={image}
            />
          )}
        </View>
        <View style={carStyles.whiteBox}></View>
        <Text style={carStyles.selectedCar}>Wybrany samochód</Text>

        <Image
          style={{
            position: 'absolute',
            top: '19.3%',
            left: '3%',
            width: 27,
            height: 27,
          }}
          src={spec}
        />

        <Link
          src={product.carSpecification}
          style={{
            color: '#0397a7',
            fontSize: 12,
            textDecoration: 'underline',
            fontFamily: 'Roboto',
            position: 'absolute',
            top: '20%',
            left: '8.23%',
          }}
        >
          Zobacz specyfikację samochodu
        </Link>

        {/* Car Parameters */}
        <View style={carParametersStyles.container}>
          <View style={carParametersStyles.element}>
            <Image style={carParametersStyles.icon} src={power} />
            <View style={carParametersStyles.textContainer}>
              <Text style={carParametersStyles.topText}>Moc silnika</Text>
              <Text style={carParametersStyles.bottomText}>
                {product.power} KM
              </Text>
            </View>
            <View style={carParametersStyles.divider} />
          </View>
          <View style={carParametersStyles.element}>
            <Image style={carParametersStyles.icon} src={acceleration} />
            <View style={carParametersStyles.textContainer}>
              <Text style={carParametersStyles.topText}>Przyspieszenie</Text>
              <Text style={carParametersStyles.bottomText}>
                {product.to100Time} s
              </Text>
            </View>
            <View style={carParametersStyles.divider} />
          </View>
          <View style={carParametersStyles.element}>
            <Image style={carParametersStyles.icon} src={gearbox} />
            <View style={carParametersStyles.textContainer}>
              <Text style={carParametersStyles.topText}>Skrzynia</Text>
              <Text style={carParametersStyles.bottomText}>
                {product.power} KM
              </Text>
            </View>
            <View style={carParametersStyles.divider} />
          </View>
          <View style={carParametersStyles.element}>
            <Image style={carParametersStyles.icon} src={fuel} />
            <View style={carParametersStyles.textContainer}>
              <Text style={carParametersStyles.topText}>Średnie zużycie</Text>
              <Text style={carParametersStyles.bottomText}>
                {product.fuelConsumption} l /100km
              </Text>
            </View>
            <View style={carParametersStyles.divider} />
          </View>
          <View style={carParametersStyles.element}>
            <Image style={carParametersStyles.icon} src={trunk} />
            <View style={carParametersStyles.textContainer}>
              <Text style={carParametersStyles.topText}>
                Pojemność bagażnika
              </Text>
              <Text style={carParametersStyles.bottomText}>
                {product.trunkSpace}
              </Text>
            </View>
            <View style={carParametersStyles.divider} />
          </View>
          <View style={carParametersStyles.element}>
            <Image style={carParametersStyles.icon} src={drive} />
            <View style={carParametersStyles.textContainer}>
              <Text style={carParametersStyles.topText}>Napęd</Text>
              <Text style={carParametersStyles.bottomText}>
                {product.gearboxType}
              </Text>
            </View>
          </View>
        </View>
        {activeOffer.financingType === 'rent' && (
          <>
            <View style={rentalOptions.container}>
              <Text style={rentalOptions.headerText}>
                Stała rata wynajmu
                <Text style={rentalOptions.headerBlueText}>
                  {' '}
                  kompleksowego{' '}
                </Text>
                w<Text style={rentalOptions.headerBlueText}> CarPlatform </Text>
                zawiera:
              </Text>
              <View style={rentalOptions.row}>
                <View style={rentalOptions.cell}>
                  <View style={rentalOptions.iconSection}>
                    <Image style={rentalOptions.icon} src={service} />
                  </View>
                  <View style={rentalOptions.textSection}>
                    <Text style={rentalOptions.topText}>Pakiet serwisowy</Text>
                    <Text style={rentalOptions.bottomText}>
                      Przeglądy, wymiany części eksploatacyjnych i naprawy
                      samochodu wynikające z jego eksploatacji.
                    </Text>
                  </View>
                </View>
                {activeOffer.packageType === 'premium' && (
                  <View style={rentalOptions.cell}>
                    <View style={rentalOptions.iconSection}>
                      <Image style={rentalOptions.icon} src={assistance} />
                    </View>
                    <View style={rentalOptions.textSection}>
                      <Text style={rentalOptions.topText}>Assistance 24/7</Text>
                      <Text style={rentalOptions.bottomText}>
                        Pomoc w przypadku potrzeby holowania pojazdu, transportu
                        kierowcy i pasażerów i zakwaterowanie w hotelu.
                      </Text>
                    </View>
                  </View>
                )}
              </View>
              {activeOffer.packageType === 'premium' && (
                <View style={rentalOptions.row}>
                  <View style={rentalOptions.cell}>
                    <View style={rentalOptions.iconSection}>
                      <Image style={rentalOptions.icon} src={insurance} />
                    </View>
                    <View style={rentalOptions.textSection}>
                      <Text style={rentalOptions.topText}>Ubezpieczenie</Text>
                      <Text style={rentalOptions.bottomText}>
                        Pełen pakiet komunikacyjny uwzględniający polisę OC oraz
                        AC. W przypadku firm, także NNW
                      </Text>
                    </View>
                  </View>
                  <View style={rentalOptions.cell}>
                    <View style={rentalOptions.iconSection}>
                      <Image style={rentalOptions.icon} src={replacement} />
                    </View>
                    <View style={rentalOptions.textSection}>
                      <Text style={rentalOptions.topText}>
                        Samochód zastępczy
                      </Text>
                      <Text style={rentalOptions.bottomText}>
                        W przypadku unieruchomienia lub kradzieży pojazdu.
                      </Text>
                    </View>
                  </View>
                </View>
              )}
            </View>

            <View style={styles.table}>
              <View style={styles.tableHeader}>
                <View style={styles.tableHeaderCell}>
                  <Text style={styles.tableHeaderCellText}>Okres</Text>
                </View>
                <View style={styles.tableHeaderCell}>
                  <Text style={styles.tableHeaderCellText}>Limit</Text>
                </View>
                <View style={styles.tableHeaderCell}>
                  <Text style={styles.tableHeaderCellText}>
                    {'Oplata poczatkowa'}
                  </Text>
                </View>
                <View style={styles.tableHeaderCell}>
                  <Text style={styles.tableHeaderCellText}>Cena Netto</Text>
                </View>
                <View style={styles.tableHeaderCell}>
                  <Text style={styles.tableHeaderCellText}>Cena Brutto</Text>
                </View>
              </View>
              <View style={styles.row}>
                <View style={styles.tableCell}>
                  {yearsData.map((row, index) => (
                    <View
                      key={`period-${index}`}
                      style={{
                        flexDirection: 'column',
                        flex: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderBottomWidth: 1,
                        borderBottomColor: '#808080',
                      }}
                    >
                      <Text style={{}}>{row.period}</Text>
                    </View>
                  ))}
                </View>
                <View style={styles.tableCell}>
                  {tableData.map((row, index) => (
                    <View
                      key={`limit-${index}`}
                      style={{
                        borderBottomWidth: 1,
                        borderBottomColor:
                          yearsData.length === 1
                            ? tableData.length - 1 === index
                              ? '#808080'
                              : '#e1e1e1'
                            : (index + 1) % 3 === 0
                            ? '#808080'
                            : '#e1e1e1',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#f3f3f3',
                      }}
                    >
                      <Text style={styles.greyText}>
                        {row.annualMileage} km
                      </Text>
                    </View>
                  ))}
                </View>
                <View style={styles.tableCell}>
                  <View
                    style={{
                      borderBottomWidth: 1,
                      borderBottomColor: '#808080',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flex: 1,
                    }}
                  >
                    <Text style={styles.blueTextBig}>{'0 zl'}</Text>
                  </View>
                </View>
                <View style={styles.tableCell}>
                  {tableData.map((row, index) => (
                    <View
                      key={`priceNetto-${index}`}
                      style={{
                        borderBottomWidth: 1,
                        borderBottomColor:
                          yearsData.length === 1
                            ? tableData.length - 1 === index
                              ? '#808080'
                              : '#e1e1e1'
                            : (index + 1) % 3 === 0
                            ? '#808080'
                            : '#e1e1e1',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#f3f3f3',
                      }}
                    >
                      <Text style={styles.blueText}>{row.priceNetto} zl</Text>
                    </View>
                  ))}
                </View>
                <View style={styles.tableCell}>
                  {tableData.map((row, index) => (
                    <View
                      key={`priceBrutto-${index}`}
                      style={{
                        borderBottomWidth: 1,
                        borderBottomColor:
                          yearsData.length === 1
                            ? tableData.length - 1 === index
                              ? '#808080'
                              : '#e1e1e1'
                            : (index + 1) % 3 === 0
                            ? '#808080'
                            : '#e1e1e1',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'white',
                      }}
                    >
                      <Text style={styles.blueText}>{row.priceBrutto} zl</Text>
                    </View>
                  ))}
                </View>
              </View>
              <View>
                <Text
                  style={{
                    fontSize: 7,
                    color: '#808080',
                    fontFamily: 'Roboto',
                  }}
                >
                  Powyżej przedstawiono najpopularniejsze warianty w umowach
                  wynajmu
                </Text>
                <Text
                  style={{
                    fontSize: 7,
                    color: '#808080',
                    fontFamily: 'Roboto',
                  }}
                >
                  W przypadku potrzeby przedstawienia oferty dla parametrów
                  niezawartych w powyższej tabeli, skonatkuj się z Twoim
                  dedykowanym Doradcą, który przedstawi indywidualne kalkulacje.
                </Text>
              </View>
            </View>
          </>
        )}
        {activeOffer.financingType === 'leasing' && (
          <View style={leasingStyles.container}>
            <View style={leasingStyles.titleSection}>
              <Text style={leasingStyles.titleText}>
                Wybrane warunki leasingu
              </Text>
            </View>
            <View style={leasingStyles.row}>
              <View style={leasingStyles.leftCell}>
                <Text style={leasingStyles.cellText}>Wkład własny</Text>
              </View>
              <View style={leasingStyles.rightCell}>
                <Text style={leasingStyles.cellText}>
                  {activeOffer.initialPayment} zł
                </Text>
              </View>
            </View>
            <View style={leasingStyles.row}>
              <View style={leasingStyles.leftCell}>
                <Text style={leasingStyles.cellText}>Liczba rat</Text>
              </View>
              <View style={leasingStyles.rightCell}>
                <Text style={leasingStyles.cellText}>
                  {activeOffer.contractPeriod} mc
                </Text>
              </View>
            </View>
            <View style={leasingStyles.lastRow}>
              <View style={leasingStyles.leftCell}>
                <Text style={leasingStyles.cellText}>Kwota wykupu</Text>
              </View>
              <View style={leasingStyles.rightCell}>
                <Text style={leasingStyles.cellText}>
                  {activeOffer.buyoutPrice} zł
                </Text>
              </View>
            </View>
            <View style={leasingStyles.row}>
              <View style={leasingStyles.leftCell}>
                <Text style={leasingStyles.highlightedText}>Rata</Text>
              </View>
              <View style={leasingStyles.rightCell}>
                <Text style={leasingStyles.highlightedText}>
                  {activeOffer.price} zł
                  {activeOffer.financingType === 'leasing'
                    ? ' netto/miesiąc'
                    : ' brutto/miesiąc'}
                </Text>
              </View>
            </View>
          </View>
        )}

        <View
          style={{
            position: 'absolute',
            bottom: '3%',
            // bottom: '0',
            width: '80%',
            left: '9.5%',
          }}
        >
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: 10,
              height: 80,
            }}
          >
            <View
              style={{
                width: '40%',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-start',
              }}
            >
              <View
                style={{
                  marginLeft: 25,
                  marginBottom: 15,
                }}
              >
                {nameAndSurname.length > 0 && (
                  <Text
                    style={{
                      color: '#808080',
                      fontSize: 9,
                      fontFamily: 'Roboto',
                    }}
                  >
                    Oferta poglądowa przygotowana dla: {`${nameAndSurname}`}
                  </Text>
                )}
                <Text
                  style={{
                    color: '#808080',
                    fontSize: 9,
                    fontFamily: 'Roboto',
                  }}
                >
                  Nr kalkulacji:
                </Text>
                <Text
                  style={{
                    color: '#027fff',
                    fontSize: 9,
                    fontFamily: 'Roboto',
                  }}
                >
                  {activeOffer?.id}
                </Text>
              </View>
            </View>
            <View
              style={{
                // border: 1,
                // borderColor: 'red',
                width: '25%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <View>
                <Text
                  style={{
                    color: '#0397a7',
                    fontSize: 12,
                    textDecoration: 'underline',
                    fontFamily: 'Roboto',
                  }}
                >
                  hello@carplatform.pl
                </Text>
                <Text
                  style={{
                    color: '#027fff',
                    fontSize: 12,
                    fontFamily: 'Roboto',
                  }}
                >
                  +48 661 188 188
                </Text>
                <View
                  style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}
                >
                  <Link
                    src={'https://www.facebook.com/carplatformPL/?locale=pl_PL'}
                  >
                    <Image style={{ width: 20, height: 20 }} src={facebook} />
                  </Link>
                  <Link src={'https://www.instagram.com/car.platform/'}>
                    <Image style={{ width: 20, height: 20 }} src={instagram} />
                  </Link>
                  <Link
                    src={
                      'https://www.linkedin.com/company/car-platform-pl/?originalSubdomain=pl'
                    }
                  >
                    <Image style={{ width: 20, height: 20 }} src={linkedin} />
                  </Link>
                  <Link src={'https://www.youtube.com/@carplatform1642'}>
                    <Image style={{ width: 20, height: 20 }} src={youtube} />
                  </Link>
                </View>
              </View>
            </View>
            <View
              style={{
                // border: 1,
                // borderColor: 'red',
                width: '35%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: 5,
                paddingLeft: 10,
              }}
            >
              <Text
                style={{
                  fontSize: 9,
                  color: '#027fff',
                  fontFamily: 'Roboto',
                }}
              >
                Mobility Benefit sp. z o.o.
              </Text>
              <View>
                <Text
                  style={{
                    fontSize: 9,
                    color: '#027fff',
                    textDecoration: 'underline',
                    fontFamily: 'Roboto',
                  }}
                >
                  Oddział Warszawa
                </Text>
                <Text
                  style={{
                    fontSize: 9,
                    color: '#027fff',
                    fontFamily: 'Roboto',
                  }}
                >
                  ul. Ostrobramska 73; 04-175 Warszawa
                </Text>
              </View>
              <View>
                <Text
                  style={{
                    fontSize: 9,
                    color: '#027fff',
                    textDecoration: 'underline',
                    fontFamily: 'Roboto',
                  }}
                >
                  Oddział Białystok
                </Text>
                <Text
                  style={{
                    fontSize: 9,
                    color: '#027fff',
                    fontFamily: 'Roboto',
                  }}
                >
                  ul. Porosły-Kolonia 1k; 16-070 Choroszcz
                </Text>
              </View>
            </View>
          </View>
          <Text
            style={{
              fontSize: 7,
              color: '#808080',
              fontFamily: 'Roboto',
            }}
          >
            Informacje prezentowane w podsumowaniu wybranego modelu nie stanowią
            ofert w rozumieniu kodeksu cywilnego, a więc jako takie nie są
            prawnie wiążące. Ostateczna wycena każdorazowo sporządzana jest
            indywidualnie dla każdego wnioskującego w oparciu o spełnione przez
            niego kryteria. Prezentowane zdjęcia są jedynie zdjęciami
            poglądowymi, a szczegółowa specyfikacja zostanie przedstawiona w
            odpowiedzi na konkretne zapytanie ofertowe.
          </Text>
        </View>
        <View
          style={{
            position: 'absolute',
            bottom: '1.5%',
            width: '100%',
            height: '1.19%',
            backgroundColor: '#027fff',
          }}
        />
      </Page>
    </Document>
  );
};

export const generatePDF = async (
  product: any,
  activeOffer: any,
  image: any,
  imageDimensions: any,
  nameAndSurname: string
): Promise<Blob> => {
  const blob = await pdf(
    <GeneratePdfFromFormBlocks
      product={product}
      activeOffer={activeOffer}
      image={image}
      imageDimensions={imageDimensions}
      nameAndSurname={nameAndSurname}
    />
  ).toBlob();
  return blob;
};
