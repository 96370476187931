import React from 'react';
import { Seo } from 'src/public/components/Seo';
import { useStorePageUrl } from 'src/common/hooks/useStorePageUrl';
import { Loader } from 'src/common/Loader';
import { FullSizeContainer } from '@summer/react-kit';
import { injectAppState } from 'src/app/state';
import { App } from 'src/app/App';
import { BusinessPartnerPage } from 'src/app/BusinessPartnerPage';
import { isNil } from 'ramda';
import { useIsHomePage } from 'src/app/common/hooks/useIsHomePage';

injectAppState();

const IndexPage = () => {
  useStorePageUrl();

  const isHomePage = useIsHomePage();

  if (isNil(isHomePage)) {
    return (
      <FullSizeContainer>
        <Loader />
      </FullSizeContainer>
    );
  }

  return typeof window === 'undefined' ? (
    <Seo />
  ) : (
    <>
      <Seo />
      {isHomePage ? <App /> : <BusinessPartnerPage />}
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default IndexPage;
