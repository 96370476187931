import { createRootRoute, createRoute, setParams } from '@summer/react-kit';
import slugify from 'slugify';
import { FinancingType, PackageType } from 'src/common/models/offer';
import { CustomerType } from 'src/common/models/customerType';
import { OrderPageProps, ProductRouterProps } from 'src/app/product/types';

export const AppOverlayId = 'app-overlay';

export const AppRoute = createRootRoute('app');

export const HomePageRoute = createRoute(AppRoute);

export const ProductPageRoute = createRoute(AppRoute, 'product');
export const ProductItemPageRoute = createRoute(
  ProductPageRoute,
  ':brand',
  ':model',
  ':version',
  ':customerType',
  ':financingType',
  ':param6',
  ':contractPeriod',
  ':param8',
  ':productId'
);
export const ProductOrderPageRoute = createRoute(ProductItemPageRoute, 'order');
export const ProductOrderItemPageRoute = createRoute(
  ProductOrderPageRoute,
  ':offerId'
);
export const ProductOrderThankYouPageRoute = createRoute(
  ProductOrderPageRoute,
  ':offerId',
  'thank-you'
);

export const SearchPageRoute = createRoute(AppRoute, 'search');
export const AccountPageRoute = createRoute(AppRoute, 'account');

const EMPTY = '--';

const slug = (value?: string) => {
  if (!value) {
    return EMPTY;
  }

  return slugify(value, {
    lower: true,
  });
};

export interface ProductItemPageRouteParams {
  annualMileage?: number;
  brandCode?: string;
  buyoutPrice?: number;
  contractPeriod?: number;
  customerType?: CustomerType;
  financingType?: FinancingType;
  initialPayment?: number;
  modelLabel?: string;
  packageType?: PackageType | null;
  productId: string;
  version?: string;
}

export interface OrderItemPageRouteParams extends ProductItemPageRouteParams {
  offerId: string;
}

const baseProductPageRoute = (params: ProductItemPageRouteParams) => {
  const param6 =
    params.financingType === FinancingType.Leasing
      ? `wklad-wlasny-${params.initialPayment}`
      : params.packageType;
  const param8 =
    params.financingType === FinancingType.Leasing
      ? `kwota-wykupu-${params.buyoutPrice}`
      : `planowany-przebieg-roczny-${params.annualMileage}`;

  return {
    segments: [
      ':brand',
      ':model',
      ':version',
      ':customerType',
      ':financingType',
      ':param6',
      ':contractPeriod',
      ':param8',
      ':productId',
    ],
    values: [
      params.brandCode ?? EMPTY,
      slug(params.modelLabel),
      slug(params.version),
      params.customerType ?? EMPTY,
      params.financingType ?? EMPTY,
      param6 ?? EMPTY,
      params.contractPeriod ? `liczba-rat-${params.contractPeriod}` : EMPTY,
      param8 ?? EMPTY,
      params.productId,
    ],
  };
};

export const buildProductItemPageRoute = (
  params: ProductItemPageRouteParams
) => {
  const { segments, values } = baseProductPageRoute(params);

  return setParams(...segments)(ProductItemPageRoute)(...values);
};

export const buildOrderItemPageRoute = (params: OrderItemPageRouteParams) => {
  const { segments, values } = baseProductPageRoute(params);

  return setParams(...segments, ':offerId')(ProductOrderItemPageRoute)(
    ...values,
    params.offerId
  );
};

export const buildProductItemPageRouteFromUrlParams = (
  params: ProductRouterProps
) => {
  return setParams(
    ':brand',
    ':model',
    ':version',
    ':customerType',
    ':financingType',
    ':param6',
    ':contractPeriod',
    ':param8',
    ':productId'
  )(ProductItemPageRoute)(
    params.brand ?? EMPTY,
    params.model ?? EMPTY,
    params.version ?? EMPTY,
    params.customerType ?? EMPTY,
    params.financingType ?? EMPTY,
    params.param6 ?? EMPTY,
    params.contractPeriod ?? EMPTY,
    params.param8 ?? EMPTY,
    params.productId ?? EMPTY
  );
};

export const buildOrderThankYouRouteFromUrlParams = (
  params: OrderPageProps
) => {
  return setParams(
    ':brand',
    ':model',
    ':version',
    ':customerType',
    ':financingType',
    ':param6',
    ':contractPeriod',
    ':param8',
    ':productId',
    ':offerId'
  )(ProductOrderThankYouPageRoute)(
    params.brand ?? EMPTY,
    params.model ?? EMPTY,
    params.version ?? EMPTY,
    params.customerType ?? EMPTY,
    params.financingType ?? EMPTY,
    params.param6 ?? EMPTY,
    params.contractPeriod ?? EMPTY,
    params.param8 ?? EMPTY,
    params.productId ?? EMPTY,
    params.offerId ?? EMPTY
  );
};
