import React, { FC, Suspense } from 'react';
import { Redirect, Router } from '@reach/router';
import {
  AccountPageRoute,
  AppOverlayId,
  AppRoute,
  ProductItemPageRoute,
  ProductPageRoute,
  SearchPageRoute,
} from 'src/app/routes';
import { IconShowcase } from 'src/app/devtools/IconShowcase';
import { FullSizeContainer, RootRoute } from '@summer/react-kit';
import { injectAppState } from 'src/app/state';
import { ButtonShowcase } from 'src/app/devtools/ButtonShowcase';
import { environment } from 'src/config/environment';
import { Loader } from 'src/common/Loader';
import { ToggleButtonGroupShowcase } from 'src/app/devtools/ToggleButtonGroupShowcase';
import { useIsHomePage } from 'src/app/common/hooks/useIsHomePage';
import { isNil } from 'ramda';

const HomePage = React.lazy(() => import('src/app/home/HomePage'));
const ProductRouter = React.lazy(() => import('src/app/product/ProductRouter'));
const SearchPage = React.lazy(() => import('src/app/search/SearchPage'));
const AccountPage = React.lazy(() => import('src/app/account/AccountPage'));

injectAppState();

export const App: FC = () => {
  const isHomePage = useIsHomePage();
  if (isNil(isHomePage)) {
    return (
      <FullSizeContainer>
        <Loader />
      </FullSizeContainer>
    );
  }

  return (
    <>
      <Suspense
        fallback={
          <FullSizeContainer>
            <Loader />
          </FullSizeContainer>
        }
      >
        <Router>
          <HomePage path={`${RootRoute}/*`} />
          <Redirect noThrow from={AppRoute} to={RootRoute} />

          <ProductRouter path={`${ProductItemPageRoute}/*`} />
          <Redirect noThrow from={ProductPageRoute} to={SearchPageRoute} />

          <SearchPage path={`${SearchPageRoute}/*`} />
          <AccountPage path={AccountPageRoute} />

          {environment.environmentName !== 'production' && (
            <>
              <IconShowcase path="/app/devtools/icons" />
              <ButtonShowcase path="/app/devtools/buttons" />
              <ToggleButtonGroupShowcase path="/app/devtools/toggleButtonGroup" />
            </>
          )}
        </Router>
      </Suspense>
      <div id={AppOverlayId} />
    </>
  );
};
